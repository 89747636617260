var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('aRow',{staticClass:"section-title",class:!_vm.openBoxDetails ? 'closed' : '',attrs:{"type":"flex","justify":"space-between"}},[_c('aCol',[_c('h2',[_vm._v("Pagamentos")])]),_c('aCol',[_c('a-icon',{staticClass:"arrow",attrs:{"type":"down"},on:{"click":_vm.onClickOpenBoxDetails}})],1)],1),(_vm.openBoxDetails)?_c('div',{staticClass:"payments line border-0"},[(_vm.paymentMethods.includes('bank-transfer'))?_c('aRow',{attrs:{"type":"flex","justify":"space-between"}},[_c('aCol',[_vm._v("Transferência bancária")]),_c('aCol',[_vm._v(" R$ "+_vm._s(_vm.formatPrice(_vm.contract.meta.payment_methods_bank_transfer_total_value)))])],1):_vm._e(),(_vm.paymentMethods.includes('payment-card'))?_c('aRow',{attrs:{"type":"flex","justify":"space-between"}},[_c('aCol',[_vm._v("Carta de Pagamento")]),_c('aCol',[_vm._v(" R$ "+_vm._s(_vm.formatPrice(_vm.contract.meta.payment_methods_payment_card_total_value)))])],1):_vm._e(),(_vm.paymentMethods.includes('payment-link'))?_c('div',_vm._l((JSON.parse(_vm.contract.meta.payment_link_rows)),function(pLink,i){return _c('aRow',{key:i,attrs:{"type":"flex","justify":"space-between"}},[_c('aCol',[_vm._v("Link de Pagamento #"+_vm._s(pLink.id)+" "),(
              pLink.id === _vm.contract.payment_link_id &&
                _vm.$route.params.status !== 'success'
            )?_c('span',{staticClass:"to-pay"},[(
                ![undefined, ''].includes(
                  _vm.contract.meta[`payment_link_3ds_tid_${pLink.id}`]
                )
              )?_c('span',{staticClass:"cgreen"},[_vm._v("(Pago)")]):_c('span',[_vm._v("(Pendente)")])]):_vm._e()]),_c('aCol',[_vm._v(" "+_vm._s(_vm.formatPricePtBr( _vm.contract.meta[ `payment_methods_payment_link_total_value_${pLink.id}` ].replace(",", ".") )))])],1)}),1):_vm._e(),(_vm.paymentMethods.includes('credit-card'))?_c('aRow',{attrs:{"type":"flex","justify":"space-between"}},[_c('aCol',[_vm._v("Cartão de Crédito")]),_c('aCol',[_vm._v("R$ "+_vm._s(_vm.creditCard()))])],1):_vm._e(),(_vm.paymentMethods.includes('billing-ticket'))?_c('aRow',{attrs:{"type":"flex","justify":"space-between"}},[_c('aCol',[_vm._v("Boleto Bancário")]),_c('aCol',[_vm._v(" R$ "+_vm._s(_vm.formatPrice( _vm.contract.meta.payment_methods_billing_ticket_total_value )))])],1):_vm._e(),(_vm.paymentMethods.includes('custom-payment'))?_c('aRow',{attrs:{"type":"flex","justify":"space-between"}},[_c('aCol',[_vm._v(_vm._s(_vm.contract.meta[`payment_methods_custom_payment_name`]))]),_c('aCol',[_vm._v(" R$ "+_vm._s(_vm.formatPrice( _vm.contract.meta.payment_methods_custom_payment_total_value )))])],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }