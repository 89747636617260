import Vue from "vue";
import Vuex from "vuex";
import VueRouter from "vue-router";

import Payments from "../views/Payments.vue";
import NoTokenPage from "../components/payment-form/NoTokenPage.vue";

Vue.use(Vuex);
Vue.use(VueRouter);

const company = "Pagamentos";
const routes = [
  {
    path: "/",
    name: "Pagamentos",
    component: Payments,
    meta: {
      title: `${company}`,
      requiresAuth: false,
    },
  },
  {
    path: "/b2c",
    name: "Payments",
    component: Payments,
    meta: {
      title: `${company}`,
      requiresAuth: true,
    },
    children: [
      {
        path: ":token",
        component: Payments,
        meta: {
          title: `${company}`,
          slug: "Payments",
          requiresAuth: true,
        },
      },
      {
        path: "checkout/:status",
        component: Payments,
        meta: {
          title: `${company}`,
          slug: "Payments",
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/link-pagamento-expirado",
    name: "NoTokenPage",
    component: NoTokenPage,
    meta: {
      title: `Link de Pagamento Expirado - ${company}`,
      requiresAuth: false,
    },
  },
  {
    path: "*",
    name: "NoTokenPage",
    component: NoTokenPage,
    meta: {
      title: `Link de Pagamento Expirado - ${company}`,
      requiresAuth: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
