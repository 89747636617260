<template>
  <div>
    <aRow
      v-for="(traveller, index) in travellers"
      :key="index"
      :gutter="[20, 10]"
      class="line"
    >
      <aCol :span="6">
        <font class="label">Nome</font>
        <font class="value">{{
          contract.meta[`traveller_${traveller.id}_first_name`]
        }}</font>
      </aCol>

      <aCol :span="10">
        <font class="label">Sobrenome</font>
        <font class="value">{{
          contract.meta[`traveller_${traveller.id}_last_name`]
        }}</font>
      </aCol>

      <aCol :span="8">
        <font class="label">Nascimento</font>
        <font class="value">{{
          formatDateModel(
            contract.meta[`traveller_${traveller.id}_birthday`],
            "dd MMM yyyy"
          )
        }}</font>
      </aCol>
    </aRow>
  </div>
</template>

<script>
import formatThings from "../../general/mixins/formatThings.js";

export default {
  props: {
    contract: Object,
  },
  mixins: [formatThings],
  data() {
    return {
      travellers: [],
    };
  },
  mounted() {
    this.travellers = this.contract.meta.travellers_rows
      ? JSON.parse(this.contract.meta.travellers_rows)
      : [];
  },
};
</script>

<style lang="sass" scoped>
.line
  padding: 5px 0
  border-bottom: 1px solid #e1e1e1
  .ant-col
    padding: 6px 0 3px 0 !important
  .label
    display: block
    color: #aab2bd
    font-size: 10px
  .value
    font-weight: 500
    display: block
    color: #434a54
    font-size: 14px
    line-height: 1.2
    text-transform: uppercase
</style>
