<template>
  <section class="container">
    <div class="token-expired white-box">
      <div class="ico">
        <img src="@/assets/clock.png" alt="Link expirado" width="260px" />
      </div>
      <div class="txt a-center">
        O seu Link de Pagamento expirou, entre em contato com o consultor
        responsável pela sua reserva.
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  name: "NoTokenPage",
};
</script>
