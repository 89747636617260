import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue";
import VueTheMask from "vue-the-mask";
import FontAwesome from "@fortawesome/fontawesome-free";
import "ant-design-vue/dist/antd.less";
import "./plugins/axios";
import "./registerServiceWorker";

Vue.config.productionTip = false;
Vue.use(VueTheMask);
Vue.use(Antd);
Vue.use(FontAwesome);

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    next();
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});

new Vue({
  data: {
    token: store.state.token,
    isLoggedIn: store.state.isLoggedIn,
    windowWidth: window.innerWidth,
  },
  created() {
    window.addEventListener("resize", this.resizeWindow);
  },
  methods: {
    resizeWindow() {
      this.windowWidth = window.innerWidth;
    },
    reponsive(windowSizes) {
      let theSize = 24;
      windowSizes.forEach((size) => {
        if (size.min <= this.windowWidth && size.max >= this.windowWidth) {
          theSize = size.span;
        }
      });
      return theSize;
    },
  },
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
