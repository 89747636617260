<template>
  <section>
    <PaymentPage v-if="showPage" :contract="contract" />
    <NoTokenPage v-if="tokenExpired" />
  </section>
</template>

<script>
import PaymentPage from "../components/payment-form/PaymentPage.vue";
import NoTokenPage from "../components/payment-form/NoTokenPage.vue";

export default {
  components: { PaymentPage, NoTokenPage },
  data() {
    return {
      contract: {},
      isLoggedIn: localStorage.getItem("isLoggedIn"),
      showPage: false,
      tokenExpired: false,
    };
  },
  beforeMount() {
    localStorage.setItem("isLoggedIn", false);
    if (
      this.$route.params.token !== "checkout" &&
      this.$route.params.status !== "success" &&
      this.$route.params.status !== "failed"
    )
      localStorage.setItem("token", this.$route.params.token);
    this.getContract();
  },
  methods: {
    getContract() {
      this.$http
        .get("/contract/payment-details")
        .then(({ data }) => {
          this.contract = data;
          this.showPage = true;
          localStorage.setItem("isLoggedIn", true);
        })
        .catch(() => {
          localStorage.setItem("isLoggedIn", false);
          localStorage.setItem("token", "");
          this.showPage = false;
          this.tokenExpired = true;
          this.contract = {};
        });
    },
  },
};
</script>
