<template>
  <div>
    <aRow
      class="section-title"
      :class="!openBoxDetails ? 'closed' : ''"
      type="flex"
      justify="space-between"
    >
      <aCol> <h2>Resumo da sua viagem</h2></aCol>
      <aCol>
        <a-icon class="arrow" type="down" @click="onClickOpenBoxDetails" />
      </aCol>
    </aRow>
    <div v-if="openBoxDetails" class="travel-details">
      <aRow>
        <aCol
          v-if="contract.meta.contracted_services.includes('hotel')"
          :span="24"
          class="section"
        >
          <aRow>
            <aCol class="title" :span="24">
              <img
                src="@/assets/bed.png"
                alt="Aéreo"
                width="30"
                style="position:relative; top:-3px"
              />
            </aCol>

            <aCol
              class="content hotel-row"
              v-for="(hotel, index) in strToJson(contract.meta.hotel_rows)"
              :key="index"
              :span="24"
            >
              <aRow>
                <aCol class="name" :span="24">
                  {{ contract.meta[`hotel_${hotel.id}_name`] }}
                </aCol>

                <aCol class="travellers" :span="24">
                  <div v-for="(room, i) in hotel.accommodation" :key="i">
                    •
                    {{
                      contract.meta[
                        `hotel_${hotel.id}_accommodation_${room.id}`
                      ]
                    }}
                    ({{
                      contract.meta[
                        `hotel_${hotel.id}_accommodation_${room.id}_type`
                      ]
                    }})
                  </div>
                  • {{ contract.meta[`hotel_${hotel.id}_nights`] }}
                  noites,
                  {{ travellers(hotel.id, "hotel").txt }}
                </aCol>
                <aCol :span="12">
                  <label> Check-in </label>
                  <div class="value">
                    {{
                      formatDateModel(
                        contract.meta[`hotel_${hotel.id}_checkin`],
                        "dd MMM yyyy"
                      )
                    }}
                  </div>
                </aCol>
                <aCol :span="12">
                  <label> Check-out </label>
                  <div class="value">
                    {{
                      formatDateModel(
                        contract.meta[`hotel_${hotel.id}_checkout`],
                        "dd MMM yyyy"
                      )
                    }}
                  </div>
                </aCol>
              </aRow>
            </aCol>
          </aRow>
        </aCol>

        <aCol
          v-if="contract.meta.contracted_services.includes('flight')"
          :span="24"
          class="section"
        >
          <aRow>
            <aCol class="title" :span="24">
              <img
                src="@/assets/flight-ico.png"
                alt="Aéreo"
                width="30"
                style="position:relative; top:-3px"
              />
            </aCol>

            <aCol
              class="content hotel-row"
              v-for="(flight, index) in flightDetails()"
              :key="index"
              :span="24"
            >
              <aRow>
                <aCol class="name" :span="24">
                  <aRow type="flex" justify="space-between">
                    <aCol>
                      <div>
                        {{ flight.destinationText }}
                      </div>
                      <div class="travellers">
                        {{ flight.roundtripText }},
                        {{ flight.travellersText }}
                      </div>
                    </aCol>
                  </aRow>
                </aCol>

                <aCol class="flight-going" :span="24">
                  <aCol class="mb-20" :span="24">
                    <aRow type="flex" justify="space-between">
                      <aCol>
                        <div>
                          <div class="subtitle">
                            IDA
                          </div>
                          <div>
                            {{
                              formatDateModel(flight.going.date, "dd MMM yyyy")
                            }}
                          </div>
                        </div>
                      </aCol>
                      <aCol>
                        <div>
                          <img
                            class="ml-10"
                            v-for="(airline, index) in flight.going.airline"
                            :key="index"
                            :src="airline.logo"
                            width="25"
                            :alt="airline.name"
                          />
                        </div>
                      </aCol>
                    </aRow>
                  </aCol>

                  <aCol class="" :span="24">
                    <aRow>
                      <aCol :span="4">
                        <div>{{ flight.origin }}</div>
                        <div>
                          {{ flight?.going?.times?.timeDeparture }}
                        </div>
                      </aCol>
                      <aCol class="stops" :span="10">
                        <div v-if="flight.going.stops == 0">
                          Direto
                        </div>
                        <div v-if="flight.going.stops == 1">
                          1 parada
                        </div>
                        <div v-if="flight.going.stops > 1">
                          {{ flight.going.stops }}
                          paradas
                        </div>

                        <div class="border-line"></div>
                      </aCol>
                      <aCol :span="4">
                        <div>
                          {{ flight.destination }}
                        </div>
                        <div>
                          {{ flight.going.times.timeArrival }}
                        </div>
                      </aCol>
                      <aCol class="duration" :span="6">
                        <div class="txt1">Duração</div>
                        <div class="txt2">
                          {{
                            flight.going.times.duration == 1
                              ? flight.going.times.duration + " hora"
                              : flight.going.times.duration + " horas"
                          }}
                        </div>
                      </aCol>
                    </aRow>
                  </aCol>
                </aCol>

                <aCol
                  class="flight-back"
                  :span="24"
                  v-if="flight.roundtrip == 'Ida e Volta'"
                >
                  <aCol :span="24">
                    <aRow type="flex" justify="space-between">
                      <aCol class="mb-20">
                        <div>
                          <div class="subtitle">
                            VOLTA
                          </div>
                          <div>
                            {{
                              formatDateModel(flight.going.date, "dd MMM yyyy")
                            }}
                          </div>
                        </div>
                      </aCol>
                      <aCol>
                        <div>
                          <img
                            class="ml-10"
                            v-for="(airline, index) in flight.back.airline"
                            :key="index"
                            :src="airline.logo"
                            width="25"
                            :alt="airline.name"
                          />
                        </div>
                      </aCol>
                    </aRow>
                  </aCol>

                  <aCol class="" :span="24">
                    <aRow>
                      <aCol :span="4">
                        <div>
                          {{ flight.destination }}
                        </div>
                        <div>
                          {{ flight.back?.times?.timeDeparture }}
                        </div>
                      </aCol>
                      <aCol class="stops" :span="10">
                        <div v-if="flight.back.stops == 0">
                          Direto
                        </div>
                        <div v-if="flight.back.stops == 1">
                          1 parada
                        </div>
                        <div v-if="flight.back.stops > 1">
                          {{ flight.back.stops }}
                          paradas
                        </div>

                        <div class="border-line"></div>
                      </aCol>
                      <aCol :span="4">
                        <div>{{ flight.origin }}</div>
                        <div>
                          {{ flight.back.times.timeArrival }}
                        </div>
                      </aCol>
                      <aCol class="duration" :span="6">
                        <div class="txt1">Duração</div>
                        <div class="txt2">
                          {{
                            flight.back.times.duration == 1
                              ? flight.back.times.duration + " hora"
                              : flight.back.times.duration + " horas"
                          }}
                        </div>
                      </aCol>
                    </aRow>
                  </aCol>
                </aCol>
              </aRow>
            </aCol>
          </aRow>
        </aCol>
        <!-- -
                <pre> {{ flightDetails() }} </pre>
                - -->
        <aCol
          v-if="contract.meta.contracted_services.includes('service')"
          :span="24"
          class="section"
        >
          <aRow>
            <aCol class="title" :span="24">
              <img
                src="@/assets/services-ico.png"
                alt="Aéreo"
                width="30"
                style="position:relative; top:-3px"
              />
            </aCol>

            <aCol
              class="content hotel-row"
              v-for="(service, index) in strToJson(contract.meta.service_rows)"
              :key="index"
              :span="24"
            >
              <aRow>
                <aCol class="name" :span="24">
                  {{ contract.meta[`service_${service.id}_type`] }}
                </aCol>

                <aCol class="description" :span="24">
                  <div class="desc-title">
                    Descrição
                  </div>
                  <div class="txt">
                    {{ contract.meta[`service_${service.id}_name`] }}
                  </div>
                </aCol>
              </aRow>
            </aCol>
          </aRow>
        </aCol>
      </aRow>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { differenceInHours, subYears } from "date-fns";

import formatThings from "../../general/mixins/formatThings.js";
import airports from "../../../json/airports.json";

export default {
  props: {
    contract: Object,
  },
  mixins: [formatThings],
  data() {
    return { airports, openBoxDetails: true };
  },
  mounted() {},
  methods: {
    onClickOpenBoxDetails() {
      if (this.openBoxDetails) {
        this.openBoxDetails = false;
      } else {
        this.openBoxDetails = true;
      }
    },
    adultHoodDate(current) {
      return current && current > subYears(new Date(), 12);
    },
    airportInitialsToName(initial) {
      let theName = "";
      if (initial) {
        theName = this.airports.filter((airport) => {
          return airport.sigla === initial;
        });
        theName = theName[0].cidade;
      }

      return theName;
    },
    airlineNameToLogo(name) {
      let theLogo = this.contract.airlines.filter((airline) => {
        return airline.name == name;
      });
      return theLogo[0];
    },
    flightDetails() {
      const flightSectionsRows = this.contract.meta.flight_sections_rows
        ? JSON.parse(this.contract.meta.flight_sections_rows)
        : [];

      let origin = "",
        destination = "",
        totalAdults = 0,
        totalChilds = 0,
        roundtrip = "Somente ida",
        allTravellers = [],
        flightsArr = [],
        txtAdts = "",
        txtChds = "",
        allAirlines = [],
        totalStopsGoing = 0,
        totalStopsBack = 0;

      JSON.parse(this.contract.meta.travellers_rows).forEach((traveller) => {
        allTravellers.push({
          id: traveller.id,
          nome: `${this.contract.meta[
            `traveller_${traveller.id}_first_name`
          ].toUpperCase()} ${this.contract.meta[
            `traveller_${traveller.id}_last_name`
          ].toUpperCase()}`,
          nascimento:
            this.contract.meta[`traveller_${traveller.id}_birthday`] !=
            undefined
              ? this.formatMultiDates(
                  this.contract.meta[`traveller_${traveller.id}_birthday`]
                )
              : "",
          cpf: `${this.contract.meta[`traveller_${traveller.id}_CPF`]}`,
          idade: `${this.contract.meta[`traveller_${traveller.id}_age`]}`,
        });
      });

      flightSectionsRows.forEach((flight) => {
        origin = this.contract.meta[`flight_${flight.id}_section_1_origin`];
        destination = "";
        roundtrip = "Somente ida";
        txtAdts = "";
        txtChds = "";
        totalAdults = 0;
        totalChilds = 0;
        allAirlines = [];
        totalStopsGoing = 0;
        let departureDateGoing = "",
          departureDateBack = "",
          flightGoingDates = [],
          flightBackDates = [];

        flight.travellers.forEach((traveller) => {
          allTravellers.forEach((travellerObj) => {
            if (
              travellerObj.id ==
              this.contract.meta[
                `flight_${flight.id}_traveller_${traveller.id}`
              ]
            ) {
              if (travellerObj.idade == "adult") {
                totalAdults = totalAdults + 1;
              }

              if (travellerObj.idade == "child") {
                totalChilds = totalChilds + 1;
              }
            }
          });
        });

        flight.sections.forEach((section) => {
          allAirlines.push({
            name: this.contract.meta[
              `flight_${flight.id}_section_${section.id}_airline`
            ],
            logo: this.airlineNameToLogo(
              this.contract.meta[
                `flight_${flight.id}_section_${section.id}_airline`
              ]
            ).logo,
          });

          if (
            this.contract.meta[
              `flight_${flight.id}_section_${section.id}_type`
            ] == "Ida"
          ) {
            departureDateGoing = this.contract.meta[
              `flight_${flight.id}_section_${1}_departure_date`
            ];

            flightGoingDates.push({
              type: this.contract.meta[
                `flight_${flight.id}_section_${section.id}_type`
              ],
              dateDeparture: `${this.formatDateModel(
                this.contract.meta[
                  `flight_${flight.id}_section_${section.id}_departure_date`
                ],
                "yyyy-MM-dd"
              )}`,

              timeDeparture: `${
                this.contract.meta[
                  `flight_${flight.id}_section_${section.id}_departure_time`
                ]
              }`,
              dateArrival: `${this.formatDateModel(
                this.contract.meta[
                  `flight_${flight.id}_section_${section.id}_arrival_date`
                ],
                "yyyy-MM-dd"
              )}`,
              timeArrival: `${
                this.contract.meta[
                  `flight_${flight.id}_section_${section.id}_arrival_time`
                ]
              }`,
              duration: this.contract.meta[
                `flight_${flight.id}_section_${section.id}_duration`
              ],
            });

            totalStopsGoing += parseInt(
              this.contract.meta[
                `flight_${flight.id}_section_${section.id}_stops`
              ]
                ? this.contract.meta[
                    `flight_${flight.id}_section_${section.id}_stops`
                  ]
                : 0
            );
          }

          if (
            this.contract.meta[
              `flight_${flight.id}_section_${section.id}_type`
            ] == "Volta"
          ) {
            if (departureDateBack == "") {
              departureDateBack = this.contract.meta[
                `flight_${flight.id}_section_${section.id}_departure_date`
              ];
            }
            flightBackDates.push({
              type: this.contract.meta[
                `flight_${flight.id}_section_${section.id}_type`
              ],
              dateDeparture: `${this.formatDateModel(
                this.contract.meta[
                  `flight_${flight.id}_section_${section.id}_departure_date`
                ],
                "yyyy-MM-dd"
              )}`,
              timeDeparture: `${
                this.contract.meta[
                  `flight_${flight.id}_section_${section.id}_departure_time`
                ]
              }`,
              dateArrival: `${this.formatDateModel(
                this.contract.meta[
                  `flight_${flight.id}_section_${section.id}_arrival_date`
                ],
                "yyyy-MM-dd"
              )}`,
              timeArrival: ` ${
                this.contract.meta[
                  `flight_${flight.id}_section_${section.id}_arrival_time`
                ]
              }`,
              duration: this.contract.meta[
                `flight_${flight.id}_section_${section.id}_duration`
              ],
            });

            totalStopsBack += parseInt(
              this.contract.meta[
                `flight_${flight.id}_section_${section.id}_stops`
              ]
                ? this.contract.meta[
                    `flight_${flight.id}_section_${section.id}_stops`
                  ]
                : 0
            );

            roundtrip = "Ida e Volta";
            destination = this.contract.meta[
              `flight_${flight.id}_section_${section.id}_origin`
            ];
          } else {
            destination = this.contract.meta[
              `flight_${flight.id}_section_${section.id}_destination`
            ];
          }
        });

        if (totalAdults == 1) {
          txtAdts = "1 adulto";
        }

        if (totalAdults > 1) {
          txtAdts = totalAdults + " adultos";
        }

        if (totalChilds == 1) {
          txtChds = " + 1 criança";
        }

        if (totalChilds > 1) {
          txtChds = ` + ${totalChilds} crianças`;
        }

        flightsArr.push({
          origin,
          destination,
          destinationText: `${this.airportInitialsToName(
            origin
          )} > ${this.airportInitialsToName(destination)}`,
          roundtrip,
          roundtripText: `${roundtrip}`,
          travellersText: `${txtAdts} ${txtChds}`,
          going: {
            date: departureDateGoing,
            times: this.flightDatesGoing(flightGoingDates),
            stops: totalStopsGoing,
            airline: _.uniqBy(allAirlines, "name"),
          },
          back: {
            date: departureDateBack,
            times: this.flightDatesBack(flightBackDates),
            stops: totalStopsBack,
            airline: _.uniqBy(allAirlines, "name"),
          },
        });
      });

      this.$emit("updateEmailFlightData", flightsArr);

      return flightsArr;
    },
    flightDatesGoing(arr) {
      return {
        timeDeparture: arr[0]?.timeDeparture,
        timeArrival: arr[arr.length - 1]?.timeArrival,
        duration: differenceInHours(
          new Date(
            `${arr[arr.length - 1]?.dateArrival} ${
              arr[arr.length - 1]?.timeArrival
            }`
          ),
          new Date(`${arr[0]?.dateDeparture} ${arr[0]?.timeDeparture}`),
          { round: false }
        ),
      };
    },
    flightDatesBack(arr) {
      return {
        timeDeparture: arr[0] ? arr[0]?.timeDeparture : "",
        timeArrival: arr[0] ? arr[arr.length - 1]?.timeArrival : "",
        duration: arr[0]
          ? differenceInHours(
              new Date(
                `${arr[arr.length - 1]?.dateArrival} ${
                  arr[arr.length - 1]?.timeArrival
                }`
              ),
              new Date(`${arr[0].dateDeparture} ${arr[0]?.timeDeparture}`)
            )
          : "",
      };
    },
    travellers(id, product) {
      let allTravellers = [];
      let totalAdults = 0;
      let totalChilds = 0;
      let txtAdts = "";
      let txtChds = "";

      JSON.parse(this.contract.meta.travellers_rows).forEach((traveller) => {
        allTravellers.push({
          id: traveller.id,
          nome: `${this.contract.meta[
            `traveller_${traveller.id}_first_name`
          ].toUpperCase()} ${this.contract.meta[
            `traveller_${traveller.id}_last_name`
          ].toUpperCase()}`,
          nascimento:
            this.contract.meta[`traveller_${traveller.id}_birthday`] !=
            undefined
              ? this.formatMultiDates(
                  this.contract.meta[`traveller_${traveller.id}_birthday`]
                )
              : "",
          cpf: `${this.contract.meta[`traveller_${traveller.id}_CPF`]}`,
          idade: `${this.contract.meta[`traveller_${traveller.id}_age`]}`,
        });
      });

      console.log("allTravellers", allTravellers);

      if (product == "hotel") {
        this.strToJson(this.contract.meta.hotel_rows)[
          id - 1
        ].accommodation.forEach((room) => {
          room.travellers.forEach((traveller) => {
            allTravellers.forEach((travellerObj) => {
              if (
                travellerObj.id ==
                this.contract.meta[
                  `hotel_${id}_accommodation_${room.id}_traveller_${traveller.id}`
                ]
              ) {
                if (travellerObj.idade == "adult") {
                  totalAdults = totalAdults + 1;
                }

                if (travellerObj.idade == "child") {
                  totalChilds = totalChilds + 1;
                }
              }
            });
          });
        });
      }

      if (totalAdults == 1) {
        txtAdts = "1 adulto";
      }

      if (totalAdults > 1) {
        txtAdts = totalAdults + " adultos";
      }

      if (totalChilds == 1) {
        txtChds = " + 1 criança";
      }

      if (totalChilds > 1) {
        txtChds = ` + ${totalChilds} crianças`;
      }

      return { txt: txtAdts + txtChds, totalAdults, totalChilds };
    },
    totalTravellers() {
      let total = JSON.parse(this.contract.meta.travellers_rows).length;
      let txt = "";

      if (total == 1) {
        txt = `${total}  pessoa`;
      }

      if (total > 1) {
        txt = `${total}  pessoas`;
      }

      return txt;
    },
    formatFlightStops(stops) {
      let txt = "";

      if (stops == 0) {
        txt = "direto";
      }

      if (stops == 1) {
        txt = `${stops} parada`;
      }

      if (stops > 1) {
        txt = `${stops} paradas`;
      }
      return txt;
    },
  },
};
</script>

<style lang="sass" scoped>
.section-title
  padding: 5px 0 0px 0
  border-bottom: 1px solid #e1e1e1
  &.closed
    border-bottom: 0
    margin-bottom: 0
  .arrow
    color: #ccc
    font-size: 16px
    position: relative
    cursor: pointer
  h2
    font-size: 13px
    font-weight: 500 !important
    color: #aab2bd
.flight-back
  padding-top: 10px
  margin-top: 10px
  border-top: 1px solid #eee !important
.subtitle
  color: #aab2bd
.duration
  .txt1
    color: #aab2bd
  .txt2
    font-size: 10px
.stops
  color: #37bc9b
  padding-right: 20px
  text-align: center
  .border-line
    border-bottom: 1px solid #37bc9b !important
    display: block
    width: 100%

.travel-details
  .section
    padding: 20px 20px 10px
    border-bottom: 1px solid #e1e1e1
    .content
      font-size: 12px
      font-weight: 500
      color: #434a54
      padding: 10px 0
      border-bottom: 1px dashed #ccc
      .description
        padding: 10px 0 0 0
        font-size: 13px
        font-weight: 400
        .desc-title
          font-size: 11px
          color: #aab2bd
      .name
        font-size: 16px
      .travellers
        font-size: 12px
        color: #aab2bd
        margin-bottom: 10px
      label
        font-size: 13px
        color: #aab2bd
      .value
        font-size: 14px
    & :last-child
      border: 0
  & :last-child
    border: 0
</style>
