import { parse } from "vue-currency-input";
import { getTime, format } from "date-fns";

export default {
  data() {
    return {
      contract: {
        loading: false,
        tempContract: {},
        details: {},
        list: [],
        completeList: [],
        listVersion: "list",
        meta: {},
        isSale: false,
        filters: {
          loadingResults: false,
          ticketed: false,
          infoteraId: "",
          invoiceId: "",
          simplifyQuery: "",
          travellerName: "",
          locator: "",
          systemRoute: "",
          productSupplier: undefined,
          invoiceStatus: {
            selected: [],
          },
          paymentMethods: {
            list: [
              {
                label: "Cartão de Crédito",
                value: "credit-card",
              },
              {
                label: "Boleto",
                value: "billing-ticket",
              },
              {
                label: "Transferência Bancária",
                value: "bank-transfer",
              },
              {
                label: "Carta de Crédito",
                value: "payment-card",
              },
              {
                label: "Link de Pagamento",
                value: "payment-link",
              },
            ],
            selected: [],
          },
          companyBranches: {
            list: [
              {
                label: "Viajar Resorts",
                value: "Viajar Resorts",
              },
              {
                label: "Voe Simples",
                value: "Voe Simples",
              },
              {
                label: "Credimilhas",
                value: "Credimilhas",
              },
            ],
            selected: [],
          },
          companies: {
            list: [
              {
                label: "Viajar Resorts",
                value: "Viajar Resorts",
              },
              {
                label: "Voe Simples",
                value: "Voe Simples",
              },
              {
                label: "Credimilhas",
                value: "Credimilhas",
              },
            ],
            selected: undefined,
          },
          personTypes: {
            list: [
              {
                label: "Pessoa Física",
                value: "Pessoa Física",
              },
              {
                label: "Pessoa Jurídica",
                value: "Pessoa Jurídica",
              },
            ],
            selected: [],
          },
          auditStatus: {
            selected: [],
          },
          users: {
            list: [],
            selected: [],
          },
          notUsers: {
            list: [],
            selected: [],
          },
          productsCategory: {
            list: [
              {
                label: "Hotéis/Resorts",
                value: "hotel",
              },
              {
                label: "Aéreo",
                value: "flight",
              },
              {
                label: "Serviços",
                value: "service",
              },
            ],
            selected: [],
          },
          saleType: {
            list: [
              {
                label: "Pacote Completo",
                value: "Pacote Completo Terceiros",
              },
              {
                label: "Serviços",
                value: "Serviços",
              },
              {
                label: "Reserva Online",
                value: "Reserva Online",
              },
            ],
            selected: [],
          },
          flightType: {
            list: [
              {
                label: "Milhas",
                value: "Milhas",
              },
              {
                label: "Regular",
                value: "Regular",
              },
            ],
            selected: [],
          },
          products: {
            list: [],
            selected: [],
          },
          clients: {
            list: [],
            selected: [],
          },
          status: {
            list: [
              {
                label: "Concluído",
                value: "concluded",
              },
              {
                label: "Em edição",
                value: "edit",
              },
              {
                label: "Cancelado",
                value: "canceled",
              },
            ],
            selected: ["concluded", "edit"],
          },
          marketing: {
            list: [
              {
                label: "Livelo",
                value: "Livelo",
                disabled: false,
              },
            ],
            selected: [],
          },
          liveloPointsSendingStatus: {
            selected: [],
          },
          embark: {
            selected: [],
          },
          checkin: {
            selected: [],
          },
          checkout: {
            selected: [],
          },
          livelo: {
            rule: "",
            pointsRange: {
              min: "",
              max: "",
            },
            availableToSend: false,
          },
          period: {
            selected: [],
          },
          searchTerm: "",
          id: "",
          confirmedTravels: "",
          pendingTravels: "",
          joinSales: "",
          customer: "",
          saleStatus: {
            list: [
              {
                name: "Pendente",
                value: "Pendente",
              },
              {
                name: "Finalizado",
                value: "Realizado",
              },
            ],
            selected: [],
          },
          saleId: "",
          price: {
            min: "",
            max: "",
          },
          order: "desc",
          orderBy: "status",
        },
        pagination: {
          page: 1,
          perPage: 25,
          total: 0,
          complete_list_total: 0,
          totalPages: 0,
        },
      },
      allContractData: {
        totalToReceive: 0,
        flight: {
          sale: {
            netTaxes: 0,
          },
        },
        paymentMethods: {
          total: 0,
        },
        financialCosts: { total: 0 },
      },
      packageTypes: [
        {
          label: "Pacote Completo Terceiros",
          value: "Pacote Completo Terceiros",
          description: "Venda de Operadoras Terceiras, Valor único.",
        },
        {
          label: "Selecionar Serviços",
          value: "Serviços",
          description: "Vendas Próprias, Valor Separado por produtos.",
        },
        {
          label: "Reserva Online",
          value: "Reserva Online",
          description: "",
        },
      ],
      products: [
        {
          label: "Hotel",
          value: "hotel",
          disabled: false,
        },
        {
          label: "Aéreo",
          value: "flight",
          disabled: false,
        },
        {
          label: "Serviço",
          value: "service",
          disabled: false,
        },
        {
          label: "Cruzeiro",
          value: "cruise",
          disabled: true,
        },
      ],
    };
  },
  methods: {
    changeContractsPage(current) {
      this.contract.pagination.page = current;
      this.getContracts(current);
    },
    changeContractsPageSize(current, pageSize) {
      this.contract.pagination.page = current;
      this.contract.pagination.perPage = pageSize;
      this.getContracts();
    },
    contractTableChange(pagination, filters, sorter) {
      filters;
      pagination;
      this.contract.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.contract.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getContracts();
    },
    onContractSelected(id) {
      this.contract.details = null;
      this.createContract = false;
      this.getContract(id);
    },
    async getContractById(id) {
      try {
        const { data } = await this.$http.get(`/contract-v2/details?id=${id}`);
        this.contract.details = data;
        return data;
      } catch (error) {
        console.log(error);
        this.$message.error("Houve algum erro ao carregar os dados.");
        this.contract.details = {};
      }
    },
    getContract(id) {
      this.$http
        .post("/contract/details?id=" + id)
        .then(({ data }) => {
          this.loadingNewContract = false;
          this.contract.details = data;

          document.title = `VENDA ${data.id} - ${data.customer.first_name}  ${data.customer.last_name} `;

          // CONTRATO TEMPORARIO
          this.contract.tempContract = {
            ...data.contract,
            package_type: data.contract.package_type
              ? data.contract.package_type
              : "Serviços",
            the_customer: data.customer,
          };

          let allFieldsSize = Object.values(data.contract.meta).length,
            values = Object.values(data.contract.meta),
            keys = Object.keys(data.contract.meta);

          for (let i = 0; i < allFieldsSize; i++) {
            this.contract.tempContract[keys[i]] = values[i];
          }

          delete this.contract.tempContract.meta;

          this.saleForm.setFieldsValue(data);
        })
        .catch(({ response }) => {
          response;
        });
    },
    getContracts(samePage) {
      this.contract.loading = true;

      let filters = "",
        queryParams = new URLSearchParams("");
      history.pushState(null, null, "");

      if (!this.$root.isAdmin()) {
        filters += `&user=${this.$store.state.userData.id}`;
      } else {
        if (this.contract.filters.users.selected.length > 0) {
          filters += `&user=${this.contract.filters.users.selected}`;
          queryParams.set("user", `${this.contract.filters.users.selected}`);
        }
      }

      if (this.contract.filters.notUsers.selected.length > 0)
        filters += `&not-user-id=${this.contract.filters.notUsers.selected}`;

      if (this.contract.filters.companies.selected)
        filters += `&company=${this.contract.filters.companies.selected}`;

      if (this.contract.filters.simplifyQuery == "yes") {
        filters += `&simplify-query=${this.contract.filters.simplifyQuery}`;
        filters += `&system-route=${this.contract.filters.systemRoute}`;
      }

      if (this.contract.filters.saleId)
        filters += `&sale_id=${this.contract.filters.saleId}`;

      if (this.contract.filters.travellerName)
        filters += `&traveller_name=${this.contract.filters.travellerName}`;

      if (this.contract.filters.locator)
        filters += `&locator=${this.contract.filters.locator}`;

      if (this.contract.filters.infoteraId)
        filters += `&id_infotera=${this.contract.filters.infoteraId}`;

      if (this.contract.filters.invoiceId)
        filters += `&invoice_id=${this.contract.filters.invoiceId}`;

      if (this.contract.filters.livelo.rule)
        filters += `&livelo-rule=${this.contract.filters.livelo.rule}`;

      if (this.contract.filters.invoiceStatus.selected.length > 0)
        filters += `&invoice_status=${this.contract.filters.invoiceStatus.selected}`;

      if (this.contract.filters.companyBranches.selected.length > 0)
        filters += `&company_branch_id=${this.contract.filters.companyBranches.selected}`;

      if (this.contract.filters.livelo.availableToSend)
        filters += `&livelo-points-available-to-send=yes`;

      if (
        this.contract.filters.livelo.pointsRange.min ||
        this.contract.filters.livelo.pointsRange.maX
      ) {
        let min = this.contract.filters.livelo.pointsRange.min;
        let max = this.contract.filters.livelo.pointsRange.max;

        if (min) if (!max) max = "500000";

        if (max) if (!min) min = "0";

        filters += `&livelo-points-range=${min}-${max}`;
      }

      if (this.contract.filters.checkin.selected.length > 0) {
        filters += `&checkin=${this.contract.filters.checkin.selected[0]}|${this.contract.filters.checkin.selected[1]}`;
        queryParams.set(
          "checkin",
          `${this.contract.filters.checkin.selected[0]}|${this.contract.filters.checkin.selected[1]}`
        );
      }

      if (this.contract.filters.checkout.selected.length > 0) {
        filters += `&checkout=${this.contract.filters.checkout.selected[0]}|${this.contract.filters.checkout.selected[1]}`;
        queryParams.set(
          "checkout",
          `${this.contract.filters.checkout.selected[0]}|${this.contract.filters.checkout.selected[1]}`
        );
      }

      if (this.contract.filters.customer)
        filters += `&customer_id=${this.contract.filters.customer}`;

      if (this.contract.filters.productSupplier) {
        filters += `&product_supplier=${this.contract.filters.productSupplier}`;
        queryParams.set(
          "product_supplier",
          `${this.contract.filters.productSupplier}`
        );
      }

      if (this.contract.filters.joinSales)
        filters += `&join-sales=${this.contract.filters.joinSales}`;

      if (this.contract.filters.confirmedTravels)
        filters += `&confirmed_travels=${this.contract.filters.confirmedTravels}`;

      if (this.contract.filters.pendingTravels)
        filters += `&pending_travels=${this.contract.filters.pendingTravels}`;

      if (this.contract.filters.status.selected.length > 0) {
        filters += `&status=${this.contract.filters.status.selected}`;
        queryParams.set("status", `${this.contract.filters.status.selected}`);
      }

      if (this.contract.filters.embark.selected.length > 0) {
        filters += `&embark=${this.contract.filters.embark.selected[0]}|${this.contract.filters.embark.selected[1]}`;
        queryParams.set(
          "embark",
          `${this.contract.filters.embark.selected[0]}|${this.contract.filters.embark.selected[1]}`
        );

        this.contract.filters.orderBy = "checkin";
      }

      if (this.contract.filters.saleType.selected.length > 0) {
        filters += `&sale-type=${this.contract.filters.saleType.selected}`;

        queryParams.set(
          "sale-type",
          `${this.contract.filters.saleType.selected}`
        );
      }

      if (this.contract.filters.paymentMethods.selected.length > 0) {
        filters += `&payment-methods=${this.contract.filters.paymentMethods.selected}`;
      }

      if (this.contract.filters.marketing.selected.length > 0) {
        filters += `&marketing-types=${this.contract.filters.marketing.selected}`;
      }

      if (this.contract.filters.liveloPointsSendingStatus.selected.length > 0) {
        filters += `&livelo-send-status=${this.contract.filters.liveloPointsSendingStatus.selected}`;
      }

      if (this.contract.filters.personTypes.selected.length > 0) {
        filters += `&person-type=${this.contract.filters.personTypes.selected}`;
      }

      if (this.contract.filters.productsCategory.selected.length > 0) {
        filters += `&product-categories=${this.contract.filters.productsCategory.selected}`;

        queryParams.set(
          "product-categories",
          `${this.contract.filters.productsCategory.selected}`
        );
      }

      if (
        this.contract.filters.flightType.selected.length > 0 &&
        this.contract.filters.productsCategory.selected.includes("flight")
      ) {
        filters += `&flight-type=${this.contract.filters.flightType.selected}`;

        queryParams.set(
          "flight-type",
          `${this.contract.filters.flightType.selected}`
        );
      }

      if (this.contract.filters.period.selected.length > 0) {
        filters += `&period=${this.contract.filters.period.selected[0]}|${this.contract.filters.period.selected[1]}`;
        queryParams.set(
          "period",
          `${this.contract.filters.period.selected[0]}|${this.contract.filters.period.selected[1]}`
        );
      }

      if (this.contract.filters.price.min || this.contract.filters.price.max) {
        let min = this.contract.filters.price.min;
        let max = this.contract.filters.price.max;

        if (min) {
          if (!max) {
            max = "500000";
          }
        }

        if (max) {
          if (!min) {
            min = "0";
          }
        }

        filters += `&price-range=${min}-${max}`;
      }

      if (this.contract.filters.searchTerm)
        filters += `&s=${this.contract.filters.searchTerm}`;

      if (this.contract.filters.id)
        filters += `&id=${this.contract.filters.id}`;

      filters += `&order=${this.contract.filters.order}&order-by=${this.contract.filters.orderBy}`;

      history.replaceState(null, null, "?" + queryParams.toString());

      this.$http
        .get(
          `/contract-v2/${this.contract.listVersion}?page=${this.contract.pagination.page}&per_page=${this.contract.pagination.perPage}${filters}`
        )
        .then(({ data }) => {
          if (!samePage) {
            this.contract.pagination.page = 1;
          }

          this.contract.pagination.total = data.meta.total;

          this.contract.list = data.data;

          this.contract.meta = data.meta;
          this.contract.loading = false;

          this.contract.complete_list = data.complete_list;
          this.contract.pagination.complete_list_total =
            data.complete_list_total;
          this.generateContractReportsData(data.data);
        })
        .catch(({ response }) => {
          this.contract.pagination.total = response.data.meta.total;
          this.contract.list = [];
          this.contract.meta = response.data.meta;
          this.contract.loading = false;
          this.generateContractReportsData([]);
          response;
        });
    },
    contractUrlDefaults() {
      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.get("period")) {
        this.contract.filters.period.selected = urlParams
          .get("period")
          .split("|");
      }

      if (urlParams.get("status")) {
        this.contract.filters.status.selected = urlParams
          .get("status")
          .split(",");
      }

      if (urlParams.get("product_supplier")) {
        this.contract.filters.productSupplier = urlParams
          .get("product_supplier")
          .split(",");
      }

      if (
        urlParams.get("flight-type") &&
        this.contract.filters.productsCategory.selected.includes("flight")
      ) {
        this.contract.filters.flightType.selected = urlParams
          .get("flight-type")
          .split(",");
      }

      if (urlParams.get("sale-type")) {
        this.contract.filters.saleType.selected = urlParams
          .get("sale-type")
          .split(",");
      }

      if (urlParams.get("product-categories")) {
        this.contract.filters.productsCategory.selected = urlParams
          .get("product-categories")
          .split(",");
      }

      if (urlParams.get("marketing-types")) {
        this.contract.filters.marketing.selected = urlParams
          .get("marketing-types")
          .split(",");
      }

      if (urlParams.get("user")) {
        this.contract.filters.users.selected = urlParams.get("user").split(",");
      }
    },

    alertContractUser(contract) {
      let flag = false,
        now = new Date().getTime();

      if (
        contract.status == "edit" &&
        now - 86400000 > getTime(new Date(contract.theContract.raw.created))
      ) {
        flag = true;
      }

      return flag;
    },
    formatProductsList(contractedServices) {
      let allProducts = [];

      if (contractedServices) {
        JSON.parse(contractedServices).forEach((product) => {
          if (product == "hotel") {
            allProducts.push({
              color: "green",
              name: "HOTEL",
            });
          }

          if (product == "flight") {
            allProducts.push({
              color: "blue",
              name: "AÉREO",
            });
          }

          if (product == "service") {
            allProducts.push({
              color: "orange",
              name: "SERVIÇO",
            });
          }
        });
      }

      return allProducts;
    },
    formatProductsTxt(meta) {
      let txt = "";
      meta;
      if (meta.contracted_services) {
        JSON.parse(meta.contracted_services).forEach((p) => {
          p;
          // HOTEL
          if (p == "hotel") {
            JSON.parse(meta.hotel_rows).forEach((row) => {
              if (meta[`hotel_${row.id}_name`] != undefined) {
                txt += meta[`hotel_${row.id}_name`]
                  ? meta[`hotel_${row.id}_name`] + "<br>"
                  : `HOTEL ${row.id} <br>`;
              }
            });
          }

          //AÉREO
          if (p == "flight") {
            if (
              meta.flight_rows != undefined &&
              meta.flight_sections_rows == undefined
            ) {
              JSON.parse(meta.flight_rows).forEach((row, index) => {
                if (index == 0) {
                  txt += `AÉREO - ${meta[`flight_${row.id}_class`]} - SAÍDA: ${
                    meta[`flight_${row.id}_origin`]
                  } <br>`;
                }
              });
            }

            if (meta.flight_sections_rows != undefined) {
              let type = "";
              JSON.parse(meta.flight_sections_rows).forEach((flight) => {
                flight.sections.forEach((section, index) => {
                  type = ` (${
                    meta[`flight_${flight.id}_section_${section.id}_type`]
                  }) `;

                  if (type == " (undefined) ") {
                    type = "";
                  }

                  if (index == 0) {
                    txt += `AÉREO ${flight.id}  ${type} - ${
                      meta[`flight_${flight.id}_section_${section.id}_class`] !=
                      undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_class`
                          ]
                        : "CLASSE"
                    } - ${
                      meta[
                        `flight_${flight.id}_section_${section.id}_origin`
                      ] != undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_origin`
                          ]
                        : "ORIGEM"
                    } ✈ ${
                      meta[
                        `flight_${flight.id}_section_${section.id}_destination`
                      ] != undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_destination`
                          ]
                        : "DESTINO"
                    } <br> `;
                  }

                  if (
                    index == flight.sections.length - 1 &&
                    flight.sections.length > 1
                  ) {
                    txt += `AÉREO ${flight.id} ${type} - ${
                      meta[`flight_${flight.id}_section_${section.id}_class`] !=
                      undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_class`
                          ]
                        : "CLASSE"
                    } - ${
                      meta[
                        `flight_${flight.id}_section_${section.id}_origin`
                      ] != undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_origin`
                          ]
                        : "ORIGEM"
                    } ✈  ${
                      meta[
                        `flight_${flight.id}_section_${section.id}_destination`
                      ] != undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_destination`
                          ]
                        : "DESTINO"
                    } <br>`;
                  }
                });
              });
            }
          }

          // SERVIÇOS

          if (p == "service") {
            if (meta.service_rows != undefined) {
              JSON.parse(meta.service_rows).forEach((row) => {
                if (meta[`service_${row.id}_name`] != undefined) {
                  txt += meta[`service_${row.id}_name`];
                }
              });
            }
          }
        });
      }

      return txt.toUpperCase();
    },
    formatProducts(meta) {
      let allProducts = [];

      if (meta.contracted_services) {
        JSON.parse(meta.contracted_services).forEach((p) => {
          // HOTEL
          if (p == "hotel" && meta.hotel_rows != undefined) {
            JSON.parse(meta.hotel_rows).forEach((row) => {
              if (meta[`hotel_${row.id}_name`] != undefined) {
                allProducts.push({
                  color: "green",
                  name: meta[`hotel_${row.id}_name`]
                    ? meta[`hotel_${row.id}_name`]
                    : `HOTEL ${row.id}`,
                });
              }
            });
          }

          // AÉREO
          if (p == "flight") {
            if (
              meta.flight_rows != undefined &&
              meta.flight_sections_rows == undefined
            ) {
              JSON.parse(meta.flight_rows).forEach((row, index) => {
                row;

                if (index == 0) {
                  allProducts.push({
                    color: "blue",
                    name: `AÉREO - ${meta[`flight_${row.id}_class`]} - SAíDA: ${
                      meta[`flight_${row.id}_origin`]
                    }`,
                  });
                }
              });
            }

            if (meta.flight_sections_rows != undefined) {
              let type = "";
              JSON.parse(meta.flight_sections_rows).forEach((flight) => {
                flight.sections.forEach((section, index) => {
                  type = ` (${
                    meta[`flight_${flight.id}_section_${section.id}_type`]
                  }) `;

                  if (type == " (undefined) ") {
                    type = "";
                  }

                  if (index == 0) {
                    allProducts.push({
                      color: "blue",
                      name: `AÉREO ${flight.id}  ${type} - ${
                        meta[
                          `flight_${flight.id}_section_${section.id}_class`
                        ] != undefined
                          ? meta[
                              `flight_${flight.id}_section_${section.id}_class`
                            ]
                          : "CLASSE"
                      } - ${
                        meta[
                          `flight_${flight.id}_section_${section.id}_origin`
                        ] != undefined
                          ? meta[
                              `flight_${flight.id}_section_${section.id}_origin`
                            ]
                          : "ORIGEM"
                      } <i class="fas fa-plane" style="margin: 0 2px; font-size: 8px; top:-1px; position: relative;"></i>  ${
                        meta[
                          `flight_${flight.id}_section_${section.id}_destination`
                        ] != undefined
                          ? meta[
                              `flight_${flight.id}_section_${section.id}_destination`
                            ]
                          : "DESTINO"
                      }`,
                    });
                  }

                  if (
                    index == flight.sections.length - 1 &&
                    flight.sections.length > 1
                  ) {
                    allProducts.push({
                      color: "blue",
                      name: `AÉREO ${flight.id} ${type} - ${
                        meta[
                          `flight_${flight.id}_section_${section.id}_class`
                        ] != undefined
                          ? meta[
                              `flight_${flight.id}_section_${section.id}_class`
                            ]
                          : "CLASSE"
                      } - ${
                        meta[
                          `flight_${flight.id}_section_${section.id}_origin`
                        ] != undefined
                          ? meta[
                              `flight_${flight.id}_section_${section.id}_origin`
                            ]
                          : "ORIGEM"
                      } <i class="fas fa-plane" style="margin: 0 2px; font-size: 8px; top:-1px; position: relative;"></i>  ${
                        meta[
                          `flight_${flight.id}_section_${section.id}_destination`
                        ] != undefined
                          ? meta[
                              `flight_${flight.id}_section_${section.id}_destination`
                            ]
                          : "DESTINO"
                      }`,
                    });
                  }
                });
              });
            }
          }

          // SERVIÇOS

          if (p == "service" && meta.service_rows != undefined) {
            JSON.parse(meta.service_rows).forEach((row) => {
              if (meta[`service_${row.id}_name`] != undefined) {
                allProducts.push({
                  color: "orange",
                  name: meta[`service_${row.id}_name`],
                });
              }
            });
          }
        });
      }

      return allProducts.length > 0
        ? allProducts
        : [
            {
              color: "",
              name: "SEM PRODUTOS",
            },
          ];
    },
    contractPricingData(meta) {
      let total = 0,
        totalNet = 0,
        totalToReceive = 0,
        totalProfit = 0,
        totalProfitPercentage = 0,
        totalTaxes = 0,
        toReceiveList = [],
        paymentMethodsList = [],
        extraFeesList = [],
        netList = [],
        profitList = [],
        productsNames = [];

      total +=
        this.hotelPricingData(meta).total +
        this.servicePricingData(meta).total +
        this.flightPricingData(meta).total +
        this.packagePricingData(meta).total;

      totalNet +=
        this.hotelPricingData(meta).totalNet +
        this.servicePricingData(meta).totalNet +
        this.flightPricingData(meta).totalNet +
        this.packagePricingData(meta).totalNet -
        this.directPaymentWithSupplier(meta);

      totalToReceive += this.financialCostData(meta).creditCard.totalToReceive;

      totalTaxes +=
        this.flightPricingData(meta).totalTaxes +
        this.hotelPricingData(meta).totalExtraFees +
        this.servicePricingData(meta).totalExtraFees +
        this.packagePricingData(meta).totalExtraFees +
        this.financialCostData(meta).total;

      totalProfit +=
        this.hotelPricingData(meta).totalProfit +
        this.hotelPricingData(meta).totalIncentives +
        this.servicePricingData(meta).totalProfit +
        this.servicePricingData(meta).totalIncentives +
        this.flightPricingData(meta).totalProfit +
        this.flightPricingData(meta).totalIncentives +
        this.packagePricingData(meta).totalProfit +
        this.packagePricingData(meta).totalIncentives -
        this.financialCostData(meta).total -
        this.financialCostData(meta).paymentCard.total -
        this.financialCostData(meta).marketing.livelo.total -
        this.hotelPricingData(meta).totalExtraFees -
        this.servicePricingData(meta).totalExtraFees -
        // this.flightPricingData(meta).totalExtraFees -
        this.packagePricingData(meta).totalExtraFees;

      totalProfitPercentage = (100 * totalProfit) / total;

      productsNames = [
        ...this.hotelPricingData(meta).productsNames,
        ...this.flightPricingData(meta).productsNames,
        ...this.servicePricingData(meta).productsNames,
      ];

      if (this.tempContract != undefined) {
        this.tempContract["contract_total_value"] = total;
        this.tempContract["contract_net_value"] = totalNet;
        this.tempContract["contract_profit_value"] = totalProfit;
        this.tempContract["contract_profit_percentage_value"] =
          totalProfitPercentage;
        this.tempContract["contract_taxes_value"] = totalTaxes;
        this.tempContract["contract_financial_cost"] =
          this.financialCostData(meta).total;

        this.tempContract["value"] = total;
        this.tempContract["value_to_pay"] = totalNet;
        this.tempContract["taxes_value"] = totalTaxes;
        this.tempContract["profit_value"] = totalProfit;
        this.tempContract["profit_percentage"] = totalProfitPercentage;
        this.tempContract["financial_cost"] =
          this.financialCostData(meta).total;

        this.tempContract["products_name"] = JSON.stringify(productsNames);

        this.getLastCheckout(meta);
        this.getFirstCheckin(meta);
      }

      toReceiveList = [
        ...this.hotelPricingData(meta).toReceiveList,
        ...this.flightPricingData(meta).toReceiveList,
        ...this.servicePricingData(meta).toReceiveList,
        ...this.packagePricingData(meta).toReceiveList,
      ];

      paymentMethodsList = [
        ...this.paymentMethodsData(meta).paymentMethodsList,
      ];

      extraFeesList = [
        ...this.hotelPricingData(meta).extraFeesList,
        ...this.flightPricingData(meta).listTaxes,
        ...this.flightPricingData(meta).extraFeesList,
        ...this.servicePricingData(meta).extraFeesList,
        ...this.packagePricingData(meta).extraFeesList,
        ...this.financialCostData(meta).list,
      ];

      netList = [
        ...this.hotelPricingData(meta).netList,
        ...this.flightPricingData(meta).netList,
        ...this.servicePricingData(meta).netList,
        ...this.packagePricingData(meta).netList,
      ];

      profitList = [
        ...this.hotelPricingData(meta).profitList,
        ...this.flightPricingData(meta).profitList,
        ...this.servicePricingData(meta).profitList,
        ...this.packagePricingData(meta).profitList,
        ...this.financialCostData(meta).profitList,
      ];

      this.allContractData = {
        total: parseFloat(total.toFixed(2)),
        totalNet,
        totalToReceive,
        totalTaxes,
        totalProfit,
        totalProfitPercentage,
        hotel: this.hotelPricingData(meta),
        flight: this.flightPricingData(meta),
        service: this.servicePricingData(meta),
        package: this.packagePricingData(meta),
        paymentMethods: this.paymentMethodsData(meta),
        financialCosts: this.financialCostData(meta),
        toReceiveList,
        paymentMethodsList,
        extraFeesList,
        netList,
        profitList,
        productsNames,
      };
    },
    paymentMethodsData(meta) {
      let paymentMethods =
          meta[`payment_methods`] != undefined
            ? JSON.parse(meta[`payment_methods`])
            : [],
        total = 0,
        billingTicket = 0,
        paymentCard = 0,
        creditCard = 0,
        paymentLink = 0,
        customPayment = 0,
        bankTransfer = 0,
        paymentMethodsList = [];

      if (paymentMethods.includes("credit-card")) {
        if (meta["credit_card_rows"] != undefined) {
          JSON.parse(meta["credit_card_rows"]).forEach((card) => {
            creditCard +=
              meta[`payment_methods_credit_card_total_value_${card.id}`] !=
              undefined
                ? parse(
                    meta[`payment_methods_credit_card_total_value_${card.id}`]
                  )
                : 0;

            paymentMethodsList.push({
              payment: `credit-card-${card.id}`,
              name: `Cartão de Crédito ${card.id}`,
              rawValue: parse(
                meta[`payment_methods_credit_card_total_value_${card.id}`]
              ),
              value: `<font class=""> ${this.formatAnyPricePtBr(
                parse(
                  meta[`payment_methods_credit_card_total_value_${card.id}`]
                )
              )}</font>`,
            });
          });
        }
      }

      if (paymentMethods.includes("bank-transfer")) {
        bankTransfer =
          meta["payment_methods_bank_transfer_total_value"] != undefined
            ? meta["payment_methods_bank_transfer_total_value"]
            : 0;

        bankTransfer = parse(bankTransfer);

        paymentMethodsList.push({
          payment: `bank-transfer`,
          name: `Transferência Bancária`,
          rawValue: bankTransfer,
          value: `<font class=""> ${this.formatAnyPricePtBr(
            bankTransfer
          )}</font>`,
        });
      }

      if (paymentMethods.includes("billing-ticket")) {
        billingTicket =
          meta["payment_methods_billing_ticket_total_value"] != undefined
            ? meta["payment_methods_billing_ticket_total_value"]
            : 0;

        billingTicket = parse(billingTicket);

        paymentMethodsList.push({
          payment: `billing-ticket`,
          name: `Boleto Bancário`,
          rawValue: billingTicket,
          value: `<font class=""> ${this.formatAnyPricePtBr(
            billingTicket
          )}</font>`,
        });
      }

      if (paymentMethods.includes("custom-payment")) {
        customPayment =
          meta["payment_methods_custom_payment_total_value"] != undefined
            ? meta["payment_methods_custom_payment_total_value"]
            : 0;

        customPayment = parse(customPayment);

        paymentMethodsList.push({
          payment: `custom-payment`,
          // name: `Incentivo ${incentive.id} <font class="cprimary f8 "><i>${productName} ${id}  (venda)</i></font>`,
          name: meta["payment_methods_custom_payment_name"]
            ? meta["payment_methods_custom_payment_name"]
            : `Pagamento personalizado`,
          rawValue: customPayment,
          value: `<font class=""> ${this.formatAnyPricePtBr(
            customPayment
          )}</font>`,
        });
      }

      if (paymentMethods.includes("payment-card")) {
        paymentCard =
          meta[`payment_methods_payment_card_total_value`] != undefined
            ? meta[`payment_methods_payment_card_total_value`]
            : 0;

        paymentCard = parse(paymentCard);

        paymentMethodsList.push({
          payment: `payment-card`,
          name: `Carta de Pagamento`,
          rawValue: paymentCard,
          value: `<font class=""> ${this.formatAnyPricePtBr(
            paymentCard
          )}</font>`,
        });
      }

      if (paymentMethods.includes("payment-link")) {
        paymentLink =
          meta[`payment_methods_payment_link_total_value`] != undefined
            ? meta[`payment_methods_payment_link_total_value`]
            : 0;

        paymentLink = parse(paymentLink);

        paymentMethodsList.push({
          payment: `payment-link`,
          name: `Link de Pagamento`,
          rawValue: paymentLink,
          value: `<font class=""> ${this.formatAnyPricePtBr(
            paymentLink
          )}</font>`,
        });
      }

      total =
        billingTicket +
        paymentCard +
        creditCard +
        paymentLink +
        customPayment +
        bankTransfer;

      return {
        billingTicket,
        paymentCard,
        creditCard,
        paymentLink,
        customPayment,
        bankTransfer,
        total: parseFloat(total.toFixed(2)),
        paymentMethodsList,
      };
    },
    getFirstCheckin(meta) {
      let theDate = "",
        services = meta.contracted_services
          ? JSON.parse(meta.contracted_services)
          : [];

      if (services.includes("hotel")) {
        theDate = meta.hotel_1_checkin ? meta.hotel_1_checkin : "";
      }

      if (services.includes("service")) {
        theDate = meta.service_1_checkin ? meta.service_1_checkin : "";
      }

      if (services.includes("flight")) {
        theDate = meta.flight_1_section_1_departure_date
          ? meta.flight_1_section_1_departure_date
          : "";
      }

      this.tempContract.contract_first_checkin = theDate;
      this.tempContract.first_checkin = theDate;
    },
    getLastCheckout(meta) {
      let theDate = "",
        services = meta.contracted_services
          ? JSON.parse(meta.contracted_services)
          : [],
        flights = [];

      if (services.includes("hotel")) {
        let hotels = meta.hotel_rows ? JSON.parse(meta.hotel_rows) : [],
          totalhotels = hotels.length;
        theDate = meta[`hotel_${totalhotels}_checkout`];
      }

      if (services.includes("service")) {
        let servicesContract = meta.service_rows
            ? JSON.parse(meta.service_rows)
            : [],
          checkoutArr = [];

        servicesContract.forEach(({ id }) => {
          checkoutArr.push(new Date(meta[`service_${id}_checkout`]));
        });

        if (checkoutArr.length) {
          const maxDate = new Date(Math.max(...checkoutArr)); // Find the maximum date
          theDate = format(maxDate, "yyyy-MM-dd");
        }
      }

      if (services.includes("flight")) {
        flights = meta.flight_sections_rows
          ? JSON.parse(meta.flight_sections_rows)
          : [];
        let totalFlights = flights.length,
          lastSection = 0;

        if (totalFlights > 0) {
          lastSection = flights[totalFlights - 1].sections.length;
        }

        theDate =
          meta[`flight_${totalFlights}_section_${lastSection}_arrival_date`];
      }

      this.tempContract.contract_last_checkout = theDate;
      this.tempContract.last_checkout = theDate;
    },
    saleTaxesAndIncentives(meta, product, id, productName) {
      let totalProfit = 0,
        profitList = [],
        theId = "";

      if (id) {
        theId = `_${id}`;
      }

      // INCENTIVES
      if (meta[`sale_${product}${theId}_incentives_rows`] != undefined) {
        JSON.parse(meta[`sale_${product}${theId}_incentives_rows`]).forEach(
          (incentive) => {
            if (
              meta[
                `sale_${product}${theId}_incentive_${incentive.id}_sum_in_profit`
              ] == true ||
              meta[
                `sale_${product}${theId}_incentive_${incentive.id}_sum_in_profit`
              ] == 1
            ) {
              if (
                meta[
                  `sale_${product}${theId}_incentive_${incentive.id}_type`
                ] == "%"
              ) {
                //
                totalProfit += parseFloat(
                  meta[
                    `sale_${product}${theId}_incentive_${incentive.id}_percentage_value`
                  ]
                );

                //
                profitList.push({
                  id: `${product}-${id}-incentive-${incentive.id}`,
                  name: `Incentivo ${incentive.id} <font class="cprimary f8 "><i>${productName} ${id}  (venda)</i></font>`,
                  rawValue:
                    meta[
                      `sale_${product}${theId}_incentive_${incentive.id}_percentage_value`
                    ],
                  value: `<font class="cgreen"> ${this.formatAnyPricePtBr(
                    meta[
                      `sale_${product}${theId}_incentive_${incentive.id}_percentage_value`
                    ]
                  )}</font>`,
                });
              } else {
                totalProfit += parse(
                  meta[
                    `sale_${product}${theId}_incentive_${incentive.id}_value`
                  ]
                );

                profitList.push({
                  id: `${product}-${id}-incentive-${incentive.id}`,
                  name: `Incentivo ${incentive.id} <font class="cprimary f8 "><i>${productName} ${id} (venda)</i></font>`,
                  rawValue:
                    meta[
                      `sale_${product}${theId}_incentive_${incentive.id}_value`
                    ],
                  value: `<font class="cgreen"> ${this.formatAnyPricePtBr(
                    meta[
                      `sale_${product}${theId}_incentive_${incentive.id}_value`
                    ]
                  )}</font>`,
                });
              }
            } else {
              ///
              if (
                meta[
                  `sale_${product}${theId}_incentive_${incentive.id}_type`
                ] == "%"
              ) {
                //
                profitList.push({
                  id: `${product}-${id}-incentive-${incentive.id}`,
                  name: `Incentivo ${incentive.id} <font class="cprimary f8 "><i>${productName} ${id} (venda)</i></font>`,
                  rawValue:
                    meta[
                      `sale_${product}${theId}_incentive_${incentive.id}_percentage_value`
                    ],
                  value: `<font class="cgreen"> ${this.formatAnyPricePtBr(
                    meta[
                      `sale_${product}${theId}_incentive_${incentive.id}_percentage_value`
                    ]
                  )}</font>`,
                });
              } else {
                profitList.push({
                  id: `${product}-${id}-incentive-${incentive.id}`,
                  name: `Incentivo ${incentive.id} <font class="cprimary f8 "><i>${productName} ${id} (venda)</i></font>`,
                  rawValue:
                    meta[
                      `sale_${product}${theId}_incentive_${incentive.id}_value`
                    ],
                  value: `<font class="cgreen"> ${this.formatAnyPricePtBr(
                    meta[
                      `sale_${product}${theId}_incentive_${incentive.id}_value`
                    ]
                  )}</font>`,
                });
              }
            }
          }
        );
      }

      // TAXES
      if (meta[`sale_${product}${theId}_taxes_rows`] != undefined) {
        JSON.parse(meta[`sale_${product}${theId}_taxes_rows`]).forEach(
          (tax) => {
            if (
              meta[`sale_${product}${theId}_tax_${tax.id}_type`] &&
              meta[`sale_${product}${theId}_tax_${tax.id}_value`]
            ) {
              if (
                meta[
                  `sale_${product}${theId}}_tax_${tax.id}_subtract_in_profit`
                ] == 1 ||
                meta[
                  `sale_${product}${theId}_tax_${tax.id}_subtract_in_profit`
                ] == true
              ) {
                totalProfit -= parse(
                  meta[`sale_${product}${theId}_tax_${tax.id}_value`]
                );
              }

              profitList.push({
                id: `${product}-${id}-tax-${tax.id}`,
                name: `${
                  meta[`sale_${product}${theId}_tax_${tax.id}_type`]
                } <font class="cprimary f8 "><i>${productName} ${id} (venda)</i></font>`,
                rawValue: meta[`sale_${product}${theId}_tax_${tax.id}_value`],
                value: `<font class="red">- ${this.formatAnyPricePtBr(
                  meta[`sale_${product}${theId}_tax_${tax.id}_value`]
                )}</font>`,
              });
            }
          }
        );
      }

      return {
        totalProfit,
        profitList,
      };
    },
    hotelPricingData(meta) {
      let total = 0,
        totalNet = 0,
        totalProfit = 0,
        totalIncentives = 0,
        totalExtraFees = 0,
        toReceiveList = [],
        netList = [],
        profitList = [],
        extraFeesList = [],
        productsNames = [],
        hotelRows = meta.hotel_rows ? JSON.parse(meta.hotel_rows) : [],
        contractedServices = meta.contracted_services
          ? JSON.parse(meta.contracted_services)
          : [],
        packageType = meta.package_type;

      if (
        (hotelRows.length > 0 &&
          contractedServices.includes("hotel") &&
          packageType == "Serviços") ||
        (hotelRows.length > 0 &&
          contractedServices.includes("hotel") &&
          packageType == "Reserva Online")
      ) {
        hotelRows.forEach((hotel) => {
          productsNames.push({
            product: "hotel",
            productId: hotel.id,
            productAlias: "Hotel",
            name: meta[`hotel_${hotel.id}_name`],
          });

          // TOTAL
          total += parse(meta[`hotel_${hotel.id}_total_price`]);

          // NET
          totalNet += parse(
            meta[`contract_finances_hotel_${hotel.id}_net_value`]
          );

          profitList.push({
            id: `hotel-${hotel.id}`,
            name: `Lucratividade <font class="cprimary f8"><i>hotel ${hotel.id}</i></font>`,
            rawValue:
              parse(meta[`hotel_${hotel.id}_total_price`]) -
              parse(meta[`contract_finances_hotel_${hotel.id}_net_value`]),
            value: `<font class="${
              total - totalNet > 0 ? "cgreen" : "red"
            }">${this.formatAnyPricePtBr(
              parse(meta[`hotel_${hotel.id}_total_price`]) -
                parse(meta[`contract_finances_hotel_${hotel.id}_net_value`])
            )}`,
          });

          // TAXES
          if (
            meta[`contract_finances_hotel_${hotel.id}_taxes_rows`] != undefined
          ) {
            JSON.parse(
              meta[`contract_finances_hotel_${hotel.id}_taxes_rows`]
            ).forEach((tax) => {
              if (
                meta[
                  `contract_finances_hotel_${hotel.id}_tax_${tax.id}_type`
                ] &&
                meta[`contract_finances_hotel_${hotel.id}_tax_${tax.id}_value`]
              ) {
                totalExtraFees += parse(
                  meta[
                    `contract_finances_hotel_${hotel.id}_tax_${tax.id}_value`
                  ]
                );

                if (
                  meta[
                    `contract_finances_hotel_${hotel.id}_tax_${tax.id}_add_in_total`
                  ] == true ||
                  meta[
                    `contract_finances_hotel_${hotel.id}_tax_${tax.id}_add_in_total`
                  ] == 1
                ) {
                  total += parse(
                    meta[
                      `contract_finances_hotel_${hotel.id}_tax_${tax.id}_value`
                    ]
                  );

                  toReceiveList.push({
                    id: `hotel-${hotel.id}-tax-${tax.id}`,
                    name: `${
                      meta[
                        `contract_finances_hotel_${hotel.id}_tax_${tax.id}_type`
                      ]
                    } <font class="cprimary f8"><i>Hotel ${
                      hotel.id
                    }</i></font>`,
                    value: this.formatAnyPricePtBr(
                      meta[
                        `contract_finances_hotel_${hotel.id}_tax_${tax.id}_value`
                      ]
                    ),
                  });
                }

                extraFeesList.push({
                  id: `hotel-${hotel.id}-tax-${tax.id}`,
                  name: `${
                    meta[
                      `contract_finances_hotel_${hotel.id}_tax_${tax.id}_type`
                    ]
                  } <font class="cprimary f8"><i>Hotel ${hotel.id}</i></font>`,
                  value: this.formatAnyPricePtBr(
                    meta[
                      `contract_finances_hotel_${hotel.id}_tax_${tax.id}_value`
                    ]
                  ),
                });
              }
            });
          }

          // INCENTIVES
          if (
            meta[`contract_finances_hotel_${hotel.id}_incentives_rows`] !=
            undefined
          ) {
            JSON.parse(
              meta[`contract_finances_hotel_${hotel.id}_incentives_rows`]
            ).forEach((incentive) => {
              if (
                meta[
                  `contract_finances_hotel_${hotel.id}_incentive_${incentive.id}_type`
                ] == "%"
              ) {
                totalIncentives += parseFloat(
                  meta[
                    `contract_finances_hotel_${hotel.id}_incentive_${incentive.id}_percentage_value`
                  ]
                );

                if (
                  meta[
                    `contract_finances_hotel_${hotel.id}_incentive_${incentive.id}_add_in_total`
                  ] == true ||
                  meta[
                    `contract_finances_hotel_${hotel.id}_incentive_${incentive.id}_add_in_total`
                  ] == 1
                ) {
                  total += parseFloat(
                    meta[
                      `contract_finances_hotel_${hotel.id}_incentive_${incentive.id}_percentage_value`
                    ]
                  );

                  toReceiveList.push({
                    id: `hotel-${hotel.id}-incentive-${incentive.id}`,
                    name: `Incentivo ${incentive.id} <font class="cprimary f8"><i>hotel ${hotel.id}</i></font>`,
                    rawValue:
                      meta[
                        `contract_finances_hotel_${hotel.id}_incentive_${incentive.id}_percentage_value`
                      ],
                    value: `<font class="">${this.formatPricePtBr(
                      meta[
                        `contract_finances_hotel_${hotel.id}_incentive_${incentive.id}_percentage_value`
                      ]
                    )}</font>`,
                  });
                }

                profitList.push({
                  id: `hotel-${hotel.id}-incentive-${incentive.id}`,
                  name: `Incentivo ${incentive.id} <font class="cprimary f8"><i>hotel ${hotel.id}</i></font>`,
                  rawValue:
                    meta[
                      `contract_finances_hotel_${hotel.id}_incentive_${incentive.id}_percentage_value`
                    ],
                  value: `<font class="cgreen">${this.formatPricePtBr(
                    meta[
                      `contract_finances_hotel_${hotel.id}_incentive_${incentive.id}_percentage_value`
                    ]
                  )}</font>`,
                });
              } else {
                totalIncentives += parse(
                  meta[
                    `contract_finances_hotel_${hotel.id}_incentive_${incentive.id}_value`
                  ]
                );

                if (
                  meta[
                    `contract_finances_hotel_${hotel.id}_incentive_${incentive.id}_add_in_total`
                  ] == true ||
                  meta[
                    `contract_finances_hotel_${hotel.id}_incentive_${incentive.id}_add_in_total`
                  ] == 1
                ) {
                  total += parse(
                    meta[
                      `contract_finances_hotel_${hotel.id}_incentive_${incentive.id}_value`
                    ]
                  );

                  toReceiveList.push({
                    id: `hotel-${hotel.id}-incentive-${incentive.id}`,
                    name: `Incentivo ${incentive.id} <font class="cprimary f8"><i>hotel ${hotel.id}</i></font>`,
                    rawValue: parse(
                      meta[
                        `contract_finances_hotel_${hotel.id}_incentive_${incentive.id}_value`
                      ]
                    ),
                    value: `<font class="">${this.formatAnyPricePtBr(
                      parse(
                        meta[
                          `contract_finances_hotel_${hotel.id}_incentive_${incentive.id}_value`
                        ]
                      )
                    )}</font>`,
                  });
                }

                profitList.push({
                  id: `hotel-${hotel.id}-incentive-${incentive.id}`,
                  name: `Incentivo ${incentive.id} <font class="cprimary f8"><i>hotel ${hotel.id}</i></font>`,
                  rawValue:
                    meta[
                      `contract_finances_hotel_${hotel.id}_incentive_${incentive.id}_value`
                    ],
                  value: `<font class="cgreen">${this.formatAnyPricePtBr(
                    meta[
                      `contract_finances_hotel_${hotel.id}_incentive_${incentive.id}_value`
                    ]
                  )}</font>`,
                });
              }
            });
          }

          toReceiveList.push({
            id: `hotel-${hotel.id}`,
            name: `${
              meta[`hotel_${hotel.id}_name`]
                ? meta[`hotel_${hotel.id}_name`]
                : "Hotel"
            } <font class="cprimary f8"><i>hotel ${hotel.id}</i></font>`,
            rawValue: parse(meta[`hotel_${hotel.id}_total_price`]),
            value: `<font class="">${this.formatAnyPricePtBr(
              parse(meta[`hotel_${hotel.id}_total_price`])
            )}`,
          });

          netList.push({
            id: `hotel-${hotel.id}`,
            name: `Neto <font class="cprimary f8"><i>hotel ${hotel.id}</i></font>`,
            rawValue: parse(
              meta[`contract_finances_hotel_${hotel.id}_net_value`]
            ),
            value: `<font class="${
              totalNet > 0 ? "" : ""
            }">${this.formatAnyPricePtBr(
              parse(meta[`contract_finances_hotel_${hotel.id}_net_value`])
            )}`,
          });

          totalIncentives += this.saleTaxesAndIncentives(
            meta,
            "hotel",
            hotel.id,
            "hotel"
          ).totalProfit;
          profitList = [
            ...profitList,
            ...this.saleTaxesAndIncentives(meta, "hotel", hotel.id, "hotel")
              .profitList,
          ];
        });
      }

      totalProfit += total - totalNet;

      return {
        total,
        totalNet,
        totalProfit,
        totalIncentives,
        totalExtraFees,
        toReceiveList,
        netList,
        profitList,
        extraFeesList,
        productsNames,
      };
    },
    regularFlight(flight, meta) {
      let total = 0,
        totalNet = 0,
        totalProfit = 0,
        totalTaxes = 0,
        listTaxes;

      flight.travellers.forEach((traveller) => {
        total +=
          parse(
            meta[`flight_${flight.id}_traveller_${traveller.id}_value`]
              ? meta[`flight_${flight.id}_traveller_${traveller.id}_value`]
              : 0
          ) +
          parse(
            meta[`flight_${flight.id}_traveller_${traveller.id}_taxes`]
              ? meta[`flight_${flight.id}_traveller_${traveller.id}_taxes`]
              : 0
          ) +
          parse(
            meta[`flight_${flight.id}_traveller_${traveller.id}_du_tax`]
              ? meta[`flight_${flight.id}_traveller_${traveller.id}_du_tax`]
              : 0
          ) +
          parse(
            meta[`flight_${flight.id}_traveller_${traveller.id}_teb_agency`]
              ? meta[`flight_${flight.id}_traveller_${traveller.id}_teb_agency`]
              : 0
          );

        totalTaxes += parse(
          meta[`flight_${flight.id}_traveller_${traveller.id}_taxes`]
            ? meta[`flight_${flight.id}_traveller_${traveller.id}_taxes`]
            : 0
        );

        totalNet += parse(
          meta[`flight_${flight.id}_traveller_${traveller.id}_value`]
            ? meta[`flight_${flight.id}_traveller_${traveller.id}_value`]
            : 0
        );
      });

      totalProfit = total - totalNet - totalTaxes;

      return {
        total,
        totalNet,
        totalProfit,
        totalTaxes,
        listTaxes,
      };
    },
    milesFlight(flight, meta) {
      let total = 0,
        totalNet = 0,
        totalProfit = 0,
        totalTaxes = 0;

      flight.travellers.forEach((traveller) => {
        if (meta[`flight_${flight.id}_traveller_${traveller.id}_value`]) {
          if (
            meta[
              `flight_${flight.id}_traveller_${traveller.id}_taxes_currency`
            ] == "BRL"
          ) {
            total +=
              parse(
                meta[`flight_${flight.id}_traveller_${traveller.id}_value`]
                  ? meta[`flight_${flight.id}_traveller_${traveller.id}_value`]
                  : 0
              ) +
              parse(
                meta[`flight_${flight.id}_traveller_${traveller.id}_taxes`]
                  ? meta[`flight_${flight.id}_traveller_${traveller.id}_taxes`]
                  : 0
              );

            totalTaxes += parse(
              meta[`flight_${flight.id}_traveller_${traveller.id}_taxes`]
                ? meta[`flight_${flight.id}_traveller_${traveller.id}_taxes`]
                : 0
            );
          } else {
            //
            total += parse(
              meta[`flight_${flight.id}_traveller_${traveller.id}_value`]
                ? meta[`flight_${flight.id}_traveller_${traveller.id}_value`]
                : 0
            );

            if (meta[`flight_${flight.id}_traveller_${traveller.id}_taxes`]) {
              total +=
                parse(
                  meta[`flight_${flight.id}_traveller_${traveller.id}_taxes`]
                ) * parse(meta[`voucher_payments_exchange`]);

              totalTaxes +=
                parse(
                  meta[`flight_${flight.id}_traveller_${traveller.id}_taxes`]
                ) * parse(meta[`voucher_payments_exchange`]);
            }
          }
        }
      });

      if (meta[`contract_finances_flight_${flight.id}_sale_value`]) {
        totalNet += parseFloat(
          meta[`contract_finances_flight_${flight.id}_sale_value`]
        );
      }

      totalProfit = total - totalNet - totalTaxes;

      return {
        total,
        totalNet,
        totalProfit,
        totalTaxes,
      };
    },
    flightProductName(flight, meta) {
      let theName = "",
        type = "";

      flight.sections.forEach((section, index) => {
        type = ` (${meta[`flight_${flight.id}_section_${section.id}_type`]}) `;

        if (type == " (undefined) ") {
          type += "";
        }

        if (index == 0) {
          theName += `AÉREO ${flight.id}  ${type} - ${
            meta[`flight_${flight.id}_section_${section.id}_class`] != undefined
              ? meta[`flight_${flight.id}_section_${section.id}_class`]
              : "CLASSE"
          } - ${
            meta[`flight_${flight.id}_section_${section.id}_origin`] !=
            undefined
              ? meta[`flight_${flight.id}_section_${section.id}_origin`]
              : "ORIGEM"
          } ⇒  ${
            meta[`flight_${flight.id}_section_${section.id}_destination`] !=
            undefined
              ? meta[`flight_${flight.id}_section_${section.id}_destination`]
              : "DESTINO"
          } | `;
        }

        if (index == flight.sections.length - 1 && flight.sections.length > 1) {
          theName += `AÉREO ${flight.id} ${type} - ${
            meta[`flight_${flight.id}_section_${section.id}_class`] != undefined
              ? meta[`flight_${flight.id}_section_${section.id}_class`]
              : "CLASSE"
          } - ${
            meta[`flight_${flight.id}_section_${section.id}_origin`] !=
            undefined
              ? meta[`flight_${flight.id}_section_${section.id}_origin`]
              : "ORIGEM"
          } ⇒ ${
            meta[`flight_${flight.id}_section_${section.id}_destination`] !=
            undefined
              ? meta[`flight_${flight.id}_section_${section.id}_destination`]
              : "DESTINO"
          }`;
        }
      });

      return theName;
    },
    flightPricingData(meta) {
      let total = 0,
        totalNet = 0,
        totalTaxes = 0,
        totalProfit = 0,
        totalExtraFees = 0,
        toReceiveList = [],
        listTaxes = [],
        netList = [],
        profitList = [],
        extraFeesList = [],
        productsNames = [],
        totalIncentives = 0,
        sale = {
          netTaxes: 0,
        },
        flightSectionRows = meta.flight_sections_rows
          ? JSON.parse(meta.flight_sections_rows)
          : [],
        contractedServices = meta.contracted_services
          ? JSON.parse(meta.contracted_services)
          : [],
        packageType = meta.package_type;

      productsNames;

      if (
        (flightSectionRows.length > 0 &&
          contractedServices.includes("flight") &&
          packageType == "Serviços") ||
        (flightSectionRows.length > 0 &&
          contractedServices.includes("flight") &&
          packageType == "Reserva Online")
      ) {
        flightSectionRows.forEach((flight) => {
          if (meta[`flight_${flight.id}_type`] == "Regular") {
            total += this.regularFlight(flight, meta).total;
            totalNet += this.regularFlight(flight, meta).totalNet; // remove net
            totalProfit += this.regularFlight(flight, meta).totalProfit;
            totalTaxes += this.regularFlight(flight, meta).totalTaxes;

            toReceiveList.push({
              id: `flight-${flight.id}`,
              name: `Aéreo ${
                meta[`flight_${flight.id}_type`]
              } <font class="cprimary f8"><i>aéreo ${flight.id}</i></font>`,
              rawValue: this.regularFlight(flight, meta).total,
              value: `<font class="${
                this.regularFlight(flight, meta).total > 0 ? "" : ""
              }">${this.formatPricePtBr(
                this.regularFlight(flight, meta).total
              )}`,
            });

            netList.push({
              id: `flight-${flight.id}`,
              name: `Neto <font class="cprimary f8"><i>aéreo ${flight.id} (regular)</i></font>`,
              rawValue: this.regularFlight(flight, meta).totalNet,
              value: `<font class="${
                this.regularFlight(flight, meta).totalNet > 0 ? "" : ""
              }">${this.formatPricePtBr(
                this.regularFlight(flight, meta).totalNet
              )}`,
            });

            profitList.push({
              id: `flight-${flight.id}`,
              name: `Lucratividade <font class="cprimary f8"><i>aéreo ${flight.id} (regular)</i></font>`,
              rawValue: this.regularFlight(flight, meta).totalProfit,
              value: `<font class="${
                this.regularFlight(flight, meta).total -
                  this.regularFlight(flight, meta).totalNet >
                0
                  ? "cgreen"
                  : "red"
              }">${this.formatPricePtBr(
                this.regularFlight(flight, meta).total -
                  this.regularFlight(flight, meta).totalNet
              )}`,
            });

            if (this.regularFlight(flight, meta).totalTaxes > 0) {
              listTaxes.push({
                name: `Taxa de embarque (regular) <font class="cprimary" style="font-size: 8px"><i>Aéreo ${flight.id}</i></font>`,
                value: this.formatAnyPricePtBr(
                  this.regularFlight(flight, meta).totalTaxes
                ),
              });
            }

            productsNames.push({
              product: "flight",
              productId: flight.id,
              productAlias: `Aéreo`,
              name: this.flightProductName(flight, meta),
            });
          } else {
            total += this.milesFlight(flight, meta).total;
            totalNet += this.contract.isSale
              ? this.milesFlight(flight, meta).totalTaxes +
                this.milesFlight(flight, meta).totalNet
              : this.milesFlight(flight, meta).totalNet;
            totalProfit += this.milesFlight(flight, meta).totalProfit;
            totalTaxes += this.contract.isSale
              ? 0
              : this.milesFlight(flight, meta).totalTaxes;

            sale.netTaxes += this.contract.isSale
              ? this.milesFlight(flight, meta).totalTaxes
              : 0;

            toReceiveList.push({
              id: `flight-${flight.id}`,
              name: `Aéreo ${
                meta[`flight_${flight.id}_type`]
              } <font class="cprimary f8"><i>aéreo ${flight.id}</i></font>`,
              rawValue: this.milesFlight(flight, meta).total,
              value: `<font class="">${this.formatAnyPricePtBr(
                this.milesFlight(flight, meta).total
              )}`,
            });

            netList.push({
              id: `flight-${flight.id}`,
              name: `Neto <font class="cprimary f8"><i>aéreo ${flight.id} (milhas)</i></font>`,
              rawValue: this.contract.isSale
                ? this.milesFlight(flight, meta).totalTaxes +
                  this.milesFlight(flight, meta).totalNet
                : this.milesFlight(flight, meta).totalNet,
              value: `<font class="">${this.formatAnyPricePtBr(
                this.milesFlight(flight, meta).totalNet
              )}`,
            });

            profitList.push({
              id: `flight-${flight.id}`,
              name: `Lucratividade <font class="cprimary f8"><i>aéreo ${flight.id} (milhas)</i></font>`,
              rawValue: this.milesFlight(flight, meta).totalProfit,
              value: `<font class="${
                this.milesFlight(flight, meta).totalProfit > 0
                  ? "cgreen"
                  : "red"
              }">${this.formatAnyPricePtBr(
                this.milesFlight(flight, meta).totalProfit
              )}`,
            });

            if (
              this.milesFlight(flight, meta).totalTaxes > 0 &&
              !this.contract.isSale
            ) {
              listTaxes.push({
                name: `Taxa de embarque (milhas) <font class="cprimary" style="font-size: 8px"><i>Aéreo ${flight.id}</i></font>`,
                value: this.formatAnyPricePtBr(
                  this.milesFlight(flight, meta).totalTaxes
                ),
              });
            }

            productsNames.push({
              product: "flight",
              productId: flight.id,
              productAlias: "Aéreo",
              name: `Aéreo ${flight.id} ${meta[`flight_${flight.id}_type`]}`,
            });
          }

          // TAXES
          if (
            meta[`contract_finances_flight_${flight.id}_taxes_rows`] !=
            undefined
          ) {
            JSON.parse(
              meta[`contract_finances_flight_${flight.id}_taxes_rows`]
            ).forEach((tax) => {
              if (
                meta[
                  `contract_finances_flight_${flight.id}_tax_${tax.id}_type`
                ] &&
                meta[
                  `contract_finances_flight_${flight.id}_tax_${tax.id}_value`
                ]
              ) {
                totalTaxes += parse(
                  meta[
                    `contract_finances_flight_${flight.id}_tax_${tax.id}_value`
                  ]
                );

                totalExtraFees += parse(
                  meta[
                    `contract_finances_flight_${flight.id}_tax_${tax.id}_value`
                  ]
                );

                if (
                  meta[
                    `contract_finances_flight_${flight.id}_tax_${tax.id}_add_in_total`
                  ] == true ||
                  meta[
                    `contract_finances_flight_${flight.id}_tax_${tax.id}_add_in_total`
                  ] == 1
                ) {
                  total += parse(
                    meta[
                      `contract_finances_flight_${flight.id}_tax_${tax.id}_value`
                    ]
                  );

                  toReceiveList.push({
                    id: `flight-${flight.id}-tax-${tax.id}`,
                    name: `${
                      meta[
                        `contract_finances_flight_${flight.id}_tax_${tax.id}_type`
                      ]
                    } <font class="cprimary" style="font-size: 8px"><i>Aéreo</i></font>`,
                    rawValue: parse(
                      meta[
                        `contract_finances_flight_${flight.id}_tax_${tax.id}_value`
                      ]
                    ),
                    value: `<font class="">${this.formatAnyPricePtBr(
                      parse(
                        meta[
                          `contract_finances_flight_${flight.id}_tax_${tax.id}_value`
                        ]
                      )
                    )}`,
                  });
                }

                extraFeesList.push({
                  name: `${
                    meta[
                      `contract_finances_flight_${flight.id}_tax_${tax.id}_type`
                    ]
                  } <font class="cprimary" style="font-size: 8px"><i>Aéreo</i></font>`,
                  value: this.formatAnyPricePtBr(
                    meta[
                      `contract_finances_flight_${flight.id}_tax_${tax.id}_value`
                    ]
                  ),
                });
              }
            });
          }

          // INCENTIVES
          if (
            meta[`contract_finances_flight_${flight.id}_incentives_rows`] !=
            undefined
          ) {
            JSON.parse(
              meta[`contract_finances_flight_${flight.id}_incentives_rows`]
            ).forEach((incentive) => {
              if (
                meta[
                  `contract_finances_flight_${flight.id}_incentive_${incentive.id}_type`
                ] == "%"
              ) {
                totalIncentives += parseFloat(
                  meta[
                    `contract_finances_flight_${flight.id}_incentive_${incentive.id}_percentage_value`
                  ]
                );

                if (
                  meta[
                    `contract_finances_flight_${flight.id}_incentive_${incentive.id}_add_in_total`
                  ] == true ||
                  meta[
                    `contract_finances_flight_${flight.id}_incentive_${incentive.id}_add_in_total`
                  ] == 1
                ) {
                  total += parseFloat(
                    meta[
                      `contract_finances_flight_${flight.id}_incentive_${incentive.id}_percentage_value`
                    ]
                  );

                  toReceiveList.push({
                    id: `flight-${flight.id}-incentive-${incentive.id}`,
                    name: `Incentivo ${
                      incentive.id
                    } <font class="cprimary f8"><i>aéreo ${flight.id} (${
                      meta[`flight_${flight.id}_type`]
                    })</i></font>`,
                    rawValue:
                      meta[
                        `contract_finances_flight_${flight.id}_incentive_${incentive.id}_percentage_value`
                      ],
                    value: `<font class="">${this.formatPricePtBr(
                      meta[
                        `contract_finances_flight_${flight.id}_incentive_${incentive.id}_percentage_value`
                      ]
                    )}</font>`,
                  });
                }

                profitList.push({
                  id: `flight-${flight.id}-incentive-${incentive.id}`,
                  name: `Incentivo ${
                    incentive.id
                  } <font class="cprimary f8"><i>aéreo ${flight.id} (${
                    meta[`flight_${flight.id}_type`]
                  })</i></font>`,
                  rawValue:
                    meta[
                      `contract_finances_flight_${flight.id}_incentive_${incentive.id}_percentage_value`
                    ],
                  value: `<font class="cgreen">${this.formatPricePtBr(
                    meta[
                      `contract_finances_flight_${flight.id}_incentive_${incentive.id}_percentage_value`
                    ]
                  )}</font>`,
                });
              } else {
                totalIncentives += parse(
                  meta[
                    `contract_finances_flight_${flight.id}_incentive_${incentive.id}_value`
                  ]
                );

                if (
                  meta[
                    `contract_finances_flight_${flight.id}_incentive_${incentive.id}_add_in_total`
                  ] == true ||
                  meta[
                    `contract_finances_flight_${flight.id}_incentive_${incentive.id}_add_in_total`
                  ] == 1
                ) {
                  total += parse(
                    meta[
                      `contract_finances_flight_${flight.id}_incentive_${incentive.id}_value`
                    ]
                  );

                  toReceiveList.push({
                    id: `flight-${flight.id}-incentive-${incentive.id}`,
                    name: `Incentivo ${
                      incentive.id
                    } <font class="cprimary f8"><i>aéreo ${flight.id} (${
                      meta[`flight_${flight.id}_type`]
                    })</i></font>`,
                    rawValue:
                      meta[
                        `contract_finances_flight_${flight.id}_incentive_${incentive.id}_value`
                      ],
                    value: `<font class="">${this.formatPricePtBr(
                      meta[
                        `contract_finances_flight_${flight.id}_incentive_${incentive.id}_value`
                      ]
                    )}</font>`,
                  });
                }

                profitList.push({
                  id: `flight-${flight.id}-incentive-${incentive.id}`,
                  name: `Incentivo ${
                    incentive.id
                  } <font class="cprimary f8"><i>aéreo ${flight.id} (${
                    meta[`flight_${flight.id}_type`]
                  })</i></font>`,
                  rawValue:
                    meta[
                      `contract_finances_flight_${flight.id}_incentive_${incentive.id}_value`
                    ],
                  value: `<font class="cgreen">${this.formatPricePtBr(
                    meta[
                      `contract_finances_flight_${flight.id}_incentive_${incentive.id}_value`
                    ]
                  )}</font>`,
                });
              }
            });
          }

          totalIncentives += this.saleTaxesAndIncentives(
            meta,
            "flight",
            flight.id,
            "aéreo"
          ).totalProfit;
          profitList = [
            ...profitList,
            ...this.saleTaxesAndIncentives(meta, "flight", flight.id, "aéreo")
              .profitList,
          ];
        });
      }

      return {
        total,
        totalNet,
        totalTaxes,
        totalProfit,
        totalIncentives,
        totalExtraFees,
        toReceiveList,
        listTaxes,
        extraFeesList,
        netList,
        profitList,
        sale,
        productsNames,
      };
    },
    servicePricingData(meta) {
      let total = 0,
        totalNet = 0,
        totalProfit = 0,
        totalIncentives = 0,
        totalExtraFees = 0,
        toReceiveList = [],
        extraFeesList = [],
        netList = [],
        profitList = [],
        productsNames = [],
        serviceRows = meta.service_rows ? JSON.parse(meta.service_rows) : [],
        contractedServices = meta.contracted_services
          ? JSON.parse(meta.contracted_services)
          : [],
        packageType = meta.package_type;

      if (
        (serviceRows.length > 0 &&
          contractedServices.includes("service") &&
          packageType == "Serviços") ||
        (serviceRows.length > 0 &&
          contractedServices.includes("service") &&
          packageType == "Reserva Online")
      ) {
        serviceRows.forEach((service) => {
          productsNames.push({
            product: "service",
            productId: service.id,
            productAlias: "Serviço",
            name: meta[`service_${service.id}_type`],
          });

          // TOTAL
          total += parse(meta[`service_${service.id}_total_price`]);

          // NET
          totalNet += parse(
            meta[`contract_finances_service_${service.id}_net_value`]
          );

          toReceiveList.push({
            id: `service-${service.id}`,
            name: `${
              meta[`service_${service.id}_type`] || "Serviço"
            } <font class="cprimary f8"><i>serviço ${service.id}</i></font>`,
            rawValue: parse(meta[`service_${service.id}_total_price`]),
            value: `<font <font class="${
              totalNet > 0 ? "" : ""
            }">${this.formatAnyPricePtBr(
              parse(meta[`service_${service.id}_total_price`])
            )}</font>`,
          });

          netList.push({
            id: `service-${service.id}`,
            name: `Neto <font class="cprimary f8"><i>serviço ${service.id}</i></font>`,
            rawValue: parse(
              meta[`contract_finances_service_${service.id}_net_value`]
            ),
            value: `<font <font class="${
              totalNet > 0 ? "" : ""
            }">${this.formatAnyPricePtBr(
              parse(meta[`contract_finances_service_${service.id}_net_value`])
            )}</font>`,
          });

          profitList.push({
            id: `service-${service.id}`,
            name: `Lucratividade <font class="cprimary f8"><i>serviço ${service.id}</i></font>`,
            rawValue: totalProfit,
            value: `<font <font class="${
              parse(meta[`service_${service.id}_total_price`]) -
                parse(
                  meta[`contract_finances_service_${service.id}_net_value`]
                ) >
              0
                ? "cgreen"
                : "red"
            }">${this.formatAnyPricePtBr(
              parse(meta[`service_${service.id}_total_price`]) -
                parse(meta[`contract_finances_service_${service.id}_net_value`])
            )}</font>`,
          });

          // TAXES
          if (
            meta[`contract_finances_service_${service.id}_taxes_rows`] !=
            undefined
          ) {
            JSON.parse(
              meta[`contract_finances_service_${service.id}_taxes_rows`]
            ).forEach((tax) => {
              if (
                meta[
                  `contract_finances_service_${service.id}_tax_${tax.id}_type`
                ] &&
                meta[
                  `contract_finances_service_${service.id}_tax_${tax.id}_value`
                ]
              ) {
                totalExtraFees += parse(
                  meta[
                    `contract_finances_service_${service.id}_tax_${tax.id}_value`
                  ]
                );

                if (
                  meta[
                    `contract_finances_service_${service.id}_tax_${tax.id}_add_in_total`
                  ] == true ||
                  meta[
                    `contract_finances_service_${service.id}_tax_${tax.id}_add_in_total`
                  ] == 1
                ) {
                  total += parse(
                    meta[
                      `contract_finances_service_${service.id}_tax_${tax.id}_value`
                    ]
                  );

                  toReceiveList.push({
                    name: `${
                      meta[
                        `contract_finances_service_${service.id}_tax_${tax.id}_type`
                      ]
                    } <font class="cprimary" style="font-size: 8px"><i>Serviço ${
                      service.id
                    }</i></font>`,
                    value: this.formatAnyPricePtBr(
                      meta[
                        `contract_finances_service_${service.id}_tax_${tax.id}_value`
                      ]
                    ),
                  });
                }

                extraFeesList.push({
                  name: `${
                    meta[
                      `contract_finances_service_${service.id}_tax_${tax.id}_type`
                    ]
                  } <font class="cprimary" style="font-size: 8px"><i>Serviço ${
                    service.id
                  }</i></font>`,
                  value: this.formatAnyPricePtBr(
                    meta[
                      `contract_finances_service_${service.id}_tax_${tax.id}_value`
                    ]
                  ),
                });
              }
            });
          }

          // INCENTIVES
          if (
            meta[`contract_finances_service_${service.id}_incentives_rows`] !=
            undefined
          ) {
            JSON.parse(
              meta[`contract_finances_service_${service.id}_incentives_rows`]
            ).forEach((incentive) => {
              if (
                meta[
                  `contract_finances_service_${service.id}_incentive_${incentive.id}_type`
                ] == "%"
              ) {
                if (
                  meta[
                    `contract_finances_service_${service.id}_incentive_${incentive.id}_add_in_total`
                  ] == true ||
                  meta[
                    `contract_finances_service_${service.id}_incentive_${incentive.id}_add_in_total`
                  ] == 1
                ) {
                  total += parseFloat(
                    meta[
                      `contract_finances_service_${service.id}_incentive_${incentive.id}_percentage_value`
                    ]
                  );

                  toReceiveList.push({
                    id: `service-${service.id}-incentive-${incentive.id}`,
                    name: `Incentivo ${incentive.id} <font class="cprimary f8"><i>serviço ${service.id}</i></font>`,
                    rawValue:
                      meta[
                        `contract_finances_service_${service.id}_incentive_${incentive.id}_percentage_value`
                      ],
                    value: `<font class="">${this.formatPricePtBr(
                      meta[
                        `contract_finances_service_${service.id}_incentive_${incentive.id}_percentage_value`
                      ]
                    )}</font>`,
                  });
                }

                profitList.push({
                  id: `service-${service.id}-incentive-${incentive.id}`,
                  name: `Incentivo ${incentive.id} <font class="cprimary f8"><i>serviço ${service.id}</i></font>`,
                  rawValue:
                    meta[
                      `contract_finances_service_${service.id}_incentive_${incentive.id}_percentage_value`
                    ],
                  value: `<font class="cgreen">${this.formatAnyPricePtBr(
                    meta[
                      `contract_finances_service_${service.id}_incentive_${incentive.id}_percentage_value`
                    ]
                  )}</font>`,
                });
              } else {
                // totalIncentives += parse(
                //   meta[
                //     `contract_finances_service_${service.id}_incentive_${incentive.id}_value`
                //   ]
                // );

                if (
                  meta[
                    `contract_finances_service_${service.id}_incentive_${incentive.id}_add_in_total`
                  ] == true ||
                  meta[
                    `contract_finances_service_${service.id}_incentive_${incentive.id}_add_in_total`
                  ] == 1
                ) {
                  total += parse(
                    meta[
                      `contract_finances_service_${service.id}_incentive_${incentive.id}_value`
                    ]
                  );

                  toReceiveList.push({
                    id: `service-${service.id}-incentive-${incentive.id}`,
                    name: `Incentivo ${incentive.id} <font class="cprimary f8"><i>serviço ${service.id}</i></font>`,
                    rawValue:
                      meta[
                        `contract_finances_service_${service.id}_incentive_${incentive.id}_value`
                      ],
                    value: `<font class="">${this.formatAnyPricePtBr(
                      meta[
                        `contract_finances_service_${service.id}_incentive_${incentive.id}_value`
                      ]
                    )}</font>`,
                  });
                }

                profitList.push({
                  id: `service-${service.id}-incentive-${incentive.id}`,
                  name: `Incentivo ${incentive.id} <font class="cprimary f8"><i>serviço ${service.id}</i></font>`,
                  rawValue:
                    meta[
                      `contract_finances_service_${service.id}_incentive_${incentive.id}_value`
                    ],
                  value: `<font class="cgreen">${this.formatAnyPricePtBr(
                    meta[
                      `contract_finances_service_${service.id}_incentive_${incentive.id}_value`
                    ]
                  )}</font>`,
                });
              }
            });
          }

          totalIncentives += this.saleTaxesAndIncentives(
            meta,
            "service",
            service.id,
            "serviço"
          ).totalProfit;
          profitList = [
            ...profitList,
            ...this.saleTaxesAndIncentives(
              meta,
              "service",
              service.id,
              "serviço"
            ).profitList,
          ];
        });
      }

      totalProfit += total - totalNet;

      return {
        total,
        totalNet,
        totalProfit,
        totalIncentives,
        totalExtraFees,
        toReceiveList,
        extraFeesList,
        netList,
        profitList,
        productsNames,
      };
    },
    packagePricingData(meta) {
      let total = 0,
        totalNet = 0,
        totalProfit = 0,
        totalIncentives = 0,
        totalExtraFees = 0,
        toReceiveList = [],
        extraFeesList = [],
        netList = [],
        profitList = [];

      if (meta["package_type"] == "Pacote Completo Terceiros") {
        //TOTAL
        total += parse(meta[`package_total_price`]);

        // NET
        totalNet += parse(meta[`contract_finances_package_net_value`]);

        toReceiveList.push({
          id: `package`,
          name: `Pacote Completo`,
          rawValue: parse(meta[`package_total_price`]),
          value: `<font <font class="${
            totalNet > 0 ? "" : ""
          }">${this.formatAnyPricePtBr(
            parse(meta[`package_total_price`])
          )}</font>`,
        });

        // TAXES
        if (meta[`contract_finances_package_taxes_rows`] != undefined) {
          JSON.parse(meta[`contract_finances_package_taxes_rows`]).forEach(
            (tax) => {
              if (
                meta[`contract_finances_package_tax_${tax.id}_type`] &&
                meta[`contract_finances_package_tax_${tax.id}_value`]
              ) {
                if (
                  meta[
                    `contract_finances_package_tax_${tax.id}_add_in_total`
                  ] == true ||
                  meta[
                    `contract_finances_package_tax_${tax.id}_add_in_total`
                  ] == 1
                ) {
                  total += parse(
                    meta[`contract_finances_package_tax_${tax.id}_value`]
                  );

                  toReceiveList.push({
                    id: `package-tax-${tax.id}`,
                    name: `${
                      meta[`contract_finances_package_tax_${tax.id}_type`]
                    } <font class="cprimary" style="font-size: 8px"><i>Pacote</i></font>`,

                    rawValue:
                      meta[`contract_finances_package_tax_${tax.id}_value`],
                    value: this.formatAnyPricePtBr(
                      meta[`contract_finances_package_tax_${tax.id}_value`]
                    ),
                  });
                }

                totalExtraFees += parse(
                  meta[`contract_finances_package_tax_${tax.id}_value`]
                );

                extraFeesList.push({
                  name: `${
                    meta[`contract_finances_package_tax_${tax.id}_type`]
                  } <font class="cprimary" style="font-size: 8px"><i>Pacote</i></font>`,
                  value: this.formatAnyPricePtBr(
                    meta[`contract_finances_package_tax_${tax.id}_value`]
                  ),
                });
              }
            }
          );
        }

        // INCENTIVES
        if (meta[`contract_finances_package_incentives_rows`] != undefined) {
          JSON.parse(meta[`contract_finances_package_incentives_rows`]).forEach(
            (incentive) => {
              if (
                meta[
                  `contract_finances_package_incentive_${incentive.id}_type`
                ] == "%"
              ) {
                totalIncentives += parseFloat(
                  meta[
                    `contract_finances_package_incentive_${incentive.id}_percentage_value`
                  ]
                );

                if (
                  meta[
                    `contract_finances_package_incentive_${incentive.id}_add_in_total`
                  ] == true ||
                  meta[
                    `contract_finances_package_incentive_${incentive.id}_add_in_total`
                  ] == 1
                ) {
                  total += parseFloat(
                    meta[
                      `contract_finances_package_incentive_${incentive.id}_percentage_value`
                    ]
                  );

                  toReceiveList.push({
                    id: `package-incentive-${incentive.id}`,
                    name: `${
                      meta[
                        `contract_finances_package_incentive_${incentive.id}_type`
                      ]
                    } <font class="cprimary" style="font-size: 8px"><i>Pacote</i></font>`,

                    rawValue: parseFloat(
                      meta[
                        `contract_finances_package_incentive_${incentive.id}_percentage_value`
                      ]
                    ),
                    value: this.formatAnyPricePtBr(
                      meta[
                        `contract_finances_package_incentive_${incentive.id}_percentage_value`
                      ]
                    ),
                  });
                }

                profitList.push({
                  id: `package-incentive-${incentive.id}`,
                  name: `Incentivo ${incentive.id} <font class="cprimar f8" ><i>Pacote</i></font>`,
                  rawValue:
                    meta[
                      `contract_finances_package_incentive_${incentive.id}_percentage_value`
                    ],
                  value: `<font class="cgreen">${this.formatAnyPricePtBr(
                    meta[
                      `contract_finances_package_incentive_${incentive.id}_percentage_value`
                    ]
                  )}</font>`,
                });
              } else {
                totalIncentives += parse(
                  meta[
                    `contract_finances_package_incentive_${incentive.id}_value`
                  ]
                );

                if (
                  meta[
                    `contract_finances_package_incentive_${incentive.id}_add_in_total`
                  ] == true ||
                  meta[
                    `contract_finances_package_incentive_${incentive.id}_add_in_total`
                  ] == 1
                ) {
                  total += parse(
                    meta[
                      `contract_finances_package_incentive_${incentive.id}_value`
                    ]
                  );

                  toReceiveList.push({
                    id: `package-incentive-${incentive.id}`,
                    name: `Incentivo ${incentive.id} <font class="cprimary f8" ><i>Pacote</i></font>`,
                    rawValue: parseFloat(
                      meta[
                        `contract_finances_package_incentive_${incentive.id}_value`
                      ]
                    ),
                    value: this.formatAnyPricePtBr(
                      meta[
                        `contract_finances_package_incentive_${incentive.id}_value`
                      ]
                    ),
                  });
                }

                profitList.push({
                  id: `package-${incentive.id}`,
                  name: `Incentivo ${incentive.id} <font class="cprimary f8"><i>pacote</i></font>`,
                  rawValue:
                    meta[
                      `contract_finances_package_incentive_${incentive.id}_value`
                    ],
                  value: `<font class="cgreen">${this.formatAnyPricePtBr(
                    meta[
                      `contract_finances_package_incentive_${incentive.id}_value`
                    ]
                  )}</font>`,
                });
              }
            }
          );
        }

        totalProfit += total - totalNet;

        if (totalNet > 0) {
          netList.push({
            id: `package`,
            name: `Neto <font class="cprimary f8"><i>pacote</i></font>`,
            rawValue: totalNet,
            value: `<font class="">${this.formatAnyPricePtBr(totalNet)}</font>`,
          });
        }

        if (totalProfit > 0) {
          profitList.push({
            id: `package`,
            name: `Lucratividade <font class="cprimary f8"><i>pacote</i></font>`,
            rawValue: totalProfit,
            value: `<font class="cgreen">${this.formatAnyPricePtBr(
              totalProfit
            )}</font>`,
          });
        }

        totalIncentives += this.saleTaxesAndIncentives(
          meta,
          "package",
          "",
          "pacote"
        ).totalProfit;
        profitList = [
          ...profitList,
          ...this.saleTaxesAndIncentives(meta, "package", "", "pacote")
            .profitList,
        ];
      }

      return {
        total,
        totalNet,
        totalProfit,
        totalExtraFees,
        totalIncentives,
        toReceiveList,
        extraFeesList,
        netList,
        profitList,
      };
    },
    productProfit(product, meta) {
      let theValue = 0,
        theProduct = [];

      if (product) {
        if (product.includes("hotel")) {
          theProduct = this.hotelPricingData(meta).profitList.filter((item) => {
            return item.product == product;
          });
        }

        if (product.includes("flight")) {
          theProduct = this.flightPricingData(meta).profitList.filter(
            (item) => {
              return item.product == product;
            }
          );
        }

        if (product.includes("service")) {
          theProduct = this.servicePricingData(meta).profitList.filter(
            (item) => {
              return item.product == product;
            }
          );
        }

        if (product.includes("package")) {
          theProduct = this.packagePricingData(meta).profitList.filter(
            (item) => {
              return item.product == product;
            }
          );
        }

        theValue = theProduct.length > 0 ? theProduct[0].rawValue : 0;
      }

      return theValue;
    },
    productNet(product, meta) {
      let theValue = 0,
        theProduct = [];

      if (product && this.contract.isSale) {
        if (product.includes("hotel")) {
          theProduct = this.hotelPricingData(meta).netList.filter((item) => {
            return item.product == product;
          });
        }

        if (product.includes("flight")) {
          theProduct = this.flightPricingData(meta).netList.filter((item) => {
            return item.product == product;
          });
        }

        if (product.includes("service")) {
          theProduct = this.servicePricingData(meta).netList.filter((item) => {
            return item.product == product;
          });
        }

        if (product.includes("package")) {
          theProduct = this.packagePricingData(meta).netList.filter((item) => {
            return item.product == product;
          });
        }

        theValue = theProduct.length > 0 ? theProduct[0].rawValue : 0;
      }

      return theValue;
    },
    directPaymentWithSupplier(meta) {
      let total = 0,
        paymentMethods = meta.payment_methods
          ? JSON.parse(meta.payment_methods)
          : [],
        creditCardRows =
          meta.credit_card_rows != undefined
            ? JSON.parse(meta.credit_card_rows)
            : [];

      if (paymentMethods.includes("credit-card") && this.contract.isSale) {
        creditCardRows.forEach((cCard) => {
          if (
            meta[`payment_methods_credit_card_machine_id_${cCard.id}`] == 14
          ) {
            if (
              meta[
                `payment_methods_credit_card_${cCard.id}_value_to_receive_rows`
              ] != undefined
            ) {
              JSON.parse(
                meta[
                  `payment_methods_credit_card_${cCard.id}_value_to_receive_rows`
                ]
              ).forEach((valueToReceive) => {
                total += this.productNet(
                  meta[
                    `payment_methods_credit_card_${cCard.id}_value_to_receive_${valueToReceive.id}_profit_value`
                  ],
                  meta
                );
              });
            }
          }
        });
      }

      return total;
    },
    financialCostData(meta) {
      let total = 0,
        creditCard = {
          total: 0,
          totalToReceive: 0,
        },
        paymentLink = {
          total: 0,
        },
        billingTicket = {
          total: 0,
        },
        paymentCard = {
          total: 0,
        },
        marketing = {
          livelo: {
            total: 0,
          },
        },
        list = [],
        profitList = [],
        paymentMethods = meta.payment_methods
          ? JSON.parse(meta.payment_methods)
          : [],
        marketingTypes = meta.marketing_types
          ? JSON.parse(meta.marketing_types)
          : [],
        creditCardRows =
          meta.credit_card_rows != undefined
            ? JSON.parse(meta.credit_card_rows)
            : [],
        paymentCardRows =
          meta.payment_card_rows != undefined
            ? JSON.parse(meta.payment_card_rows)
            : [];

      if (marketingTypes.length > 0) {
        if (marketingTypes.includes("Livelo")) {
          if (
            meta.livelo_subtract_fees_in_profit == 1 ||
            meta.livelo_subtract_fees_in_profit == true
          ) {
            marketing.livelo.total = parseFloat(meta.livelo_points_total_fee);

            profitList.push({
              id: `livelo`,
              name: `Livelo <font class="cprimary f8"><i>marketing</i></font>`,
              rawValue: parseFloat(meta.livelo_points_total_fee),
              value: `<font class="red">- ${this.formatAnyPricePtBr(
                parseFloat(meta.livelo_points_total_fee)
              )}</font>`,
            });
          }
        }
      }

      if (paymentMethods.length > 0) {
        if (paymentMethods.includes("billing-ticket")) {
          billingTicket.total += parse(
            meta[`payment_methods_billing_ticket_tax_value`]
          );

          total += parse(meta[`payment_methods_billing_ticket_tax_value`]);

          if (parse(meta[`payment_methods_billing_ticket_tax_value`]) > 0) {
            list.push({
              id: "billing-ticket",
              name: `Taxa do Boleto <font class="cprimary f8"><i>Boleto</i></font>`,
              value: this.formatAnyPricePtBr(
                parse(meta[`payment_methods_billing_ticket_tax_value`])
              ),
            });
          }
        }

        if (paymentMethods.includes("payment-card")) {
          if (paymentCardRows.length > 0) {
            paymentCardRows.forEach((pCard) => {
              paymentCard.total += parse(
                meta[
                  `payment_methods_payment_card_contract_profit_discount_value_${pCard.id}`
                ]
              );
            });
          } else {
            paymentCard.total +=
              meta[
                `payment_methods_payment_card_contract_profit_discount_value`
              ] != undefined
                ? parse(
                    meta[
                      `payment_methods_payment_card_contract_profit_discount_value`
                    ]
                  )
                : parse(
                    meta[`payment_methods_payment_card_total_profit_value`]
                  );
          }

          if (paymentCard.total > 0) {
            profitList.push({
              id: `payment-card`,
              name: `Lucratividade <font class="cprimary f8"><i>carta de crédito</i></font>`,
              rawValue: paymentCard.total,
              value: `<font class="red">- ${this.formatAnyPricePtBr(
                paymentCard.total
              )}</font>`,
            });
          }
        }

        if (paymentMethods.includes("credit-card")) {
          creditCardRows.forEach((cCard) => {
            if (
              meta[`payment_methods_credit_card_machine_id_${cCard.id}`] != 14
            ) {
              let advanceFee = meta[
                  `payment_methods_credit_card_advance_fee_value_${cCard.id}`
                ]
                  ? parse(
                      meta[
                        `payment_methods_credit_card_advance_fee_value_${cCard.id}`
                      ]
                    )
                  : 0,
                discountAdvFeeInProfit =
                  meta[
                    `payment_methods_credit_card_discount_adm_tax_in_profit_${cCard.id}`
                  ] != undefined
                    ? meta[
                        `payment_methods_credit_card_discount_adm_tax_in_profit_${cCard.id}`
                      ] == 1
                      ? true
                      : false
                    : false;

              if (discountAdvFeeInProfit) {
                advanceFee = 0;
              }

              creditCard.total +=
                advanceFee +
                parse(
                  meta[`payment_methods_credit_card_tax_value_${cCard.id}`]
                );

              total +=
                advanceFee +
                parse(
                  meta[`payment_methods_credit_card_tax_value_${cCard.id}`]
                );

              list.push({
                id: `credit-card-${cCard.id}`,
                name: `Taxa Administrativa <font class="cprimary" style="font-size: 8px"><i>C. Crédito ${cCard.id}</i></font>`,
                value: this.formatAnyPricePtBr(
                  advanceFee +
                    parse(
                      meta[`payment_methods_credit_card_tax_value_${cCard.id}`]
                    )
                ),
              });
            } else {
              if (
                meta[
                  `payment_methods_credit_card_${cCard.id}_value_to_receive_rows`
                ] != undefined
              ) {
                JSON.parse(
                  meta[
                    `payment_methods_credit_card_${cCard.id}_value_to_receive_rows`
                  ]
                ).forEach((valueToReceive) => {
                  creditCard.totalToReceive += this.productProfit(
                    meta[
                      `payment_methods_credit_card_${cCard.id}_value_to_receive_${valueToReceive.id}_profit_value`
                    ],
                    meta
                  );
                });
              }
            }
          });
        }

        if (paymentMethods.includes("payment-link")) {
          paymentLink.total +=
            parse(meta[`payment_methods_payment_link_tax_value`]) +
            parse(meta[`payment_methods_payment_link_advance_fee_value`]);

          total +=
            parse(meta[`payment_methods_payment_link_tax_value`]) +
            parse(meta[`payment_methods_payment_link_advance_fee_value`]);

          list.push({
            id: "payment-link",
            name: `Taxa Administrativa <font class="cprimary" style="font-size: 8px"><i>Link Pagamento</i></font>`,
            value: this.formatAnyPricePtBr(
              parse(meta[`payment_methods_payment_link_tax_value`]) +
                parse(meta[`payment_methods_payment_link_advance_fee_value`])
            ),
          });
        }
      }

      return {
        total,
        creditCard,
        billingTicket,
        paymentCard,
        paymentLink,
        marketing,
        list,
        profitList,
      };
    },
  },
};
