<template>
  <div class="user-details">
    <h2 class="consultant">Seu especialista em viagens:</h2>
    <aRow>
      <aCol class="avatar" :span="4">
        <aAvatar :size="50" icon="user" :src="contract.user.avatar" />
      </aCol>

      <aCol :span="13">
        <div class="name">
          {{ contract.user.first_name }}
          {{ contract.user.last_name }}
        </div>
        <div class="email">{{ contract.user.email }}</div>
        <div class="email">
          {{ contract.user.mobile_phone }}
        </div>
      </aCol>

      <aCol class="wpp a-right" :span="7">
        <a
          v-if="contract.user.mobile_phone != undefined"
          @click="openWhatsapp(contract.user.mobile_phone)"
          style="color: #37bc9b"
          ><i
            class="fab fa-whatsapp mr-10"
            style="font-size: 30px; top: 10px; position: relative"
          ></i
        ></a>
      </aCol>
    </aRow>
  </div>
</template>

<script>
import "@fortawesome/fontawesome-free/js/all.js";

export default {
  props: {
    contract: Object,
  },
  methods: {
    openWhatsapp(number) {
      const baseUrl = "https://api.whatsapp.com/send?phone=55";
      let theNumber = number
        .replace(" ", "")
        .replace(" ", "")
        .replace("-", "")
        .replace("(", "")
        .replace(")", "");
      window.open(
        `${baseUrl}${theNumber}&text=Olá ${this.contract.user.first_name}`,
        "_blank"
      );
    },
  },
};
</script>

<style lang="sass" scoped>
.user-details
  font-weight: 500
  font-size: 12px
  h2
    margin-bottom: 2px
    color: #aab2bd !important
  .name
    padding-top: 5px
    font-weight: 600
    color: #6a50a7
    font-size: 13px
    line-height: 1
  .email
    font-size: 11px
    color: #656d78
    margin-top: 5px
    line-height: 1
</style>
