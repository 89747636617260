<template>
  <div>
    <aRow
      class="section-title"
      :class="!openBoxDetails ? 'closed' : ''"
      type="flex"
      justify="space-between"
    >
      <aCol> <h2>Pagamentos</h2></aCol>
      <aCol>
        <a-icon class="arrow" type="down" @click="onClickOpenBoxDetails" />
      </aCol>
    </aRow>
    <div v-if="openBoxDetails" class="payments line border-0">
      <aRow
        v-if="paymentMethods.includes('bank-transfer')"
        type="flex"
        justify="space-between"
      >
        <aCol>Transferência bancária</aCol>
        <aCol>
          R$
          {{
            formatPrice(contract.meta.payment_methods_bank_transfer_total_value)
          }}</aCol
        >
      </aRow>

      <aRow
        v-if="paymentMethods.includes('payment-card')"
        type="flex"
        justify="space-between"
      >
        <aCol>Carta de Pagamento</aCol>
        <aCol>
          R$
          {{
            formatPrice(contract.meta.payment_methods_payment_card_total_value)
          }}</aCol
        >
      </aRow>

      <div v-if="paymentMethods.includes('payment-link')">
        <aRow
          v-for="(pLink, i) in JSON.parse(contract.meta.payment_link_rows)"
          :key="i"
          type="flex"
          justify="space-between"
        >
          <aCol
            >Link de Pagamento #{{ pLink.id }}
            <span
              class="to-pay"
              v-if="
                pLink.id === contract.payment_link_id &&
                  $route.params.status !== 'success'
              "
            >
              <span
                class="cgreen"
                v-if="
                  ![undefined, ''].includes(
                    contract.meta[`payment_link_3ds_tid_${pLink.id}`]
                  )
                "
                >(Pago)</span
              >
              <span v-else>(Pendente)</span></span
            >
          </aCol>
          <aCol>
            {{
              formatPricePtBr(
                contract.meta[
                  `payment_methods_payment_link_total_value_${pLink.id}`
                ].replace(",", ".")
              )
            }}</aCol
          >
        </aRow>
      </div>

      <aRow
        v-if="paymentMethods.includes('credit-card')"
        type="flex"
        justify="space-between"
      >
        <aCol>Cartão de Crédito</aCol>
        <aCol>R$ {{ creditCard() }}</aCol>
      </aRow>

      <aRow
        v-if="paymentMethods.includes('billing-ticket')"
        type="flex"
        justify="space-between"
      >
        <aCol>Boleto Bancário</aCol>
        <aCol>
          R$
          {{
            formatPrice(
              contract.meta.payment_methods_billing_ticket_total_value
            )
          }}</aCol
        >
      </aRow>

      <aRow
        v-if="paymentMethods.includes('custom-payment')"
        type="flex"
        justify="space-between"
      >
        <aCol>{{ contract.meta[`payment_methods_custom_payment_name`] }}</aCol>
        <aCol>
          R$
          {{
            formatPrice(
              contract.meta.payment_methods_custom_payment_total_value
            )
          }}</aCol
        >
      </aRow>
    </div>
  </div>
</template>

<script>
import { parse } from "vue-currency-input";
import formatThings from "../../general/mixins/formatThings.js";

export default {
  props: {
    contract: Object,
  },
  mixins: [formatThings],
  data() {
    return {
      paymentMethods: [],
      openBoxDetails: true,
    };
  },
  mounted() {
    this.paymentMethods = this.contract.meta.payment_methods
      ? JSON.parse(this.contract.meta.payment_methods)
      : [];
  },
  methods: {
    onClickOpenBoxDetails() {
      if (this.openBoxDetails) {
        this.openBoxDetails = false;
      } else {
        this.openBoxDetails = true;
      }
    },
    creditCard() {
      let creditCards = this.contract.meta.credit_card_rows
          ? JSON.parse(this.contract.meta.credit_card_rows)
          : [],
        total = 0;

      creditCards.forEach((cCard) => {
        total += this.contract.meta[
          `payment_methods_credit_card_total_value_${cCard.id}`
        ]
          ? parse(
              this.contract.meta[
                `payment_methods_credit_card_total_value_${cCard.id}`
              ]
            )
          : 0;
      });

      return total;
    },
  },
};
</script>

<style lang="sass" scoped>
.to-pay
  font-size: 11px
  color: #ff9500
  font-weight: 600
.section-title
  padding: 5px 0 0px 0
  border-bottom: 1px solid #e1e1e1
  margin-bottom: 10px
  &.closed
    border-bottom: 0
    margin-bottom: 0
  .arrow
    color: #ccc
    font-size: 16px
    position: relative
    cursor: pointer
  h2
    font-size: 13px
    font-weight: 500 !important
    color: #aab2bd
.info-box
  .total
    padding: 5px 0 5px 0
    font-size: 13px
    color: #656d78
    font-weight: 600 !important
  .line
    padding: 5px 0 5px 0
    border-bottom: 1px solid #e1e1e1
    font-size: 13px
    color: #656d78
    font-weight: 500 !important
    .ant-row-flex
      margin: 2px 0
    .discount
      color: #37bc9b
</style>
