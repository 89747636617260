<template>
  <div>
    <aRow
      v-if="contract.customer.person_type == 'Pessoa Física'"
      :gutter="[0, 10]"
      class="line"
    >
      <aCol :span="$root.windowWidth < 1401 ? 24 : 6">
        <font class="label">Nome</font>
        <font class="value">{{ contract.customer.first_name }}</font>
      </aCol>

      <aCol :span="$root.windowWidth < 1401 ? 24 : 10">
        <font class="label">Sobrenome</font>
        <font class="value">{{ contract.customer.last_name }}</font>
      </aCol>

      <aCol :span="$root.windowWidth < 1401 ? 24 : 8">
        <font class="label">Nascimento</font>
        <font class="value">{{
          formatDateModel(contract.customer.birthday, "dd MMM yyyy")
        }}</font>
      </aCol>

      <aCol :span="$root.windowWidth < 1401 ? 24 : 6">
        <font class="label">CPF</font>
        <font class="value">{{ contract.customer.cpf }}</font>
      </aCol>

      <aCol :span="$root.windowWidth < 1401 ? 24 : 10">
        <font class="label">E-mail</font>
        <font class="value">{{ contract.customer.email }}</font>
      </aCol>

      <aCol :span="$root.windowWidth < 1401 ? 24 : 8">
        <font class="label">Celular</font>
        <font class="value">{{ contract.customer.mobile_phone }}</font>
      </aCol>
    </aRow>

    <aRow
      v-if="contract.customer.person_type == 'Pessoa Jurídica'"
      :gutter="[0, 10]"
      class="line"
    >
      <aCol :span="$root.windowWidth < 1401 ? 24 : 12">
        <font class="label">Empresa</font>
        <font class="value">{{ contract.customer.trading_name }}</font>
      </aCol>
      <aCol :span="$root.windowWidth < 1401 ? 24 : 12">
        <font class="label">CNPJ</font>
        <font class="value">{{ contract.customer.cnpj }}</font>
      </aCol>
    </aRow>
  </div>
</template>

<script>
import formatThings from "../../general/mixins/formatThings.js";

export default {
  props: {
    contract: Object,
  },
  mixins: [formatThings],
};
</script>

<style lang="sass" scoped>
.line
  .label
    display: block
    color: #aab2bd
    font-size: 10px
  .value
    font-weight: 500
    display: block
    color: #434a54
    font-size: 14px
    line-height: 1.2
    text-transform: uppercase
</style>
