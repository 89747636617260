<template>
  <div>
    <header class="header">
      <div
        class="container"
        :class="$root.windowWidth < 960 ? 'responsive' : ''"
      >
        <aRow type="flex" justify="space-between">
          <aCol>
            <img
              v-if="contract.company_branch"
              :src="contract.company_branch.logo"
              :alt="contract.company_branch.name"
              width="100px"
            />
          </aCol>
          <aCol>
            <img
              class="selo"
              src="@/assets/selo_seguro.png"
              alt="Compra Segura"
            />
          </aCol>
        </aRow>
      </div>
    </header>

    <section class="main" style="padding: 0 10px">
      <div class="container">
        <aAlert
          v-if="$route.params.status === 'failed'"
          class="cred mb-20"
          type="error"
        >
          <template #message>
            <span class="red fw500 f16">
              <a-icon type="warning" class="mr-10" /> Houve uma falha no seu
              pagamento, tente novamente ou entre em contato com o seu
              consultor.</span
            >
          </template>
        </aAlert>

        <aRow :gutter="30">
          <aCol :span="$root.windowWidth < 960 ? 24 : 8">
            <aRow>
              <aCol :span="24" class="white-box">
                <ConsultantWidget :contract="contract" />
              </aCol>

              <aCol :span="24" class="info-box white-box">
                <PaymentDetailsWidget :contract="contract" />
              </aCol>

              <aCol :span="24" class="info-box white-box">
                <PaymentMethodsWidget :contract="contract" />
              </aCol>

              <aCol :span="24" class="info-box white-box">
                <ProductsWidget
                  @updateEmailFlightData="updateEmailFlightData"
                  :contract="contract"
                />
              </aCol>
            </aRow>
          </aCol>
          <aCol :span="$root.windowWidth < 960 ? 24 : 16">
            <PaymentSection
              :emailFlightData="emailFlightData"
              :contract="contract"
              :userData="userData"
            />
          </aCol>
        </aRow>
      </div>
    </section>

    <footer class="footer" :class="$root.windowWidth < 960 ? 'responsive' : ''">
      <div
        class="container"
        :class="$root.windowWidth < 960 ? 'responsive' : ''"
      >
        <aRow :gutter="20" type="flex" justify="space-between">
          <aCol class="col1" v-if="$root.windowWidth > 1400">
            <img src="@/assets/cards.png" alt="Viajar Resorts" height="30" />
          </aCol>

          <aCol
            class="col1"
            :span="$root.windowWidth < 960 ? 24 : ''"
            v-if="$root.windowWidth < 960"
          >
            <img src="@/assets/cards.png" alt="Viajar Resorts" height="30" />
          </aCol>

          <aCol class="col3" :span="$root.windowWidth < 960 ? 24 : ''">
            <img
              src="@/assets/certificados.png"
              alt="Viajar Resorts"
              height="30"
            />
          </aCol>
        </aRow>
      </div>
    </footer>
  </div>
</template>

<script>
import PaymentSection from "./sections/PaymentSection.vue";
import ConsultantWidget from "./widgets/ConsultantWidget.vue";
import PaymentDetailsWidget from "./widgets/PaymentDetailsWidget.vue";
import PaymentMethodsWidget from "./widgets/PaymentMethodsWidget.vue";
import ProductsWidget from "./widgets/ProductsWidget.vue";

export default {
  props: {
    contract: Object,
  },
  components: {
    PaymentSection,
    ConsultantWidget,
    PaymentDetailsWidget,
    PaymentMethodsWidget,
    ProductsWidget,
  },
  data() {
    return {
      emailFlightData: "",
      userData: undefined,
    };
  },
  beforeMount() {
    document.title = `${this.contract.company_branch.name} - Pagamentos`;
  },
  mounted() {
    if (this.$route.params.status) {
      if (this.$route.params.status === "success")
        this.actionsAfterPaymentSuccess();
      if (this.$route.params.status === "failed")
        this.actionsAfterPaymentFailed();
    }
  },
  methods: {
    actionsAfterPaymentSuccess() {
      const urlParams = window.location.search.replace("?", "").split("&");
      const tid = urlParams[1].split("=")[1];
      const nsu = urlParams[2].split("=")[1];
      const threedsres = urlParams[3].split("=")[1];

      this.$http.post("/contract/update-multi-meta-payment-link", {
        id: this.contract.id,
        [`payment_link_3ds_tid_${this.contract.payment_link_id}`]: tid,
        [`payment_link_3ds_nsu_${this.contract.payment_link_id}`]: nsu,
        [`payment_link_3ds_threeds_response_${this.contract.payment_link_id}`]: threedsres,
      });

      this.$http.post("/log/create-payment-link", {
        user_id: this.contract.user.id,
        module_id: this.contract.id,
        module: "contract",
        action: `payment-link-${this.contract.payment_link_id}-3ds-success`,
        description: `<b>Transação autorizada:</b> A verificação do <b>Cartão de Crédito</b> do contratante foi bem sucedida. ID transação: ${tid}.`,
      });

      this.$http.post("/notification/create-payment-link", {
        user_id: this.contract.user.id,
        created_by: 0,
        title: `Link de Pagamento ${this.contract.payment_link_id}: Transação autorizada`,
        content: `A verificação do <b>Cartão de Crédito</b> do contrato ID ${this.contract.id} foi bem sucedida.`,
        action: `/contracts/edit/${this.contract.id}`,
      });
    },
    actionsAfterPaymentFailed() {
      const urlParams = window.location.search.replace("?", "").split("&");
      const tid = urlParams[1].split("=")[1];
      const nsu = urlParams[2].split("=")[1];
      const code = urlParams[3].split("=")[1];
      const message = urlParams[4].split("=")[1];
      const threedsres = urlParams[5].split("=")[1];

      this.$http.post("/log/create-payment-link", {
        user_id: this.contract.user.id,
        module_id: this.contract.id,
        module: "contract",
        action: `payment-link-${this.contract.payment_link_id}-3ds-failure`,
        description: `<b>Transação NÃO AUTORIZADA:</b> A verificação do <b>Cartão de Crédito</b> do contratante falhou.<br><br>
        Erro ${code}: ${message}<br><br>`,
      });

      this.$http.post("/notification/create-payment-link", {
        user_id: this.contract.user.id,
        created_by: 0,
        title: `Link de Pagamento ${this.contract.payment_link_id}: Transação NÃO AUTORIZADA`,
        content: `A verificação de dois fatores do <b>Cartão de Crédito</b> do contrato ID ${this.contract.id} falhou.`,
        action: `/contracts/edit/${this.contract.id}`,
      });

      this.$http.post("/contract/update-multi-meta-payment-link", {
        id: this.contract.id,
        [`payment_link_3ds_tid_${this.contract.payment_link_id}`]: tid,
        [`payment_link_3ds_nsu_${this.contract.payment_link_id}`]: nsu,
        [`payment_link_3ds_threeds_failure_response_${this.contract.payment_link_id}`]: threedsres,
      });
    },
    updateEmailFlightData(flightData) {
      this.emailFlightData = JSON.stringify(flightData);
    },
  },
};
</script>
