<template>
    <div>
        <p><strong>Última atualização: 20 de setembro de 2022</strong></p>

        <p>
            A {{ company.domain }} ("{{ company.name }}" ou "Nós"), domiciliado
            na Rua Rio Grande do Norte 1436 12º andar, Belo Horizonte, Minas
            Gerais, CEP 30130.131, valoriza seus usuários e está comprometida
            com a proteção de seus dados pessoais e com a sua privacidade. Para
            o cumprimento desse compromisso, a {{ company.name }} desenvolveu
            esta Política de Privacidade ("Política de Privacidade" ou
            "Política") que descreve as práticas da {{ company.name }} no que se
            refere ao tratamento de dos dados pessoais, de acordo com os
            parâmetros estabelecidos pela Lei Geral de Proteção de Dados (Lei nº
            13.709/2018).
        </p>

        <p>
            Ao consentir com o nosso Termo de Consentimento, você autoriza que a
            {{ company.name }} utilize seus dados pessoais para alguns ou todos
            os fins descritos nessa Política de Privacidade.
        </p>

        <p>
            Podemos alterar a Política de Privacidade a qualquer tempo, mas você
            será avisado quando fizermos qualquer mudança substancial. Inclusive
            precisamos que você concorde com essas alterações para continuar
            tendo acesso ao Site. Você pode notar que sempre informamos, no
            início desta Política, a data da sua última atualização. Para
            quaisquer perguntas sobre esta Política e as práticas da
            {{ company.name }}, entre em contato conosco.
        </p>

        <p>
            Quando você utiliza os serviços da {{ company.name }}, seja através
            de nosso website ou WhatsApp, alguns dados pessoais são coletados e,
            posteriormente, utilizados. Essa Política de Privacidade destina-se
            a ajudar-lhe a entender de que forma tratamos tais dados pessoais.
        </p>

        <p><strong>Nosso Compromisso com a sua Privacidade</strong></p>

        <p>
            Aqui na {{ company.name }} estamos realmente empenhados em proteger
            a sua privacidade, garantindo que seus dados pessoais serão tratados
            de acordo com a lei brasileira e com as melhores práticas de
            mercado. Somos transparentes em relação às nossas práticas e estamos
            à disposição para conversar a respeito sempre que você quiser.
        </p>

        <p>
            Por isso, veja abaixo alguns dos princípios que orientam a forma
            pela qual a {{ company.name }} trata seus dados pessoais:
        </p>

        <ul>
            <li>
                Toda a coleta e tratamento de dados pessoais será realizada de
                acordo com essa Política de Privacidade e para cumprir as
                finalidades aqui especificadas.
            </li>
            <li>
                Seus dados não serão transferidos, vendidos, alugados ou
                divulgados de forma diferente do que indicamos nessa Política de
                Privacidade.
            </li>
            <li>
                Quando algum dado pessoal deixar de ser necessário e não
                tivermos a obrigação legal de armazená-lo, nossa intenção é
                excluí-lo, destruí-lo ou anonimizá-lo.
            </li>
            <li>
                Nossa intenção é coletar a menor quantidade possível de dados
                pessoais – realmente só pretendemos coletar os dados que forem
                necessários para atender às finalidades aqui descritas.
            </li>
            <li><br /></li>
        </ul>
        <p><strong>Definições importantes</strong></p>

        <p>
            Veja abaixo algumas definições importantes para entender melhor a
            proteção que aplicamos aos seus dados pessoais, os limites do nosso
            uso e seus direitos:
        </p>

        <ul>
            <li>Titular – Você é o titular dos seus dados pessoais.</li>
            <li>
                Dado pessoal – Informação que identifique ou leve à
                identificação de um titular. São exemplos de dados pessoais seu
                nome completo, telefone, CPF, RG e endereço.
            </li>
            <li>
                Controlador – Pessoa responsável por tomar decisões relacionadas
                ao tratamento dos dados pessoais dos titulares. Nesse caso, é a
                {{ company.name }}.
            </li>
            <li>
                Tratamento – Qualquer operação que seja realizada com dados
                pessoais, o que pode incluir obtenção, acesso, análise,
                transferência, armazenamento, anonimização, deleção etc.
            </li>
            <li>
                Anonimização – Processo de despersonalização dos dados pessoais
                por meio da remoção de qualquer associação destas informações
                aos titulares a quem elas se referem.
            </li>
        </ul>

        <p><strong>Seus Direitos</strong></p>

        <p>
            A legislação brasileira te concede uma série de direitos em relação
            aos seus dados pessoais e você poderá exercê-los conforme descrito
            nesta Política.
        </p>

        <p>
            Veja abaixo seus principais direitos relacionados aos seus dados
            pessoais:
        </p>

        <ul>
            <li>
                Questionar se seus dados pessoais são objeto de tratamento pela
                {{ company.name }}.
            </li>
            <li>
                Obter informações acerca da forma de tratamento dos seus dados
                pessoais.
            </li>
            <li>Corrigir dados incompletos, inexatos ou desatualizados.</li>
            <li>
                Anonimização, bloqueio ou eliminação dos dados pessoais que
                forem desnecessários, excessivos ou que estejam sendo tratados
                em desconformidade com a lei.
            </li>
            <li>
                Pedir a eliminação de seus dados pessoais, que tenham sido
                tratados a partir de consentimento dado por você anteriormente.
            </li>
            <li>
                Saber quais entidades públicas e privadas têm acesso aos dados
                por decisão da {{ company.name }}.
            </li>
            <li>
                Não fornecer consentimento para o tratamento dos seus dados e
                ser informado das consequências dessa recusa.
            </li>
            <li>
                Revogar o consentimento, a qualquer tempo, para coleta de novos
                dados pessoais.
            </li>
        </ul>

        <p>
            Solicitar a revisão de decisões tomadas unicamente com base em
            tratamento automatizado de dados pessoais que, por exemplo,
            determinem seu perfil pessoal, profissional, de consumo, ou de
            crédito.
        </p>

        <ol>
            <li>Quais dados pessoais coletamos?</li>
            <li>Qual é a nossa política de cookies e web beacons?</li>
            <li>Para que utilizamos os dados pessoais que coletamos?</li>
            <li>Com quem compartilhamos os dados pessoais coletados?</li>
            <li>
                Onde armazenamos e como protegemos os dados pessoais coletados?
            </li>
            <li>
                Como pode ser feito o acesso, eliminação e/ou atualização dos
                dados pessoais coletados?
            </li>
            <li>
                Como a {{ company.name }} relaciona-se com as suas redes
                sociais?
            </li>
            <li>Informações gerais.</li>
        </ol>
        <p>&nbsp;</p>
        <ol>
            <li><strong>Quais dados pessoais coletamos?</strong></li>
        </ol>
        <p>A {{ company.name }} coleta e armazena</p>
        <ol>
            <li>a) dados pessoais enviados por você</li>
            <li>
                b) dados pessoais enviados por terceiros, inclusive dados
                públicos, e
            </li>
            <li>
                c) dados pessoais coletados por nossos sistemas, conforme
                descrito a seguir:
            </li>
            <li><strong>a) Dados pessoais enviados por você: </strong></li>
        </ol>
        <p>
            Para poder fazer reservas turísticas na {{ company.name }}, você
            deve enviar determinados dados pessoais, que incluem, mas sem se
            limitar, nome e sobrenome, número de documento ou identificação
            válida, nacionalidade, informações de identificação tributária e
            informações físicas de contato (como número de telefone, domicílio
            ou endereço de e-mail). Visando formalizar as reservas turísticas,
            você deve também fornecer-nos informações sobre o seu cartão de
            débito ou crédito (tais como o número do referido cartão de crédito,
            o código de segurança, o nome do titular do cartão e a data de
            vencimento). A {{ company.name }} poderá confirmar a veracidade dos
            dados pessoais enviados, a critério exclusivo, através de entidades
            públicas, empresas especializadas ou centrais de risco. As
            informações obtidas pela {{ company.name }} junto a estas entidades
            serão tratadas de modo confidencial, respeitando a legislação
            brasileira no que diz respeito à proteção de dados.
        </p>

        <p>
            Você sempre poderá decidir não nos enviar dados pessoais, mas, em
            geral, certos tipos de dados pessoais são necessários para comprar
            ou realizar transações na {{ company.name }}.
        </p>

        <p>
            Caso decida não nos fornecer determinados dados pessoais, é possível
            que você não possa contratar nossos serviços.
        </p>

        <p>
            Caso faça uma reserva em nome de um terceiro, deverá obter
            previamente o consentimento deste terceiro antes de fornecer-nos
            seus dados pessoais. Caso tal terceiro seja menor de idade, de
            acordo com a legislação aplicável, você declara possuir o
            consentimento específico dado por pelo menos um dos pais ou pelo
            responsável legal do menor.
        </p>

        <p>
            Você também pode enviar opiniões, comentários e sugestões
            participando voluntariamente de pesquisas de opinião e/ou
            respondendo às perguntas enviadas através de e-mails, bem como
            através do site ou do aplicativo (as "Opiniões"). Nesse caso,
            visando oferecer a outros usuários depoimentos reais sobre
            experiências de serviços, você aceita e concorda que todas as
            Opiniões que nos envie poderão ser publicadas pelo site e/ou
            aplicativo da {{ company.name }}, ou em outros sites e/ou
            aplicativos com os quais a {{ company.name }} tenha relações
            contratuais. Nesse sentido, você poderá ser identificado através do
            seu nome completo e/ou apelido, foto, comentário e nacionalidade. Ao
            enviar-nos sua Opinião, ou subir fotos reais através do site, você
            entende que tais conteúdos são não confidenciais e poderão ser
            usados livremente pela {{ company.name }}, inclusive para fins
            comerciais. Sujeito a quaisquer limitações previstas na legislação
            aplicável, você renuncia qualquer direito relativo à utilização
            desse material, incluindo direitos de privacidade, direitos de
            propriedade, direitos de publicidade, incluindo o direito de aprovar
            como esse material é utilizado. Além disso, você concede uma licença
            não exclusiva, irrestrita, perpétua e livre de royalties a nós e a
            nossos sucessores para usar, transmitir, copiar e exibir tais
            Opiniões em toda e qualquer mídia atualmente conhecida ou criada
            posteriormente. Nenhuma outra compensação será concedida por
            quaisquer materiais ou informações enviadas.
        </p>

        <p>
            Lembre-se que você pode enviar sua opinião de maneira anônima ou
            eliminá-la, configurando suas preferências nas opções "Minha Conta"
            ou "Minha Reserva". Sempre esteja ciente de que, quando colocar
            informações em uma área pública do site (incluindo, sem limitar-se
            a, avisos, grupos de chats, álbuns de fotografias eletrônicos e
            comentários sobre os produtos e serviços), estará
            disponibilizando-as para outros membros, usuários do site e público
            em geral; o que se encontra fora do âmbito de controle da
            {{ company.name }}. Lembre-se do mencionado acima e tome cuidado com
            as informações que decidir divulgar.
        </p>

        <ol>
            <li>
                <strong
                    >b) Dados pessoais enviados por terceiros, inclusive dados
                    públicos:</strong
                >
            </li>
        </ol>

        <p>
            Na extensão permitida por lei, especialmente a legislação referente
            à proteção de dados pessoais, a {{ company.name }} também poderá
            obter dados pessoais sobre você, tanto de entidades afiliadas,
            parceiros comerciais e outras fontes de terceiros independentes,
            quanto de bases de dados públicas, informações recolhidas durante
            uma conversa telefônica junto ao centro de atenção ao cliente e/ou
            através de aplicativos interativos. Esses dados pessoais obtidos
            através de terceiros, ainda que dados públicos, serão combinados com
            os dados pessoais que já possuímos sobre você.
        </p>

        <p>
            Todos os dados pessoais obtidos através de terceiros serão tratados
            em conformidade com as disposições desta Política de Privacidade e a
            legislação brasileira.
        </p>

        <ol>
            <li>
                <strong
                    >c) Dados pessoais coletados por nossos sistemas:</strong
                >
            </li>
        </ol>

        <p>
            Por conta de uma obrigação legal somos obrigados a guardar, por seis
            meses, os dados de navegação no nosso site e, eventualmente,
            autoridades judiciais podem solicitar tais informações.
        </p>
        <p>
            Os dados de navegação incluem: endereços de IP, Identificadores
            Uniformes de Recursos (URIs), horários de solicitações, métodos
            usados para enviar solicitações ao servidor, tamanho dos arquivos
            recebidos como resposta, códigos numéricos indicando a situação da
            resposta do servidor, e outros parâmetros relacionados ao sistema
            operacional do usuário e ao ambiente computacional.
        </p>

        <p>
            Os sistemas de computadores e softwares projetados para navegar
            neste site coletam, durante seu uso normal, determinados dados
            pessoais, cuja transmissão é implícita ao uso de protocolos de
            comunicação da Internet. Em regra, essas informações não são
            coletadas para identificar usuários, mas, por conta da sua natureza,
            podem permitir essa identificação por meio de associações a outros
            dados.
        </p>

        <p>
            <strong>2.Qual é a nossa política de cookies e web beacons?</strong>
        </p>

        <p>
            A {{ company.name }} informa que o site utiliza "cookies",
            relacionados a determinadas características ou funções. Os cookies
            são tipos específicos de informações que um site transmite ao disco
            rígido do computador do usuário, com o objetivo de manter os
            registros. Os cookies podem servir para facilitar o uso de um site,
            ao guardar senhas e preferências enquanto o usuário navega na
            internet.
        </p>
        <p>
            Por meio dos cookies, não podemos acessar outras informações
            armazenadas no seu dispositivo, mesmo se esses cookies forem
            baixados aqui. Os cookies não podem fazer o upload de códigos de
            qualquer tipo ou carregar vírus ou malware, e não prejudicam o
            terminal do usuário.
        </p>

        <p>
            As "web beacons" são imagens inseridas em um site da internet ou
            e-mail, que podem ser utilizadas para monitorar o comportamento de
            um visitante, assim como armazenar informações sobre o endereço IP
            do usuário, a duração do tempo de interação em tal site e o tipo de
            navegador utilizado, entre outros. A {{ company.name }} recolhe e
            armazena automaticamente determinadas informações sobre a atividade
            dos seus Usuários. Tais informações podem incluir a URL da qual
            provêm (estejam ou não no Site da {{ company.name }}), a URL que
            acessam frequentemente, o navegador que utilizam e seus endereços
            IP, bem como os sites visitados e as buscas realizadas.
        </p>
        <p>
            Embora a maioria dos navegadores aceitem "cookies" e "web beacons"
            automaticamente, Você pode configurar seu navegador para que não os
            aceite.
        </p>

        <p>Para desativar os "cookies", deve seguir as seguintes instruções:</p>

        <ul>
            <li>
                Internet Explorer:
                <a
                    href="http://support.microsoft.com/kb/283185"
                    rel="noopener noreferrer"
                    target="_blank"
                    >http://support.microsoft.com/kb/283185</a
                >
            </li>
            <li>
                Firefox:http://support.mozilla.org/es/kb/Habilitar%20y%20deshabilitar%20cookies?
                s=deshabilitar+cookies&amp;r=0&amp;e=es&amp;as=s
            </li>
            <li>
                Opera:
                <a
                    href="http://help.opera.com/Windows/11.60/en/cookies.html"
                    rel="noopener noreferrer"
                    target="_blank"
                    >http://help.opera.com/Windows/11.60/en/cookies.html</a
                >
            </li>
            <li>
                Safari IPAD:
                http://manuals.info.apple.com/en_US/iPad_iOS4_User_Guide.pdf
                Safari IPHONE:
                <a
                    href="http://manuals.info.apple.com/en_US/iPhone_iOS3.1_User_Guide.pdf"
                    rel="noopener noreferrer"
                    target="_blank"
                    >http://manuals.info.apple.com/en_US/iPhone_iOS3.1_User_Guide.pdf</a
                >
            </li>
            <li>
                Chrome:
                <a
                    href="http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647"
                    rel="noopener noreferrer"
                    target="_blank"
                    >http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647</a
                >
            </li>
        </ul>
        <p>&nbsp;</p>
        <ol>
            <li>
                <strong>
                    Para que utilizamos os dados pessoais que coletamos?</strong
                >
            </li>
        </ol>
        <p>
            A {{ company.name }} utiliza os dados pessoais coletados para os
            seguintes fins:
        </p>
        <ul>
            <li>
                Gerenciar sua reserva turística, realizar as transações que
                houver iniciado e processar faturas ou demais documentos
                fiscais, relacionados a sua reserva. Além disso, manter o
                histórico da reserva nos bancos de dados da {{ company.name }};
            </li>
            <li>
                Oferecer-lhe produtos e serviços, através da própria{{
                    company.name
                }}
                ou de parceiros terceiros, na medida em que formos autorizados a
                realizar esse tipo de comunicação;
            </li>
            <li>
                Gerenciar suas solicitações e reclamações, fornecendo respostas
                às suas perguntas e comentários;
            </li>
            <li>
                Análises internas estatísticas no que diz respeito às vendas
                e/ou padrões de consumo.
            </li>
            <li>
                Medir satisfação do cliente para com nossos produtos, serviços e
                atendimento;
            </li>
            <li>
                Cumprimento de obrigações legais, cooperação com órgãos públicos
                ou para o exercício de algum direito previsto por lei;
            </li>
            <li>
                Enviar-lhe confirmações e atualizações sobre sua viagem, bem
                como quaisquer outras informações relevantes. Estas informações
                poderão ser enviadas via e-mail, SMS ou WhatsApp, ou outra forma
                de contato que você tiver informado. Estas mensagens poderão
                conter (sem limitar-se a) informações sobre os serviços da
                {{ company.name }}, bem como dados de contato de prestadores de
                serviços que poderiam auxiliar a sua experiência de viagem no
                destino.
            </li>
            <li>
                Entrar em contato com você para fins de prestar serviços ao
                cliente, realizar pesquisas de opinião, estatísticas ou análises
                sobre hábitos de consumo ou preferências, notificar-lhe por
                e-mail sobre as ofertas especiais e os produtos e serviços
                relacionados às viagens que poderiam ser de seu interesse, na
                medida em que a {{ company.name }} for autorizada a isso.
            </li>
            <li>
                Com o propósito de oferecer melhores serviços ou proporcionar
                aos usuários informações relativas às suas preferências, a
                {{ company.name }} também poderá desenvolver estudos internos
                sobre os interesses, comportamentos e demografia dos usuários,
                para compreender melhor suas necessidades e interesses, melhorar
                nossas iniciativas comerciais e promocionais, personalizar seus
                conteúdos, sua apresentação e serviços, exibir publicidade ou
                promoções e banners que sejam interessantes para os nossos
                usuários, para tanto, a {{ company.name }} só fará uso de dados
                anonimizados.
            </li>
            <li>
                Do mesmo modo, a {{ company.name }} utiliza as informações dos
                cartões de débito ou crédito (tais como o nome do titular do
                cartão, número do cartão e data de vencimento) somente com o
                propósito de completar as reservas de viagem que você fizer,
                incluindo o envio dos seus dados aos prestadores finais de
                serviços, para gerenciar suas reservas e/ou solicitações de
                compra. Você também pode escolher que a
                {{ company.name }} armazene determinados dados do seu cartão e
                faturas, que aparecerão automaticamente ao entrar no site e/ou
                no aplicativo da {{ company.name }}.
            </li>
            <li>
                Finalmente, a {{ company.name }} poderá utilizar os dados
                pessoais do usuário para; (i) o processamento e cumprimento do
                fluxo de pagamento entre o usuário e o fornecedor final,
                inclusive perante os respectivos meios de pagamentos, como
                resultado da execução do contrato estabelecido entre as partes
                (usuário e fornecedor final) e a intermediação praticada por ela
                – {{ company.name }}; e, (ii) a garantia à prevenção de fraudes
                e irregularidade, à segurança do titular, bem como proteger e
                resguardar a segurança e a integridade dos dados, por meio de
                processos de identificação e autenticação de cadastro em
                sistemas eletrônicos da {{ company.name }} e de terceiros, que
                façam ou possam fazer parte da relação e respectivo fluxo.
            </li>
        </ul>

        <p>
            <strong
                >4.Com quem compartilhamos os dados pessoais coletados?</strong
            >
        </p>

        <p>
            Você expressa seu consentimento inequívoco para que a
            {{ company.name }}
            possa compartilhar os dados pessoais relevantes dos usuários com
            prestadores de serviços para a gestão das suas reservas e/ou
            solicitações de compra, tais como linhas aéreas, hotéis, empresas
            turísticas que prestam serviços no destino, empresas de aluguel de
            veículos, distribuidores e outros prestadores de produtos e serviços
            que você contratar através do nosso site e/ou nosso aplicativo.
        </p>

        <p>
            Do mesmo modo, você aceita compartilhar seus dados pessoais com
            prestadores de serviços para a {{ company.name }}, incluindo-se
            empresas que prestem serviços de cartões de crédito, análises
            comerciais, serviços de atenção ao cliente, marketing e prevenção de
            fraudes. Tais empresas poderão utilizar seus dados para enviar
            ofertas de produtos e serviços a você.
        </p>

        <p>
            Neste sentido, ao aceitar os termos desta política de privacidade,
            você reconhece e concorda que a {{ company.name }}, na condição de
            controlador de dados, nos termos da legislação aplicável, poderá
            compartilhar com instituições financeiras e adquirentes, seus dados
            pessoais e transacionais, incluindo seus dados cadastrais e os dados
            de suas reservas e/ou solicitações de compra para que,
            conjuntamente, possam oferecer produtos e serviços adequados ao seu
            perfil e, ainda possam atribuir maior segurança e prevenir fraudes e
            realizar análises de risco de crédito e aperfeiçoar o atendimento e
            os produtos e serviços prestados, sempre com a estrita observância
            dos princípios e finalidades legais.
        </p>

        <p>
            A {{ company.name }} pode autorizar prestadores de serviços a
            coletar dados pessoais em nosso nome, incluindo, conforme necessário
            para operar determinados elementos do nosso site e/ou aplicativo, ou
            para facilitar a divulgação de publicidade on-line adaptada aos seus
            interesses. Além disso, a {{ company.name }} poderá compartilhar os
            dados pessoais com parceiros comerciais, com os quais poderíamos
            oferecer produtos ou serviços em conjunto ou individualmente. Estes
            prestadores de serviços e parceiros comerciais estão sujeitos a
            rigorosos contratos de confidencialidade que proíbem a utilização ou
            divulgação não autorizada dos dados pessoais aos quais têm acesso,
            bem como impossibilitam que utilizem seus dados pessoais em
            desconformidade com a Lei Geral de Proteção de Dados.
        </p>

        <p>
            Finalmente, a {{ company.name }} poderá igualmente compartilhar seus
            dados pessoais com as empresas pertencentes ao grupo
            {{ company.name }}.com. Caso houver transferência de dados pessoais,
            informamos que o receptor de tais dados pessoais assumirá as mesmas
            obrigações correspondentes ao responsável que transfere esses dados
            pessoais.
        </p>

        <p>
            Esteja ciente de que, caso você proporcionar, de maneira livre e
            voluntária e por iniciativa própria, dados pessoais diretamente aos
            prestadores de serviços (sejam ou não prestadores de serviços para a
            {{ company.name }}), tais prestadores recolherão, tratarão e
            conservarão estas informações de acordo com suas próprias Políticas
            de Privacidade. A {{ company.name }} não é responsável pelo eventual
            uso indevido das Informações Pessoais de parte de empresas externas
            fora do site ou do aplicativo.
        </p>

        <p>
            <strong
                >5.Onde armazenamos e como protegemos os dados pessoais
                coletados?
            </strong>
        </p>

        <p>
            Todos os dados pessoais são recolhidos e armazenados em servidores
            localizados fisicamente nos Estados Unidos. A
            {{ company.name }} poderá futuramente realocar tais servidores em
            qualquer outro país, e poderá armazenar os dados pessoais nos
            Estados Unidos ou em outros países, para fins de respaldo ou
            back-up. Ademais, a {{ company.name }}
            faz parte de um grupo econômico internacional com sociedades
            localizadas também fora do Brasil. Você concorda que poderemos
            transferir seus dados entre as empresas do grupo, como parte do
            curso normal de nossas atividades.
        </p>

        <p>
            De acordo com a legislação nacional, isso constitui “Transferência
            Internacional de Dados”, então ao concordar com esta Política de
            Privacidade, você também entende e consente com este envio.
            Garantimos que seus dados também serão protegidos e bem cuidados no
            exterior.
        </p>

        <p>
            Em qualquer caso, a {{ company.name }} compromete-se a garantir que
            se cumpram as normas legalmente exigíveis para a proteção e
            resguardo de seus dados pessoais, mediante a assinatura de acordos
            ou termos, cujo objeto seja a proteção dos seus dados pessoais.
        </p>

        <p>
            A {{ company.name }} possui ferramentas para proteger seus dados
            pessoais de terceiros mal-intencionados ou sem autorização. Adotamos
            medidas de segurança adequadas para nos proteger contra acesso,
            alteração, divulgação ou destruição não-autorizada dos dados. Essas
            medidas incluem análises internas de nossas práticas de coleta,
            armazenamento e processamento de dados.
        </p>

        <p>
            Com os cuidados que tomamos, podemos garantir que evitamos ao máximo
            possíveis falhas de segurança que afetem seus dados pessoais.
            Entretanto, reconhecendo que existem riscos naturais inerentes ao
            uso da Internet, entendemos que não podemos anular esse risco por
            completo. De todo modo, garantimos que a proteção de seus dados
            pessoais é uma das nossas prioridades e que, caso algum incidente
            aconteça, adotaremos todas as medidas possíveis para minimizar
            eventuais danos.
        </p>

        <p>
            A {{ company.name }} resguarda seus dados pessoais de acordo com os
            padrões e procedimentos de segurança e confidencialidade vigentes no
            Brasil. A {{ company.name }} não transmite, divulga ou compartilha
            os dados pessoais coletados a terceiros exceto aqueles descritos na
            presente Política. Nesse sentido, este site e o aplicativo tomam as
            medidas necessárias para proteger os dados pessoais dos usuários.
        </p>

        <p>
            <strong
                >6.Como pode ser feito o acesso, eliminação e/ou atualização dos
                dados pessoais coletados?</strong
            >
        </p>

        <p><strong>6.1.Opt-out</strong></p>

        <p>
            Quando você realizar transações ou registrar-se como usuário no site
            da {{ company.name }}, lhe daremos a opção de receber boletins
            promocionais, mensagens ou alertas de e-mail sobre ofertas. Em cada
            mensagem de e-mail enviada, também lhe daremos a oportunidade de
            cancelar sua inscrição.
        </p>

        <p>
            Esteja ciente de que o exercício do direito de opt-out refere-se aos
            boletins promocionais, mensagens ou alertas de e-mail sobre ofertas.
            Nesse sentido, você continuará recebendo comunicações referentes ao
            estado das suas reservas ativas. Em determinados casos, inclusive, a
            {{ company.name }} deverá manter nos seus arquivos determinados
            dados pessoais, com a finalidade de resolver disputas ou
            reclamações, detectar problemas ou incidências e solucioná-los, e
            cumprir as disposições dos Termos e Condições Gerais por um período
            de tempo determinado pela legislação. Em qualquer caso, os dados
            pessoais de um usuário não serão retirados imediatamente dos nossos
            arquivos por questões legais e de segurança.
        </p>

        <p>
            Para fazer qualquer modificação nos dados pessoais oportunamente
            fornecidos, ligue para 0800 291 2251.
        </p>

        <p>
            <strong
                >6.2.Como exercer os direitos descritos na seção “Seus
                Direitos”?</strong
            >
        </p>

        <p>Você pode exercê-los através de qualquer das opções a seguir:</p>

        <ul>
            <li>
                Apresentando a devida solicitação na Rua Rio Grande do Norte,
                1436 12º andar, Belo Horizonte, Minas Gerais, CEP 30130.131, ou
                enviando-a por e-mail ao seguinte endereço: dadospessoais@{{
                    company.domain
                }}. Em sua solicitação, deverá informar seu nome completo e,
                conforme o caso, documentos que comprovem a representação legal
                do titular, assim como anexar uma cópia simples do seu documento
                nacional de identidade, informar seu e-mail ou endereço postal
                para notificações e um número de telefone para contato; e
                descrever clara e precisamente os dados pessoais sobre os quais
                deseja quaisquer dos direitos mencionados, e os documentos que
                embasem tal alteração. Uma vez cumpridos os requisitos
                detalhados acima, e sempre que a solicitação for cabível, a
                {{ company.name }} lhe comunicará se irá atender à solicitação
                ou se irá negá-la. A {{ company.name }} dispõe de 10 (dez) dias
                úteis a partir do recebimento da solicitação para enviar uma
                resposta, caso trate-se de uma solicitação de acesso, e 05
                (cinco) dias úteis se você tiver solicitado a retificação,
                atualização ou eliminação dos dados pessoais.
            </li>
            <li>
                Você também pode acessar, atualizar e corrigir suas informações
                de registro a qualquer momento entrando em "Meu Perfil", em
                "Minha {{ company.name }}", no Site da {{ company.name }}.
                Entrando em "E-mails e Alertas" em "Minha {{ company.name }}",
                você poderá escolher quais e-mails deseja receber. Atente-se ao
                fato de que você é responsável por fornecer dados pessoais
                atualizados, corretos e autênticos.
            </li>
            <li><br /></li>
        </ul>
        <p>
            <strong
                >7.Como a {{ company.name }} relaciona-se com as suas redes
                sociais?</strong
            >
        </p>

        <p>
            Ao registrar-se na {{ company.name }}, é necessária a escolha de uma
            identificação de usuário e uma senha (ou seja, acessar uma conta
            pessoal no site). Do mesmo modo, a {{ company.name }} permite que
            você acesse a seção "Minha {{ company.name }}" através da sua conta
            de Facebook, de Google+ ou outras redes sociais (doravante, as
            "Redes Sociais", ou "Rede Social") que futuramente tenham
            compatibilidade com o acesso à "Minha Conta".
        </p>

        <p>
            Como parte da funcionalidade do site, e para possibilitar uma melhor
            experiência personalizada, você pode vincular a sua conta às Redes
            Sociais, proporcionando as informações de login da sua conta na Rede
            Social através do site e/ou o aplicativo ou permitindo que a
            {{ company.name }} acesse a sua conta nas Redes Sociais, de acordo
            com o permitido nos termos e condições aplicáveis que regem o uso
            que você faz de cada Rede Social.
        </p>

        <p>
            Você declara que tem direito de revelar as informações de login da
            sua Rede Social à {{ company.name }} e/ou conceder à
            {{ company.name }} o acesso à sua conta, sem que isso suponha um
            descumprimento da sua parte de nenhum dos termos e condições que
            regem o uso que você faz da correspondente Rede Social, e sem que a
            {{ company.name }} esteja obrigada a pagar nenhuma taxa ou a
            respeitar nenhuma limitação de uso imposta pelos prestadores de
            serviços externos da Rede Social.
        </p>

        <p>
            Devido ao fato de conceder à {{ company.name }} o acesso a qualquer
            Rede Social, você entende que a {{ company.name }} acessará,
            disponibilizará e armazenará todos os conteúdos que você houver
            compartilhado e armazenado na sua Rede Social, e que devem constar
            no site da {{ company.name }}, tais como: Facebook, Instagram,
            Twitter.
        </p>

        <p>
            Em função das redes sociais que você escolher, e sujeitas às
            configurações de privacidade que houver estabelecido em tais contas,
            as informações de identificação pessoal que publicar em suas redes
            sociais estarão disponíveis na sua conta da {{ company.name }} e
            através dela no site e/ou o aplicativo. Você deve estar ciente de
            que, se uma rede social deixar de estar disponível, ou se o
            prestador de serviços externo bloquear o acesso da
            {{ company.name }} a esta rede social, os conteúdos das redes
            sociais deixarão de estar disponíveis no site e/ou o aplicativo ou
            através deles.
        </p>
        <p>
            Se você se registra no site da {{ company.name }} através da sua
            conta pessoal de uma rede social (a "Conta Pessoal"), concorda que a
            {{ company.name }} poderá acessar informações contidas na sua Conta
            Pessoal, incluindo seus Dados Pessoais, informações sobre seus
            interesses, preferências, contatos e quaisquer conteúdos disponíveis
            na sua Conta Pessoal. Envie ao e-mail do Usuário vinculado à Conta
            Pessoal ou ao e-mail utilizado para fazer a reserva na
            {{ company.name }}, informações ou mensagens com a finalidade
            descrita na seção 3: "Para que utilizamos as informações que
            coletamos?", que se encontra nesta Política.
        </p>

        <p><strong>8.Informações gerais.</strong></p>

        <p>
            ESTEJA CIENTE DE QUE SUA RELAÇÃO COM OS PRESTADORES DE SERVIÇOS
            EXTERNOS REGE-SE EXCLUSIVAMENTE PELOS ACORDOS QUE TENHA FEITO COM
            TAIS PRESTADORES DE SERVIÇOS EXTERNOS. Este site pode conter
            vínculos (links) a outros sites que têm suas próprias políticas de
            privacidade e confidencialidade. Por esse motivo, recomendamos que,
            se você acessar estes outros sites, revise cuidadosamente as
            respectivas práticas e políticas de confidencialidade, sempre que
            esta Política de Privacidade não englobar práticas ou políticas de
            terceiros, incluindo os que possam revelar e/ou compartilhar
            informações com a {{ company.name }}.
        </p>

        <p>
            Se você perder o acesso à sua conta ou senha de acesso às redes
            sociais, poderá perder o controle sobre os seus dados pessoais e
            estar sujeito a transações legalmente válidas realizadas em seu
            nome. Portanto, se por qualquer razão sua senha estivesse
            comprometida, você deverá imediatamente: (i) trocá-la, modificando
            suas informações de registro proporcionadas a este site, e (ii)
            entrar em contato conosco através de qualquer canal oferecido pela
            {{ company.name }}.
        </p>
    </div>
</template>

<script>
export default {
    props: {
        contract: Object,
    },
    data() {
        return {
            company: {
                domain:
                    this.contract.meta.payment_methods_payment_link_base_url +
                    ".com.br",
                name: this.contract.company_branch.name,
            },
        };
    },
    methods: {},
};
</script>
