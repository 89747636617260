<template>
  <div>
    <aRow
      class="section-title"
      :class="!openBoxDetails ? 'closed' : ''"
      type="flex"
      justify="space-between"
    >
      <aCol> <h2>Detalhes do pagamento</h2></aCol>
      <aCol>
        <a-icon class="arrow" type="down" @click="onClickOpenBoxDetails" />
      </aCol>
    </aRow>
    <div v-if="openBoxDetails">
      <div class="products line">
        <aRow
          v-if="contractedServices.includes('hotel')"
          type="flex"
          justify="space-between"
        >
          <aCol>Hospedagem para {{ hotel().text }} </aCol>
          <aCol>
            {{
              formatPricePtBr(hotel().data.total - hotel().data.totalExtraFees)
            }}</aCol
          >
        </aRow>
        <aRow
          v-if="contractedServices.includes('flight')"
          type="flex"
          justify="space-between"
        >
          <aCol>Passagem para {{ flight().text }} </aCol>
          <aCol>
            {{
              formatPricePtBr(flight().data.total - flight().data.totalTaxes)
            }}</aCol
          >
        </aRow>
        <aRow
          v-if="contractedServices.includes('service')"
          type="flex"
          justify="space-between"
        >
          <aCol>Serviços</aCol>
          <aCol>
            {{
              formatPricePtBr(
                service().data.total - service().data.totalExtraFees
              )
            }}</aCol
          >
        </aRow>
        <aRow
          v-if="contractedServices.includes('cruise')"
          type="flex"
          justify="space-between"
        >
          <aCol>Cruzeiro para 2 pessoas</aCol>
          <aCol>R$ 0,00</aCol>
        </aRow>
      </div>
      <div class="taxes line">
        <aRow type="flex" justify="space-between">
          <aCol>Impostos e taxas</aCol>
          <aCol>
            {{
              formatPricePtBr(
                flight().data.totalTaxes +
                  hotel().data.totalExtraFees +
                  service().data.totalExtraFees
              )
            }}</aCol
          >
        </aRow>
        <aRow class="discount" type="flex" justify="space-between">
          <aCol>Desconto</aCol>
          <aCol>
            {{
              formatPricePtBr(
                hotel().data.totalDiscount + service().data.totalDiscount
              )
            }}</aCol
          >
        </aRow>
      </div>
      <div class="total">
        <aRow type="flex" justify="space-between">
          <aCol>Total da sua viagem:</aCol>
          <aCol>{{ formatPricePtBr(allContractData.total) }}</aCol>
        </aRow>
      </div>
    </div>
  </div>
</template>

<script>
import formatThings from "../../general/mixins/formatThings.js";
import contractMixins from "../../general/mixins/contractMixins.js";

export default {
  props: {
    contract: Object,
  },
  mixins: [formatThings, contractMixins],
  data() {
    return {
      contractedServices: [],
      openBoxDetails: true,
    };
  },
  mounted() {
    this.contractedServices = this.contract.meta.contracted_services
      ? JSON.parse(this.contract.meta.contracted_services)
      : [];

    this.contractPricingData(this.contract.meta);
  },
  methods: {
    onClickOpenBoxDetails() {
      if (this.openBoxDetails) {
        this.openBoxDetails = false;
      } else {
        this.openBoxDetails = true;
      }
    },
    hotel() {
      let arr = this.contract.meta.hotel_rows
          ? JSON.parse(this.contract.meta.hotel_rows)
          : [],
        text = "",
        total = 0;

      arr.forEach((product) => {
        product.accommodation.forEach((room) => {
          total += room.travellers.length;
        });
      });

      if (total == 1) {
        text = "1 pessoa";
      } else {
        text = `${total} pessoas`;
      }

      return { text, data: this.hotelPricingData(this.contract.meta) };
    },
    service() {
      let arr = this.contract.meta.service_travellers
          ? JSON.parse(this.contract.meta.service_travellers)
          : [],
        text = "",
        total = 0;

      total += arr.length;

      if (total == 1) {
        text = "1 pessoa";
      } else {
        text = `${total} pessoas`;
      }

      return { text, data: this.servicePricingData(this.contract.meta) };
    },
    flight() {
      let arr = this.contract.meta.flight_sections_rows
          ? JSON.parse(this.contract.meta.flight_sections_rows)
          : [],
        text = "",
        total = 0;

      arr.forEach((product) => {
        total += product.travellers.length;
      });

      if (total == 1) {
        text = "1 pessoa";
      } else {
        text = `${total} pessoas`;
      }

      return { text, data: this.flightPricingData(this.contract.meta) };
    },
  },
};
</script>

<style lang="sass" scoped>
.section-title
  padding: 5px 0 0px 0
  border-bottom: 1px solid #e1e1e1
  margin-bottom: 10px
  &.closed
    border-bottom: 0
    margin-bottom: 0
  .arrow
    color: #ccc
    font-size: 16px
    position: relative
    cursor: pointer
  h2
    font-size: 13px
    font-weight: 500 !important
    color: #aab2bd
.info-box
  .total
    padding: 5px 0 5px 0
    font-size: 13px
    color: #656d78
    font-weight: 600 !important
  .line
    padding: 5px 0 5px 0
    border-bottom: 1px solid #e1e1e1
    font-size: 13px
    color: #656d78
    font-weight: 500 !important
    .ant-row-flex
      margin: 2px 0
    .discount
      color: #37bc9b
</style>
