<template>
    <div>
        <p><center>Última atualização em Janeiro de 2022</center></p>
        <p>
            <center>
                <strong
                    >TERMOS E CONDIÇÕES GERAIS DA {{ company.branch }}</strong
                >
            </center>
        </p>
        <p>
            <center>
                <strong
                    >A {{ company.branch }} LHE DÁ AS BOAS-VINDAS E, AGRADECENDO
                    A SUA PREFERÊNCIA.</strong
                >
            </center>
        </p>
        <p>
            Os termos “<strong>{{ company.branch }}</strong
            >”, “<strong>{{ company.domain }}</strong
            >”, “nós” e “nosso” se referem à {{ company.name }}. O termo “Você”,
            se refere ao usuário dos serviços oferecidos pela
            {{ company.domain }} através do site da web www.{{ company.domain }}
            e/ou através de qualquer outro canal de comercialização utilizado
            atualmente ou que venha a ser utilizado no futuro pela
            {{ company.branch }} para oferecer Serviços (a(s) “Plataforma(s)”).
        </p>
        <ul>
            <li>
                COM.BR é uma agência de viagens on-line, cuja plataforma
                tecnológica permite a uma grande quantidade de prestadores de
                serviços turísticos (o(s) “Fornecedor(es)”) oferecer e
                comercializar seus serviços aos usuários, que, por sua vez,
                podem procurar informações sobre vendas de passagens, passeios,
                viagens e excursões, nas modalidades aérea, aquaviária,
                terrestre, ferroviária e conjugadas, assessoramento,
                planejamento e organização de atividades associadas à execução
                de viagens turísticas ou excursões, bem como a organização de
                programas, serviços, roteiros e itinerários de viagens,
                individuais ou em grupo, organização de programas e serviços
                relativos a viagens educacionais ou culturais e demais serviços
                turísticos (o(s) “Serviço(s) Turístico(s)”), comparar e reservar
                as referidas ofertas em tempo real, e adquiri-las separadamente
                ou em conjunto, montando e gerenciando sua própria viagem, em
                conformidade com as suas necessidades pessoais.
            </li>
            <li>
                COM.BR não é o fornecedor dos Serviços Turísticos e atua como
                intermediadora dos produtos turísticos oferecidos pelos
                Fornecedores, não sendo, portanto, obrigado, direta ou
                indiretamente, a executar o Serviço Turístico, tampouco
                responsável por qualquer falha na prestação de serviços
                prestados pelos Fornecedores, ou quaisquer danos causados por
                culpa exclusiva dos Fornecedores. Quando Você contrata Serviços
                Turísticos através da {{ company.branch }}, está celebrando um
                contrato diretamente com o Fornecedor que oferece os seus
                Serviços Turísticos através da nossa Plataforma. A
                {{ company.company }} atua como intermediária entre você e os
                Fornecedores de Serviços Turísticos.
            </li>
            <li>
                Ao contratar Serviços Turísticos através da
                {{ company.branch }}, Você garante que: (i) é civilmente capaz;
                (ii) aceita expressamente os Termos e Condições; (iii) só
                utiliza a Plataforma da {{ company.company }} para reservar ou
                contratar Serviços Turísticos para Você e/ou para outra pessoa
                em nome da qual Você tenha autorização para atuar; (iv) no caso
                de adquirir serviços com destino/escala em Cuba, não é cidadão
                nem residente dos Estados Unidos da América e nem está sujeito à
                jurisdição dos Estados Unidos; e (vi) toda informação que Você
                fornece à {{ company.branch }} é verídica, exata, atual e
                completa.
            </li>
            <li>
                Estas condições são as de caráter geral que são aplicadas aos
                serviços de intermediação prestados pela {{ company.branch }} e,
                sob nenhum aspecto, substituem ou alteram as limitações de
                responsabilidade legalmente estabelecidas, nem as condições
                especiais que cada Fornecedor Turístico tenha definido para seus
                Serviços Turísticos. Antes de contratar, revise as condições
                particulares de cada Serviço Turístico e de cada Fornecedor. A
                {{ company.branch }}, como mera intermediária, não tem
                ingerência nas condições particulares estabelecidas por cada
                Fornecedor para a prestação dos seus Serviços Turísticos e,
                consequentemente, não pode alterá-las ou arcar com qualquer
                responsabilidade delas decorrentes, observando-se o disposto no
                item V, abaixo.
            </li>
            <li>
                A {{ company.branch }} faz constar que sua atividade de
                intermediação na comercialização dos Serviços Turísticos não
                garante que o destino escolhido não apresente riscos para a
                saúde e integridade física das pessoas. A
                {{ company.branch }} não é responsável direta ou indiretamente
                pelas perdas e danos que por qualquer modo Você ou aqueles que
                viajam com Você possam sofrer antes, durante ou como
                consequência do destino escolhido. A {{ company.branch }} não se
                responsabiliza por eventos relacionados com caso fortuito, força
                maior, fato do príncipe e condutas intrínsecas praticadas por
                Você ou aqueles que viajam com Você, que possam acontecer antes
                ou durante a Prestação do Serviço Turístico, e que possam
                eventualmente atrasar, interromper ou impedir a execução do
                mesmo.
            </li>
            <li>
                Antes de confirmar a sua solicitação de compra, clicando no
                botão Comprar e mediante prévia aceitação destas Condições
                Gerais (o “Pedido de Compra”), é de suma importância que Você
                tenha compreendido os termos e condições particulares aplicáveis
                ao Serviço Turístico que deseja contratar, que são estabelecidos
                pelo respectivo Fornecedor e devidamente informados na página de
                apresentação de tal serviço, razão pela qual recomenda-se a sua
                leitura, para que haja contratação apenas no caso de seu aceite.
                Assim evitará surpresas e poderá desfrutar de sua viagem como
                planejou. As políticas de cada Serviço Turístico são
                estabelecidas para cada Fornecedor sem ingerência da
                {{ company.company }}. Esclarece-se especialmente que a
                {{ company.branch }} não tem ingerência alguma na cobrança de
                penalidades por parte dos Fornecedores ou diferenças de tarifas,
                caso sejam aplicáveis, o que dependerá das políticas de cada
                Fornecedor. A {{ company.company }}, ainda, não se
                responsabiliza por danos causados em decorrência de qualquer
                interrupção, suspensão, descontinuidade ou indisponibilidade dos
                serviços por qualquer razão.
            </li>
            <li>
                Caso não concorde com estes termos, você não poderá acessar ou
                utilizar de qualquer forma a plataforma da {{ company.branch }}.
                O simples uso da Plataforma, entretanto, implica na aceitação de
                todas e quaisquer condições gerais e particulares incluídas
                nestes termos e condições, ressalvadas as questões específicas
                relativas ao uso que damos aos seus dados pessoais que
                demandarão sua prévia aceitação da nossa Políticas de
                Privacidade. Sua leitura lhe permitirá exercer da melhor maneira
                seus direitos como consumidor. Sugerimos imprimi-las e
                guardá-las junto com o restante da documentação de sua viagem.
                Lembre-se de tomar nota do número do Pedido de Compra que
                aparecerá na tela ao confirmar seu pedido. Este número
                identifica sua transação e será indispensável para a realização
                de qualquer gestão através da {{ company.branch }}.
            </li>
            <li>
                A {{ company.branch }} se reserva no direito de cancelar,
                suspender, desativar, bloquear ou, encerrar unilateralmente o
                seu acesso á Plataforma, com rescisão unilateral dos Termos,
                caso: (i) seja identificada qualquer violação as normas
                jurídicas (ii) suas ações violem as disposições destes Termos;
                (iii) se você causar algum dano direto ou indireto à
                {{ company.branch }}, seus usuários ou a terceiros; ou (vi) em
                outros casos onde a {{ company.branch }}, a seu exclusivo
                critério, entenda necessário.
            </li>
            <li>
                As operações de uso, processamento, compartilhamento e demais
                atividades realizadas com seus Dados Pessoais serão realizadas
                em conformidade com a legislação de proteção de dados aplicável,
                em especial a Lei Geral de Proteção de Dados Pessoais (“LGPD”),
                e com a nossa Política de Privacidade (que você poderá acessar
                neste link https://www.{{
                    company.domain
                }}/legal/politicas-de-privacidade). Ressaltamos desde já que
                estas atividades serão realizadas exclusivamente para propósitos
                legítimos, explícitos e previamente informados a você em nossa
                Política de Privacidade, sempre limitadas ao uso do volume
                mínimo necessário de dados para o cumprimento destas finalidades
                e permitidos com base nas autorizações aplicáveis previstas na
                LGPD.
            </li>
            <li>
                Ressaltamos que todas as informações e conteúdo que estão
                disponíveis através da Plataforma da {{ company.branch }} estão
                protegidos por leis de direitos autorais e não devem ser
                utilizadas de outra forma que não a estabelecida nestes Termos
                ou sem a autorização expressa, por escrito, da
                {{ company.branch }} e de qualquer outra parte que a
                {{ company.branch }} julgar necessária. Imagens de pessoas ou
                lugares, exibidas na Plataforma, são de propriedade da
                {{ company.branch }} e fica proibido o uso dessas imagens por
                você ou por terceiros. Qualquer uso não autorizado dessas
                imagens poderá violar leis de direitos autorais, de marcas
                comerciais, de publicidade e outras regulamentações aplicáveis,
                podendo resultar em penalidades civis e/ou criminais.
            </li>
            <li>
                Os presentes Termos e Condições de Uso podem ser alterados a
                qualquer momento, através da publicação de versões atualizadas.
                No caso de mudanças relevantes, você será informado a respeito e
                terá a oportunidade de aceitá-las ou não.
            </li>
        </ul>
        <p>
            <strong
                >SE VOCÊ CONTRATAR SERVIÇOS TURÍSTICOS ATRAVÉS DO
                {{ company.company }}, LEVE EM CONSIDERAÇÃO A SEGUINTE
                INFORMAÇÃO:</strong
            >
        </p>
        <ol>
            <li>
                <strong> COMO FUNCIONA O PROCESSO DE RESERVA E COMPRA?</strong>
                Quando Você envia um Pedido de Compra através da Plataforma da
                {{ company.branch }}, Você nos autoriza a gerenciar sua reserva
                e a cobrar o(s) respectivo(s) encargo(s) na sua conta ou no seu
                cartão. Preste muita atenção às nossas mensagens posteriores,
                nas quais iremos lhe informando sobre as etapas e situação do
                seu Pedido de Compra. A conclusão da gestão da reserva e/ou da
                confirmação da compra dependem do pagamento integral e oportuno
                do preço e/ou tarifa de todos os Serviços Turísticos. Enquanto
                não houver confirmação do pagamento total da reserva, o Pedido
                de Compra ficará suspenso e poderá ser cancelado pelo
                Fornecedor. A confirmação definitiva dos Serviços Turísticos e
                dos respectivos preços será realizada mediante a emissão do(s)
                ticket(s) eletrônico(s) ou com o envio do voucher, o que for
                aplicável, e sua fatura. Além disso, nas operações de crédito,
                deverão ser preenchidos os requisitos próprios fixados pelo seu
                banco emissor. A emissão do ticket eletrônico ou voucher, o que
                for aplicável, confirma expressa e inequivocamente a aceitação
                das condições do Fornecedor e da celebração do contrato assinado
                entre o Fornecedor e a pessoa cujo(s) nome(s) aparece(m) no
                referido ticket eletrônico e/ou voucher.
            </li>
        </ol>
        <p>
            Se o Pedido de Compra ficar sujeito a confirmação, circunstância que
            lhe será informada no primeiro correio eletrônico que receber, os
            valores pagos antes da confirmação serão recebidos a título de
            reserva. Se, por fim, seu Pedido de Compra for rejeitado por
            qualquer motivo (por exemplo, por saldo insuficiente no seu cartão
            de crédito, por falta de disponibilidade, por variação na tarifa
            etc.) a {{ company.branch }} lhe informará sobre as opções
            disponíveis para o caso de Você desejar insistir na compra ou na
            reserva ou, a seu pedido, lhe devolverá os valores pagos.
        </p>
        <p>
            Se, após a entrada do seu Pedido de Compras, não receber um e-mail
            com a informação de compra, revise a pasta de Spam e/ou entre em
            “Minha Conta/Minha Reserva ou em Minhas Viagens” na Plataforma com o
            número do Pedido de Compra. Ali, Você poderá realizar gestões e, no
            caso de dúvidas adicionais, poderá ligar para os números de
            telefones que constam na Plataforma.
        </p>
        <p>
            <strong>QUAIS DADOS PESSOAIS TENHO QUE FORNECER?</strong> Você
            deverá preencher todos os campos com dados exatos para poder
            realizar reservas válidas de Serviços Turísticos através da
            {{ company.company }}. Ao entrar com seus dados pessoais na
            Plataforma, Você declara que os referidos dados (os “Dados
            Pessoais”) são exatos, precisos e verdadeiros, e assume o
            compromisso de atualizar os referidos Dados Pessoais conforme for
            necessário. A {{ company.branch }}
            não se responsabiliza pela certificação dos Dados Pessoais
            fornecidos por Você. Você garante e se responsabiliza pela
            veracidade, exatidão, vigência e autenticidade dos Dados Pessoais
            inseridos. Lembre-se que todos os seus dados deverão coincidir de
            forma exata com a Documentação de viagem, conforme definido no item
            VI).
        </p>
        <p>
            <strong>III. QUAIS DADOS DE CONTATO SÃO NECESSÁRIOS?</strong>
            Você deverá inserir um endereço de e-mail no item “Dados de
            Contato”. Este e-mail será nossa via de contato com Você. Verifique
            se o e-mail fornecido como ponto de contato está correto e
            mantenha-se atento às informações que serão enviadas pelo referido
            correio. Considerar-se-á que Você tomou conhecimento oportuno da
            confirmação de seu Pedido de Compra ou de algum inconveniente ou
            alteração do mesmo mediante envio da referida informação ao endereço
            de e-mail fornecido por Você. Leve em conta que se o endereço de e-
            mail fornecido por Você contiver algum erro, você deixará de receber
            comunicações fundamentais para o gerenciamento do seu Pedido de
            Compra. De qualquer forma, Você poderá chamar ou entrar em “Minha
            Conta” (ou através de “Minha Reserva e/ou Minhas Viagens”) e
            realizar o acompanhamento e/ou gerenciamento de todos os seus
            Pedidos de Compra a partir dali.
        </p>
        <ol>
            <li>
                <strong> QUAIS DADOS DE FATURAMENTO SÃO NECESSÁRIOS?</strong>
                Você deverá inserir seus dados pessoais e fiscais com exatidão
                no item “Dados de Faturamento” para poder emitir o comprovante
                fiscal correspondente. Você declara que os referidos dados são
                exatos, precisos e verdadeiros. A {{ company.branch }} não será
                responsável pela emissão de documentos com informação incorreta
                fornecida por Você. Você garante e responde pela veracidade,
                exatidão, vigência e autenticidade dos dados fiscais que
                fornecer. A atualização dos Dados Pessoais mencionados no item
                2), não afetará os dados fiscais de faturamento fornecidos por
                Você e os refletidos no comprovante de venda, o cliente poderá
                solicitar através do “Meu {{ company.branch }}” a correção da
                fatura dentro dos primeiros 30 dias contados a partir da data da
                emissão do comprovante. Poderá receber uma fatura emitida por
                qualquer uma das empresas mencionadas no cabeçalho do presente e
                definidas como {{ company.branch }} ou {{ company.branch }}.
            </li>
        </ol>
        <p>
            <strong
                >QUAIS INFORMAÇÕES POSSO ENCONTRAR NA PLATAFORMA DA
                {{ company.company }}?</strong
            >
            As informações publicadas na Plataforma da
            {{ company.branch }} relativas aos preços, qualidades e
            características dos Serviços Turísticos, sua disponibilidade,
            condições de venda, restrições, políticas de cancelamento ou de
            reembolso (entre outras) são estabelecidas e oferecidas diretamente
            pelos Fornecedores dos Serviços Turísticos “on-line” e em tempo real
            e sem qualquer ingerência (direta ou indireta) da
            {{ company.branch }}. Na sua qualidade de intermediária, a
            {{ company.branch }} lhe informa sobre as características dos
            Serviços Turísticos reproduzindo exatamente as informações prestadas
            pelos Fornecedores e apenas gerencia seus Pedidos de Compra com os
            Fornecedores, faz a cobrança, quando aplicável, dos valores
            correspondentes às tarifas e presta suporte na busca de soluções no
            caso de surgirem preocupações inconvenientes. A
            {{ company.branch }} não é nem deve ser interpretada como um
            promotor da qualidade, do nível do serviço e/ou da qualificação ou
            classificação de qualquer Serviço Turístico disponível na
            Plataforma. Aconselhamos ler os comentários de outros passageiros
            que constam da Plataforma para assessorar-se sobre o(s) Serviço(s)
            Turístico(s) escolhido(s) e avaliar se este(s) se enquadra(m) às
            suas necessidades e expectativas. A Plataforma pode conter links e
            pontos de direção a outros sites da Internet, recursos e promoções
            da Plataforma. Os links da Plataforma (em qualquer direção) a outros
            sites de terceiros, mantidos por terceiros, não constituem uma
            recomendação por parte da {{ company.company }} de qualquer
            terceiro, seus sites na web ou o conteúdo destes. Da mesma maneira,
            a {{ company.branch }} pode oferecer ferramentas para permitir a
            interação entre a Plataforma e um site de terceiros, como por
            exemplo, uma Rede Social. A {{ company.branch }} não é responsável
            de maneira alguma pelos sites ou recursos de terceiros, e sua
            utilização destes sites e recursos não será regida por estes termos
            e condições. A {{ company.branch }} poderá, a qualquer momento e a
            seu exclusivo critério, obter acesso ou utilizar qualquer perfil ou
            outra informação sobre Você que tenha sido fornecida pelo Facebook
            e/ou por qualquer outra rede social ou site de terceiros (“Rede
            Social”). Nesse caso, Você reconhece e aceita que: (i)
            {{ company.company }} poderá acessar e divulgar na Plataforma de
            Informação o conteúdo que faça parte do seu perfil público dentro da
            Rede Social; (ii) O conteúdo do perfil público proveniente da Rede
            Social poderá ser considerado como gerado por Você na Plataforma; e
            (iii) O perfil público continuará sendo regido pelos termos e
            condições e pela Política de Privacidade da Rede Social de origem.
        </p>
        <ol>
            <li>
                <strong> QUAIS DOCUMENTOS PRECISO PARA VIAJAR?</strong> Você tem
                o dever de informar-se de forma responsável sobre a documentação
                legal e/ou migratória e/ou as exigências sanitárias que podem
                ser necessárias para Você ou para as pessoas que viajam com Você
                para poder realizar sua viagem conforme o planejado (a
                “Documentação de Viagem”). Verifique com tempo hábil as
                condições de entrada no País de destino e/ou trânsito, condições
                especiais de vistos e/ou passaportes, licenças para dirigir,
                vacinas, autorizações para menores ou outras exigências
                documentais, tanto para os Países de destino como para os Países
                de trânsito, ainda que não seja necessário sair do aeroporto. Se
                for exigida e Você não dispuser dessa documentação, faça os
                trâmites necessários para obtê-la com antecedência. Os
                requisitos podem variar conforme o País de destino e/ou de
                trânsito e conforme seu País de nacionalidade ou de residência
                e/ou da documentação com a qual Você viaje. Lembre-se que é sua
                responsabilidade cumprir com todos os requisitos e/ou
                documentação de entrada e/ou de trânsito que forem exigidos
                pelas autoridades migratórias, aduaneiras e/ou sanitárias do(s)
                País(es) de destino(s) e/ou trânsito. Aconselhamos que antes de
                reservar e partir, consulte todos os requisitos necessários com
                a Embaixada ou o Consulado correspondente.
            </li>
            <li>
                Sem prejuízo do mencionado anteriormente, lembramos que: (i) os
                residentes dos Países membros do Mercosul poderão transladar com
                o DNI Digital (ou o Passaporte) aos Países membros do Mercosul
                (Argentina, Brasil, Bolívia, Paraguai, Uruguai e Venezuela) e
                Estados Associados (Chile, Colômbia, Equador e Peru). No caso de
                viajar a outros Países, é necessário levar o Passaporte e (ii)
                os residentes estrangeiros podem viajar às Nações sul-americanas
                mencionadas com o Documento Nacional de Identidade ou o
                Passaporte de seu País. Verifique com antecedência a data do
                vencimento da documentação e leve em consideração que, em alguns
                Países, exige-se que a data de expiração não seja inferior a
                seis meses da data da chegada ao território estrangeiro.
            </li>
            <li>
                No caso de viajar com menores de 18 anos, além do documento de
                viagem habilitado (DNI Digital ou Passaporte), são necessárias
                autorizações de acordo com as diferentes considerações: (i) Se o
                menor estiver viajando com ambos os pais, basta que se comprove
                o vínculo mediante Caderneta Civil de Família ou Certidão de
                Nascimento (sem importar a data de sua emissão) ou o DNI do
                menor, onde constem os dados de filiação, (ii) se viajar somente
                com um dos pais, além de demonstrar a relação parental com algum
                dos documentos mencionados, deverá vir acompanhado da
                autorização da mãe ou do pai ausente, sem prejuízo de obtenção
                de ordem judicial, quando aplicável e (iii) se for filho de
                menores, necessitará a autorização dos pais e a autorização
                correspondente de um dos avós. Sem prejuízo disso, revise a
                Documentação de viagem necessária conforme o tipo de transporte,
                o Fornecedor e a origem e/ou destino do transporte, uma vez que
                os requisitos poderão variar em caso de viagem por mar, terra ou
                ar e de acordo com cada Fornecedor. No caso de o(s) menor(es)
                não viajar(em) acompanhado(s) de um maior responsável, por
                favor, revise as políticas de menor desacompanhado de cada
                Fornecedor.
            </li>
        </ol>
        <p>
            <strong
                >VII. O QUE TEREI DE PAGAR? COMO FUNCIONAM OS MEIOS DE
                PAGAMENTO?</strong
            >
            Quando Você reserva um ou mais Serviços Turísticos através da
            {{ company.company }}, Você deve pagar (i) o preço ou a tarifa
            fixado pelo Fornecedor; (ii) os impostos, taxas e/ou emolumentos
            aplicáveis ao Serviço Turístico e, caso corresponda, (iii) o encargo
            de gerenciamento pelo serviço de intermediação da
            {{ company.branch }} (o “Encargo de Gerenciamento”). Lembre-se de
            que a {{ company.branch }} atua como mero intermediador e que os
            valores correspondentes ao preço ou tarifa dos Serviços Turísticos
            são transferidos (endossados) ao Fornecedor. A
            {{ company.branch }} lhe oferece distintas modalidades para poder
            efetuar e pagar suas reservas de Serviços Turísticos dependendo do
            Serviço a ser adquirido, do Fornecedor que o(s) presta e do meio
            utilizado para pagamento. Os meios e formas de pagamento disponíveis
            para cada Serviço Turístico são os informados na Plataforma de
            maneira prévia à elaboração do seu Pedido de Compra.
        </p>
        <p>
            <strong
                >VIII. O QUE ACONTECE SE EU PAGAR COM CARTÃO DE CRÉDITO?</strong
            >
            Leve em consideração que quando Você informa à
            {{ company.branch }} os dados de sua conta ou do cartão de crédito
            (mediante o formulário disponível na Plataforma) e após aceita ou
            confirmada a operação, está nos autorizando a efetuar as despesas:
            (i) pela tarifa do Serviço Turístico, (ii) pelos Impostos, taxas
            e/ou emolumentos que possam corresponder e (iii) por nossos Encargos
            de Gerenciamento correspondentes. Se Você efetuar uma reserva na
            opção parcelada e/ou mediante um cartão de crédito, leve em
            consideração que:
        </p>
        <ol>
            <li>
                O Encargo de Gerenciamento da {{ company.branch }} (que
                corresponda) poderá ser aplicado integralmente na primeira
                parcela, apesar de ter feito a opção de pagamento parcelado para
                o restante do preço ou tarifa.
            </li>
            <li>
                Os juros e/ou impostos que vierem a ser gerados pelo pagamento
                em parcelas são somados ao preço ou tarifa proposto para o
                serviço e o referido total é dividido pela quantidade de
                parcelas selecionadas para pagamento.
            </li>
            <li>
                Se Você tiver combinado com seu emissor de cartão de crédito
                seguros de vida pelo saldo devedor ou outros encargos que afetem
                o custo financeiro total, lembre-se de consultar sua empresa
                emissora sobre a vigência, condições e custos de tais seguros ou
                encargos, os quais, naturalmente, nada têm a ver com a
                {{ company.company }} e dependem e/ou variam conforme o que foi
                combinado por Você com cada agente emissor ou operadora de
                cartões de crédito. Recomendamos que se informe sobre as
                condições de uso e dos juros de seu cartão de crédito antes de
                utilizar da modalidade de pagamento em parcelas.
            </li>
            <li>
                Caso realize um cancelamento posterior à compra, sempre que as
                condições particulares do Serviço Turístico assim o permitam, a
                {{ company.branch }} não devolverá e nem será responsável pelos
                juros que a transação original tiver gerado, uma vez que a
                {{ company.company }} não recebe esses referidos juros.
            </li>
            <li>
                O preço a pagar poderá ser cobrado em moeda nacional ou em
                dólares, conforme se tratar de prestações locais ou
                estrangeiras. Caso seja cobrado em dólares, o lançamento será
                efetuado contra o limite internacional do seu cartão. Em todo
                caso, qualquer que seja a moeda de pagamento, a tarifa ou preço
                será aquele que lhe foi informado e aceito por Você. Lembre-se
                de que para as operações internacionais, alguns bancos e
                empresas operadoras de cartões de crédito impõem certos
                encargos, além de impostos correspondentes de acordo com a lei
                estrangeira aplicável. Adicionalmente, seu banco poderá
                converter os preços à moeda local e cobrar um valor pela
                conversão. Pode acontecer de o tipo de câmbio utilizado pelo
                Banco ser distinto do apresentado pela Plataforma, sendo que
                Você poderá perceber uma variação entre o valor cobrado pelo
                banco e o valor informado na Plataforma. O banco é quem
                determina o tipo de câmbio e a tarifa aplicada na sua reserva,
                informe-se junto ao seu banco;
            </li>
            <li>
                Cada transação está sujeita a processos de verificação
                antifraude. Um deles consiste em lançar uma despesa equivalente
                a 1 dólar norte-americano no limite em dólares de seu cartão
                como se estivesse sendo utilizado no Reino Unido, com a
                finalidade de verificar a identidade e a validade internacional
                de seu cartão. O processo de lançamento e estorno poderá ser
                visualizado no extrato de conta do cliente e não gera despesas
                nem custos adicionais para Você, uma vez confirmadas a
                identidade e a validade, o valor é devolvido e o lançamento
                anulado.
            </li>
            <li>
                Para certos destinos, o Fornecedor do Serviço Turístico poderá
                cobrar de Você diretamente pelo Serviço Turístico contratado,
                sem qualquer ingerência da {{ company.branch }}. Nestes casos, a
                {{ company.branch }} apenas lhe informará no ato da compra e
                Você receberá uma fatura emitida pelo referido Fornecedor. Leve
                em consideração que nestes casos, o pagamento será efetuado
                através do seu cartão de crédito, sendo que, para produtos
                internacionais, poderá haver a conversão de respectivo valor
                para Dólares Norte-Americanos pelo valor na referida moeda
                indicada no detalhamento de seu pagamento.
            </li>
            <li>
                <strong> TENHO DIREITO A DESISTÊNCIA?</strong> observando-se a
                legislação aplicável ao produto adquirido por Você e as regras
                dos Fornecedores, o exercício do direito de arrependimento deve
                observar o prazo de 07 (sete) dias contados a partir da
                celebração do contrato. Para exercer este direito, Você deverá
                comunicar a {{ company.branch }} tempestivamente acerca de sua
                decisão, por escrito ou mediante meios eletrônicos ou similares.
                A {{ company.branch }} realizará as gestões de apresentação e
                acompanhamento de sua solicitação perante o Fornecedor do
                Serviço Turístico, e, no caso de o Fornecedor ter sido pago,
                será o Fornecedor do Serviço quem deverá proceder à devolução do
                seu dinheiro, mediante um crédito ou um estorno de valores no
                seu cartão de crédito. Se o pagamento tiver sido efetuado
                mediante transferência bancária, a
                {{ company.branch }} solicitará novamente seus dados para poder
                gerenciar o reembolso através desse meio. Sobre este item,
                informamos que:
            </li>
        </ol>
        <p>
            <strong
                >Artigo 49. Do Código de Defesa do Consumidor - O consumidor
                pode desistir do contrato, no prazo de 7 dias a contar de sua
                assinatura ou do ato de recebimento do produto ou serviço,
                sempre que a contratação de fornecimento de produtos e serviços
                ocorrer fora do estabelecimento comercial, especialmente por
                telefone ou a domicílio.</strong
            >
        </p>
        <p>
            Sem prejuízo do que foi explicado no parágrafo anterior, Você deve
            levar em consideração que o transporte comercial no Brasil é regido
            por um conjunto de normas, entre as quais se encontram a Resolução
            400 da Agência Nacional da Aviação Civil e a Resolução 4282 da
            Agência Nacional de Transportes Terrestres, as Normas Internacionais
            da IATA, (International Air Transport Association) (As “Normas
            Aeronáuticas”).
            <strong
                >Essas normas trazem regramento especifico sobre o Direito de
                Arrependimento, motivo pelo qual fica a cargo das companhias
                aéreas estabelecer livremente as condições de cancelamento, o
                que acontece sem a intervenção da {{ company.branch }}</strong
            >. A Resolução 400 da Agência Nacional da Aviação Civil estabelece
            que Você poderá exercer o Direito de Arrependimento desde que o faça
            no prazo de 24 (vinte e quatro) horas da realização da compra, sendo
            certo que as companhias aéreas podem determinar em suas condições de
            cancelamento que esta regra somente se aplicam para compras feitas
            com antecedência igual ou superior a 07 (sete) dias em relação á
            data do embarque. Por isso, consulte as condições estabelecidas pela
            companhia aérea antes da aquisição de seu bilhete aéreo.
        </p>
        <ol>
            <li>
                <strong>
                    UMA VEZ CONFIRMADO UM PEDIDO DE COMPRA, POSSO ALTERÁ-LO OU
                    CANCELÁ-LO? SE O FIZER, TEREI CUSTOS ASSOCIADOS?</strong
                >
                Se Você solicitar uma alteração ou cancelamento fora dos prazos
                e procedimentos explicados no item anterior, e a regulamentação
                específica da tarifa por Você selecionada assim o permitir, a
                {{ company.branch }} realizará as gestões de apresentação e
                acompanhamento de sua solicitação, mas, tanto os prazos de
                resposta como a decisão final, serão do Fornecedor do Serviço
                Turístico. Leve em consideração que se a data da execução do
                serviço estiver próxima, a {{ company.branch }} não pode
                garantir que o Fornecedor do Serviço Turístico emitirá uma
                solução da questão. A maneira de proceder e as condições
                especiais vigentes para esses casos de trocas ou cancelamentos
                em uma reserva confirmada por Você são determinadas pelos
                Fornecedores dos Serviços Turísticos, que poderão estabelecer
                multas ou penalidades aplicáveis para as alterações solicitadas.
                Estas condições estão disponíveis para sua consulta em nossa
                Plataforma. Revise-as antes de reservar e lembre-se de que
                existem Serviços Turísticos nos quais, por disposição dos
                Fornecedores, não se admitem alterações e/ou devoluções, ou se
                admitem mediante restrições e/ou penalidades, inclusive em casos
                de doença, greves, fenômenos climáticos etc. – Lembre-se de que
                cada Fornecedor define suas penalidades sem intervenção ou
                ingerência da {{ company.branch }}.
            </li>
            <li>
                As gestões nesta plataforma da {{ company.branch }} são
                realizadas “on-line” e em tempo real. Quando Você solicita uma
                alteração, está cancelando uma reserva confirmada e solicitando
                outra nova que será regida pelas condições particulares
                aplicáveis no momento em que Você realizar a alteração.
                Portanto, além das penalidades aplicáveis, poderá existir uma
                diferença de tarifa que consiste em um valor que resulta da
                diferença da tarifa originalmente paga e a nova tarifa, conforme
                a disponibilidade tarifária no momento de realização da troca (a
                “Diferença Tarifária”). Lembre-se de que as tarifas turísticas
                são dinâmicas e dependem de variáveis, como antecedência de
                compra, datas de viagem, promoções de estação e, no caso de
                voos, também das restrições tarifárias (quanto maiores as
                restrições, maior o preço).
            </li>
            <li>
                No caso de devoluções aprovadas pelo Fornecedor, estas poderão
                ser efetuadas mediante um crédito ou estorno de valores no mesmo
                cartão com o qual foi feita a reserva, mediante prévia
                autorização do(a) titular do cartão de crédito. Se o pagamento
                foi realizado à vista, em dinheiro, e/ou transferência bancária,
                a {{ company.branch }} solicitará seus dados bancários para
                realizar o reembolso mediante transferência bancária. Os prazos
                de reembolso e/ou do estorno de valores dependerão de cada
                Fornecedor (nos casos das Companhias Aéreas estima-se
                habitualmente de 30 a 120 dias).
            </li>
            <li>
                Cabe esclarecer que os Fornecedores não são obrigados a realizar
                exceções das condições do contrato aos passageiros, mesmo nos
                casos comprovados de doença ou incapacidade física que lhes
                impeça de viajar. Ou seja, se a tarifa ofertada previr a
                cobrança de penalidade para realizar alterações/devoluções ou
                não permitir alterações e/ou devoluções, o Fornecedor poderá
                cobrar esses valores ou negar a devolução do custo da reserva.
            </li>
            <li>
                O Valor de Gerenciamento cobrado pela {{ company.branch }} é de
                caráter não reembolsável. No caso de cancelamento de reservas
                confirmadas por qualquer motivo, o referido valor de
                Gerenciamento não será devolvido, já que corresponde a um
                serviço efetivamente prestado pela {{ company.branch }}. Da
                mesma forma, na hipótese de Você solicitar uma alteração ou
                cancelamento de um Serviço Turístico, a
                {{ company.branch }} poderá cobrar um Valor de Gerenciamento
                adicional.
            </li>
            <li>
                <strong>
                    O QUE ACONTECE SE NÃO ME APRESENTAR PARA UTILIZAR UMA
                    RESERVA?</strong
                >
                Se Você não se apresentar para utilizar sua reserva nas datas e
                horários indicados (ou chegar com atraso) configura-se o que no
                mercado turístico conhece como “No Show” e Você não terá o
                direito de exigir a devolução total ou parcial do valor pago. Se
                não for utilizar sua reserva no todo ou em parte ou se for
                chegar com atraso para utilizar a sua reserva, recomendamos
                comunicar-se previamente com a {{ company.branch }} para
                conhecer as restrições aplicáveis.
            </li>
        </ol>
        <p>
            Sem prejuízo do que foi explicado no parágrafo anterior, Você deve
            levar em consideração que o transporte comercial no Brasil é regido
            por um conjunto de normas entre as quais se encontram a Resolução
            400 da Agência Nacional da Aviação Civil e a Resolução 4282 da
            Agência Nacional de Transportes Terrestres. Essas normas trazem
            regramento especifico sobre o “No Show”, motivo pelo qual fica a
            cargo das companhias aéreas estabelecer livremente as condições de
            cancelamento, o que acontece sem a intervenção da
            {{ company.branch }}.
        </p>
        <p>
            <strong
                >XII. CONDIÇÕES ESPECIAIS A PRODUTOS TURÍSTICOS COM DESTINO A
                CUBA: </strong
            >Conforme as regulamentações vigentes nos Estados Unidos, as viagens
            a Cuba para atividades turísticas são proibidas para cidadãos,
            residentes ou pessoas sujeitas à jurisdição norte-americana. Esta
            regra tem algumas exceções (como por exemplo: visitas familiares;
            trabalho oficial para o Governo dos Estados Unidos, Governos
            Estrangeiros e algumas organizações não governamentais; atividade
            jornalística; pesquisa e reuniões profissionais etc.). Atualmente a
            {{ company.branch }} não conta com os recursos para poder verificar
            estas exceções durante o processo de compra “on-line”. Portanto,
            momentaneamente não temos condições de oferecer este destino aos
            cidadãos compreendidos nas referidas exceções. Mais informações no
            site: h
            ttps://br.usembassy.gov/pt/comunicado-sobre-politica-para-cuba.
        </p>
        <p>
            <strong
                >XIII. QUAL É O PROCEDIMENTO NO CASO DE CONSULTAS OU
                RECLAMAÇÕES?</strong
            >
            Caso Você precise efetuar uma reclamação ou consulta, solicitar
            alterações e/ou cancelamentos – sempre que sua tarifa assim o
            permitir – solicitar o reenvio de vouchers ou tickets e/ou fazer o
            download de sua fatura, exercer seus direitos sobre seus Dados
            Pessoais (dispostos na nossa Política de Privacidade), Você poderá
            acessar durante as 24 horas do dia o link “Minha Conta” se estiver
            registrado na Plataforma (ou através de “Minha Reserva” ou “Minhas
            Viagens”) com o seu número do Pedido de Compra, onde poderá realizar
            qualquer gestão. Lembre-se de que a {{ company.branch }} é uma
            agência de viagens “on-line” e, como tal, sua principal via de
            contato é a sua Plataforma “on-line”. Independente disso, para
            qualquer urgência, sempre poderá utilizar as linhas telefônicas que
            constam nos e-mails ou vouchers enviados com cada Serviço Turístico.
        </p>
        <ol>
            <li>
                Caso queira realizar uma reclamação, sugestão ou consulta,
                recomenda-se realizar uma descrição detalhada dos fatos,
                juntando imediatamente uma cópia dos comprovantes do alegado,
                explicando o ocorrido e formulando em termos claros e simples a
                solução desejada. Desta maneira poderemos revisar seu caso de
                forma mais rápida e eficiente. A {{ company.branch }} analisará
                a reclamação e dará resposta através do mesmo endereço de e-mail
                informado por Você o quanto antes. Se, da revisão dos
                comprovantes resultar o acolhimento total ou parcial da sua
                reclamação, a {{ company.branch }} o notificará e lhe enviará
                por e-mail o acordo correspondente. O referido acima sem
                prejuízo de que a {{ company.branch }} poderá lhe oferecer uma
                solução alternativa, diferente dos pedidos formulados por Você.
            </li>
            <li>
                Quanto aos seus direitos sobre seus Dados Pessoais, você poderá
                ainda enviar uma solicitação para a {{ company.branch }} através
                do e-mail grupoviajar@viajaroperadora.com.br, conforme previsto
                na Política de Privacidade.
            </li>
            <li>
                Se Você estiver de acordo com a proposta da
                {{ company.branch }}, deverá assinar o termo de acordo
                correspondente e enviá-lo à {{ company.branch }} no endereço
                indicado. Uma vez que a {{ company.company }} tenha recebido o
                documento, um agente de atendimento ao cliente entrará em
                contato com Você para lhe informar a data do pagamento e as
                demais condições do cumprimento do acordo.
            </li>
            <li>
                As alterações ou cancelamentos de reservas efetuados por nosso
                intermédio, ainda que sejam regidos pelos Fornecedores dos
                Serviços Turísticos, devem ser gerenciados através da
                {{ company.company }}. Em qualquer um dos casos anteriores, a
                companhia aérea e/ou o fornecedor o direcionarão à nossa
                empresa.
            </li>
        </ol>
        <p>
            Tenha muito claro que cada Fornecedor tem os seus próprios
            regulamentos para a prestação de seus Serviços Turísticos. Quando
            for revisar esses regulamentos, sem prejuízo dos demais temas de seu
            interesse, preste atenção aos seguintes pontos:
        </p>
        <ul>
            <li>C APÍTULO 1 – VOOS</li>
            <li>C APÍTULO 2 – ACOMODAÇÕES</li>
            <li>C APÍTULO 3 – CRUZEIROS</li>
            <li>C APÍTULO 4 – AUTOMÓVEIS</li>
            <li>C APÍTULO 5 – ATIVIDADES</li>
            <li>CAPÍTULO 6 – PACOTES</li>
        </ul>
        <p><strong>CAPÍTULO 1 – VOOS</strong></p>
        <p>
            O transporte aéreo encontra-se regulamentado pelas Regras da
            Aeronáutica. Por sua vez, cada companhia aérea (a “Companhia Aérea”)
            estabelece suas próprias regulamentações tarifárias para a
            comercialização de passagens aéreas (a(s) Passagem(ns) Aérea(s)).
        </p>
        <p>
            <strong
                >0.1. RESERVAS DE VOOS, TARIFAS: A
                {{ company.branch }} é</strong
            >
            uma agência credenciada pela IATA e está apenas autorizada a
            comercializar Passagens Aéreas na qualidade de intermediadora entre
            as Companhias Aéreas membro da IATA e em cumprimento de seus manuais
            de tarifas, condições de transporte e de suas instruções escritas.
            Quando Você entra com um Pedido de Compra na Plataforma e este é
            confirmado mediante a emissão de um e-ticket, é gerado
            automaticamente e sem intervenção manual alguma, um registro
            (Electronic Ticket Record ou ETR) no sistema da Companhia Aérea com
            os dados fornecidos por Você na Plataforma. A aquisição da Passagem
            Aérea obedece a regulamentação estabelecida pela Companhia Aérea, a
            qual é a única responsável por informar os respectivos Termos e
            Condições para tal aquisição, inclusive quanto às condições
            especiais e promocionais, cite-se: imposição de certas limitações
            sobre a utilização da Passagem Aérea, não ser reembolsável, não ser
            transferível, restrições sobre alterações de rota, imposição de
            estadias mínimas e máximas, restrições para a devolução do valor
            pago etc. Importante destacar que as tarifas classificadas como
            econômica são as que apresentam maiores restrições. Leve em conta
            que a {{ company.branch }} não está autorizada a alterar ou mudar em
            nenhum caso as regulamentações das Passagens Aéreas.
        </p>
        <p>
            <strong>0.2 INFORMAÇÃO DE VOOS:</strong> Toda informação exibida na
            Plataforma referente às Passagens Aéreas oferecidas (itinerários,
            tarifas etc.) é proporcionada e atualizada “on-line” e em tempo real
            diretamente por cada Companhia Aérea através de um sistema global de
            reservas (Global Distribuition System ou GDS). As Companhias Aéreas,
            através dos GDSs, são as responsáveis exclusivas pela atualização
            das tarifas, da disponibilidade e outros dados que aparecem na
            Plataforma. Por este motivo, no período entre a entrada do Pedido de
            Compra e a confirmação do pagamento ou emissão da Passagem Aérea, as
            tarifas estabelecidas pela Companhia Aérea poderão variar conforme a
            disponibilidade. Os preços definitivos ficarão fixados a partir do
            momento da confirmação definitiva da reserva que é produzida com a
            emissão do(s) ticket(s) eletrônico(s) e seu faturamento. A
            {{ company.company }} não fixa tarifas aéreas e nem tem controle
            sobre a disponibilidade das mesmas. 0.3 MODALIDADES DE PAGAMENTO: Se
            Você paga com cartão de crédito, tanto a tarifa como os impostos e
            taxas são cobrados diretamente com o próprio código de comércio da
            Companhia Aérea sem que os referidos valores sejam pagos à
            {{ company.company }}. Se Você pagar mediante depósito ou via
            transferência bancária, a {{ company.branch }} endossa à Companhia
            Aérea os valores correspondentes às tarifas. Em todos os casos, a
            {{ company.branch }}
            somente recebe os valores correspondentes aos valores de
            Gerenciamento.
        </p>
        <p>
            <strong
                >0.4 REPROGRAMAÇÕES, ATRASOS E/OU CANCELAMENTOS DE VOOS</strong
            >: Em cumprimento ao artigo 12 da Resolução 400 da ANAC, as
            Companhias Aéreas poderão alterar as condições de voos, como
            horários, datas ou itinerários; poderão atrasá-los e/ou
            reprogramá-los e/ou cancelá-los. O transportador está habilitado a
            realizar as alterações nos voos programados, desde que comunique a
            esta autoridade de comércio aéreo conforme a regulamentação
            aplicável, e a {{ company.branch }} não tem nenhuma ingerência nem
            intervenção em relação às alterações que poderiam sofrer os voos. Se
            alguma dessas variações ocorrerem em até 72 horas antes da saída do
            voo, por instruções do fornecedor final, a {{ company.branch }} lhe
            informará sobre as alternativas disponíveis e somente procederá à
            confirmação das alterações com sua expressa e prévia aceitação e
            confirmação. Qualquer reclamação, demanda ou denúncia pela prestação
            do serviço escolhido deverá ser dirigida à Companhia Aérea.
            Lembre-se de que são as Companhias Aéreas as que prestam o serviço
            de transporte. Nas 72 horas prévias à saída de seu voo, a
            {{ company.company }} não pode realizar gestões sobre as Passagens
            Aéreas.
        </p>
        <p>
            <strong>0.5 APRESENTAÇÃO NO AEROPORTO: </strong>Você deverá estar
            presente nos balcões de despacho da Companhia Aérea no aeroporto de
            partida no dia previsto e no horário indicado com a Documentação de
            Viagem. Para voos domésticos, aconselhamos apresentar-se no
            aeroporto com duas horas de antecedência e para voos internacionais,
            a antecedência deverá ser de, no mínimo, três horas. Leve em
            consideração que os horários constantes na sua Passagem Aérea estão
            expressos em horário local do País de escala e/ou de destino. Se
            Você não se apresentar para embarcar ou chegar com atraso para
            embarcar (No Show), a Companhia Aérea poderá cancelar qualquer
            reserva para a continuação da viagem ou de retorno e Você não terá o
            direito de exigir a devolução total ou parcial dos valores pagos.
            Caso Você não utilize o trecho inicial nas passagens do tipo ida e
            volta, a Companhia Aérea poderá cancelar o trecho de volta, exceto
            se Você informar, até o horário originalmente contratado para o
            trecho de ida do voo doméstico, que deseja utilizar o trecho de
            volta, sendo vedada a cobrança de multa contratual para essa
            finalidade.
        </p>
        <p>
            <strong
                >0.6 PEDIDO DE CANCELAMENTO OU ALTERAÇÃO DE DATA OU
                ITINERÁRIO:</strong
            >
            Destacamos que usualmente as Companhias Aéreas determinam que as
            tarifas mais econômicas sejam aquelas que não permitem alterações,
            cancelamentos e nem devoluções e, ainda que sejam permitidos, as
            referidas alterações ou cancelamentos ficarão sujeitos a
            penalidades. Caso – de acordo com a regulamentação apresentada pela
            Companhia Aérea – a tarifa selecionada por Você permita
            cancelamentos, a {{ company.branch }} realizará gestões de
            apresentação e acompanhamento de seu pedido de cancelamento perante
            a Companhia Aérea, e será esta que deverá proceder à devolução,
            mediante um crédito ou um estorno de pagamento no seu cartão de
            crédito. Se o pagamento tiver sido efetuado mediante transferência
            bancária, a {{ company.branch }} solicitará novamente seus dados
            para poder gerenciar o reembolso por este meio. Se sua solicitação
            for uma alteração de data ou de itinerário e a tarifa da Passagem
            Aérea permitir alterações, a Companhia Aérea poderá estabelecer uma
            penalidade a ser paga e, também, ao fixar uma nova data ou escolher
            um novo itinerário, Você pagará a Diferença de Tarifa aplicável no
            momento da alteração. As alterações deverão ser realizadas nas
            mesmas classes emitidas ou então em classes superiores. Esclarecemos
            que não é possível realizar alterações para tarifas mais econômicas
            publicadas posteriormente à emissão da Passagem Aérea adquirida. O
            pagamento da penalidade e da Diferença de Tarifa poderá ser
            realizado unicamente com cartão de crédito em 1 (um) único
            pagamento. Também, tanto no caso de alterações como no de
            cancelamentos, a {{ company.branch }} poderá cobrar um valor de
            Gerenciamento adicional.
        </p>
        <p>
            <strong
                >0.7 ALTERAÇÕES NO(S) NOME(S) OU ALTERAÇÃO DE
                PASSAGEIRO:</strong
            >
            A Passagem Aérea emitida em nome do passageiro é nominal, pessoal e
            intransferível. Preste muita atenção ao inserir seu(s) nome(s) e
            sobrenome(s) no(s) lugar(es) indicado(s) para isso e que tudo seja
            conforme sua Documentação de Viagem, especialmente o Passaporte.
            Você tem disponível um espaço para o(s) nome(s) e outro para o(s)
            sobrenome(s) e tem a possibilidade de revisar esta informação antes
            de confirmar o Pedido de Compra. Sua(s) passagem(ns) Aérea(s)
            será(ão) emitida(s) automaticamente com a informação prestada por
            Você na Plataforma sem intervenção manual alguma por parte da
            {{ company.company }}. O erro no preenchimento de seu nome,
            sobrenome ou agnome deverá ser corrigido pela Companhia Aérea, sem
            qualquer ônus para Você. No entanto, lhe caberá tal solicitação de
            correção até o momento do check-in. No caso de voo internacional,
            que envolva operadores diferentes (interline), os custos da correção
            podem lhe ser repassados. A correção do nome não altera o caráter
            pessoal e intransferível da passagem aérea. A
            {{ company.branch }} não se responsabiliza por erros e/ou
            inconsistências nos Dados Pessoais que Você utilizar na Plataforma.
        </p>
        <p>
            <strong>0.8 MENORES DESACOMPANHADOS:</strong> Será considerado
            “menor desacompanhado” aquele que viajar sem ao menos um dos pais ou
            responsáveis legais. As Companhias Aéreas podem oferecer serviços de
            assistência e acompanhamento (com custo) que, conforme o caso,
            poderão ser obrigatórios ou opcionais para aqueles menores que
            viagem sem acompanhante. Entretanto, a {{ company.branch }} não
            oferece este tipo de serviço e não poderá assegurar o efetivo
            embarque do menor no caso de essa exigência ser obrigatória.
        </p>
        <p>
            Os menores entre 2 e 5 anos, deverão viajar obrigatoriamente
            acompanhados pelos seus pais ou responsáveis a seu encargo.
            Normalmente, o serviço de “menor desacompanhado” é obrigatório para
            crianças entre 5 e 11 anos e opcional para crianças entre 12 e 17
            anos. O serviço não está disponível para crianças que requeiram
            certificação médica para viajar e/ou que não possam alimentar-se
            sozinhas e/ou que não sejam capazes de cumprir com suas necessidades
            básicas de higiene e não está disponível para itinerários com
            escalas ou alterações de avião. Consulte a política específica da
            Companhia Aérea pela qual deseja viajar.
        </p>
        <p>
            <strong>0.9 BAGAGEM:</strong> Todas as Companhias Aéreas têm
            diferentes políticas de franquia de bagagem. Informe-se antes de
            viajar. O transporte de bagagem despachada configurará contrato
            acessório oferecido pela Companhia Aérea, sendo que a bagagem
            despachada poderá sofrer restrições, nos termos da Resolução 400 da
            ANAC e de outras normas atinentes à segurança da aviação civil. A
            Companhia Aérea deverá permitir uma franquia mínima de 10 (dez)
            quilos de bagagem de mão por passageiro de acordo com as dimensões e
            a quantidade de peças definidas pela Companhia Aérea. Considera-se
            bagagem de mão aquela transportada na cabine, sob a sua
            responsabilidade. A Companhia Aérea poderá restringir o peso e o
            conteúdo da bagagem de mão por motivo de segurança ou de capacidade
            da aeronave. A Companhia Aérea deverá informar aos usuários quais
            bagagens serão submetidas a procedimentos especiais de despacho, em
            razão de suas condições de manuseio ou de suas dimensões. As
            bagagens que não se enquadrarem nas regras estabelecidas pela
            Companhia Aérea poderão ser recusadas ou submetidas a contrato de
            transporte de carga. O transporte de carga e de animais deverá
            observar regime de contratação e procedimento de despacho próprios.
            No caso de extravio de bagagem, será devido o ressarcimento de
            eventuais despesas ao passageiro que se encontrar fora do seu
            domicílio pela Companhia Aérea. O passageiro deve observar os termos
            estipulados no contrato de transporte da Companhia Aérea com relação
            a hipóteses em que eventuais danos causados a item frágil despachado
            poderão deixar de ser indenizados pelo transportador.
        </p>
        <p>
            <strong>0.10</strong> A responsabilidade por roubo e/ou furto e/ou
            perda de bagagem e demais artigos pessoais dos passageiros é
            exclusivamente da Companhia Aérea. No caso de perda ou dano da
            bagagem, Você deve dirigir-se aos escritórios de atendimento ao
            cliente da Companhia Aérea e realizar a correspondente reclamação.
            Aconselhamos contratar um seguro-viagem para cobrir parte destes
            riscos, o qual poderá ser adquirido com a {{ company.branch }}.
            Evite levar na bagagem elementos não permitidos, como objetos
            perfurantes ou cortantes, garrafas ou recipientes que contenham mais
            do que 100 ml (de líquidos, gel ou aerossóis), excetuando-se
            medicamentos que sejam acompanhados de prescrição médica.
        </p>
        <p>
            <strong>0.11 SAÚDE E APTIDÃO PARA A VIAGEM – GRÁVIDAS:</strong>
            Se Você apresenta alguma condição de saúde particular, a Companhia
            Aérea lhe exigirá um atestado de aptidão médica que lhe permita
            realizar a viagem. O atestado de aptidão médica deve conter uma data
            de emissão não superior a 12 horas anteriores à saída do voo. Leve
            em consideração que não poderão viajar desacompanhados – nem com
            serviço de acompanhante – pessoas que não tenham condições de se
            valer por si só e/ou que não tenham capacidade de seguir instruções.
            A {{ company.branch }} não é responsável por qualquer procedimento
            inerente à coleta e envio do atestado de aptidão médica ou qualquer
            documento equivalente à Companhia Aérea, cabe a Você a adoção desse
            procedimento com a Companhia Aérea.
        </p>
        <p>
            <strong>0.12 VALIDADE DA PASSAGEM AÉREA: </strong>De acordo com as
            Normas da Aeronáutica, as Passagens Aéreas são válidas por um ano a
            contar da data de emissão. Qualquer exceção ao prazo de validade
            deverá ser solicitada e autorizada pela Companhia Aérea. A
            {{ company.company }} não pode prorrogar a validade de uma Passagem
            Aérea sob nenhuma circunstância.
        </p>
        <p>
            <strong>0.13 PASSAGENS “SÓ DE IDA”:</strong> Por disposições
            migratórias de cada País, no caso de não-residente do País de
            destino e contratar por nosso intermédio uma Passagem Aérea somente
            de ida, as autoridades migratórias poderão exigir que Você
            justifique as razões pelas quais não conta com uma Passagem Aérea de
            volta. Sugerimos consultar o Consulado ou a Embaixada correspondente
            antes de efetuar a reserva. As Companhias Aéreas também poderão
            exigir esta documentação antes de embarcar.
        </p>
        <p>
            <strong>0.14 STOP OVER:</strong> De maneira geral, as tarifas
            econômicas não permitem o Stop Over: recomenda-se consultar quais
            tarifas o permitem.
        </p>
        <p>
            <strong>0.15 VOOS OU COMPANHIAS AÉREAS LOW-COST:</strong> Leve em
            consideração que quando se reserva um voo de uma Companhia Aérea
            “low cost” a tarifa publicada não permite alterações nem
            cancelamentos, não inclui reserva de assento nem bagagem incluída
            (somente bagagem de mão), nem serviços complementares, tais como
            bebidas e/ou comidas e/ou entretenimento a bordo. Todos estes
            serviços são considerados adicionais e são oferecidos sob diferentes
            condições conforme cada Companhia Aérea e a um custo diferencial.
            Leve em consideração que as companhias low-cost geralmente operam em
            aeroportos pequenos, regionais ou secundários. Antes de confirmar
            seu Pedido de Compra, revise atentamente as condições e tarifas que
            são informadas para cada caso já que alguns dos serviços adicionais
            devem ser reservados e pagos com antecedências enquanto que outros
            poderão ser adquiridos durante o voo.
        </p>
        <p>
            <strong>0.16</strong> REGULAMENTAÇÃO E RESPONSABILIDADE NO
            TRANSPORTE AÉREO: A responsabilidade das Companhias Aéreas pelo
            transporte das pessoas e suas bagagens está regulamentada pelas
            Normas da Aeronáutica. Se Você deseja realizar uma reclamação a
            respeito do contrato de transporte aéreo celebrado entre Você e uma
            Companhia Aérea, Você poderá fazê-lo perante da Agência Nacional de
            Aviação Civil (ANAC) http://www.anac.gov.br/, órgão encarregado de
            regulamentar, fiscalizar e certificar a atividade aeronáutica
            comercial na Brasil.
        </p>
        <p><strong>CAPÍTULO 2 – HOSPEDAGENS</strong></p>
        <p>
            Cada hotel, hospedagem e/ou apartamento (a “ Hospedagem”) tem suas
            próprias regras de serviço e tarifárias.
        </p>
        <p>
            <strong>0.1 RESERVA DE HOSPEDAGEM, TARIFAS:</strong> Muitas
            Hospedagens oferecem para o mesmo quarto uma “tarifa reembolsável”
            ou uma “tarifa não reembolsável” (mais barata). Se reservar uma
            tarifa “não reembolsável” o Hospedagem poderá cobrar 100% do valor
            da primeira estadia ao finalizar a compra sem direito a reembolso
            algum, mesmo no caso em que os impedimentos para utilizar a
            hospedagem derivem de motivos de força maior. Se Você tiver dúvidas
            sobre a possibilidade de utilizar a hospedagem, recomendamos
            reservar uma “tarifa reembolsável”. As penalidades que eventualmente
            são informadas são cobradas pelo Hospedagem. A
            {{ company.branch }} não cobra penalidades nem valores de
            Gerenciamento por alterações ou cancelamentos de reservas de
            Hospedagem.
        </p>
        <p>
            <strong>0.2 INFORMAÇÃO DA HOSPEDAGEM:</strong> A informação
            específica do Hospedagem, como nome, localização, categoria,
            horários de check-in e check-out e serviços incluídos se encontra
            suficientemente detalhada na Plataforma. A
            {{ company.branch }} esclarece que toda a informação exibida na
            Plataforma é inserida e atualizada “on-line” diretamente por cada
            Hospedagem. Eles são responsáveis exclusivos pela atualização das
            tarifas, disponibilidade e outros dados inseridos na Plataforma. É
            de suma importância que você tome conhecimento dos comentários de
            outros passageiros para verificar se a Hospedagem se ajusta às suas
            preferências e necessidades. A {{ company.company }} não é e não
            deve ser interpretada como uma promotora da qualidade, do nível do
            serviço e/ou da qualificação ou classificação de qualquer
            Hospedagem.
        </p>
        <p>
            <strong>0.3 MODALIDADES DE PAGAMENTO:</strong> A
            {{ company.branch }} lhe oferece distintas modalidades para poder
            efetuar e pagar suas reservas da HOSPEGAGEM. A
            {{ company.branch }} indicará, em cada caso, as opções de pagamento
            disponíveis conforme a Hospedagem selecionado.
        </p>
        <p>
            <strong>0.3.1 PAGAMENTO À HOSPEDAGEM: </strong>Você tem a
            possibilidade de pagar sua estadia integral e diretamente à
            Hospedagem, que determinará o momento da cobrança. No caso de
            serviços “não reembolsáveis”, a Hospedagem poderá cobrar o total da
            estadia no momento de finalizar a sua compra. Você deverá informar
            na Plataforma os dados de seu cartão de crédito, que serão
            transmitidos à Hospedagem para garantir a sua reserva. A Hospedagem
            poderá cobrar toda ou parte da tarifa no seu cartão de crédito, na
            moeda local da Hospedagem ou em dólares norte-americanos no caso de
            Hospedagem no exterior.
        </p>
        <p>
            0.3.2 ADIANTAMENTO À {{ company.branch }} + PAGAMENTO À HOSPEDAGEM:
            Você também poderá pagar um adiantamento à {{ company.branch }}, em
            cujo caso deverá pagar o saldo restante diretamente à Hospedagem,
            sob as condições que este determinar para a sua cobrança.
        </p>
        <p>
            0.3.3 PRÉ-PAGAMENTO À {{ company.branch }}: Sob esta modalidade,
            Você aceita que a {{ company.branch }} cobre no seu cartão de
            crédito o valor total da reserva, que inclui impostos, taxas e
            emolumentos fiscais e/ou o valor do Gerenciamento.
        </p>
        <p>
            <strong
                >0.4 CANCELAMENTO OU EXCESSO DE RESERVAS DA HOSPEDAGEM:</strong
            >
            A {{ company.branch }} oferece Hospedagens em sua Plataforma
            conforme a disponibilidade informada pelos Fornecedores através de
            um sistema informático de reservas. Em nenhum caso a
            {{ company.branch }} oferecerá uma Hospedagem que não conceda
            disponibilidade no sistema. Caso uma Hospedagem não possa respeitar
            a sua reserva nas condições contratadas, a Hospedagem deverá
            proporcionar-lhe, ao seu próprio custo, um alojamento alternativo/a
            de qualidade igual ou superior ao reservado por Você originalmente.
            A {{ company.branch }}, em seu caráter de intermediário, envidará
            seus maiores esforços para que Você possa disfrutar de sua estadia
            sem inconvenientes.
        </p>
        <p>
            <strong>0.5 APRESENTAÇÃO NA HOSPEDAGEM:</strong> Lembre-se de que se
            Você não se apresentar na Hospedagem na data e horário
            correspondentes, a Hospedagem poderá cancelar sua reserva e
            considerá-la como NO SHOW, caso em que Você não terá o direito de
            exigir o reembolso total ou parcial do valor pago. Se por alguma
            circunstância Você for chegar fora dos horários indicados,
            comunique-se previamente com a Hospedagem e/ou com a
            {{ company.branch }}.
        </p>
        <p>
            <strong>0.6 PEDIDO DE CANCELAMENTO OU ALTERAÇÃO: A</strong>
            possibilidade de efetuar alterações e/ou cancelamentos e, no caso,
            as penalidades aplicáveis a cada circunstância estão sujeitas às
            políticas do Hospedagem que são informadas na Plataforma e no
            Voucher. A {{ company.branch }} não fixa nem cobra penalidades por
            alterações e/ou cancelamentos. Qualquer exceção às referidas
            condições deverá ser autorizada pela Hospedagem.
        </p>
        <p>
            <strong>0.7 MENORES: </strong>Se viajar com menores, tem de
            incluí-los na reserva mesmo que não ocupem a cama. Lembre-se de que
            existem Hospedagens que não aceitam menores.
        </p>
        <p>
            <strong>0.8 BAGAGEM – DEPÓSITO DE BENS DE VALOR:</strong> Se Você
            levar consigo pertences de valor superior ao que ordinariamente
            levam os passageiros, deve declarar à Hospedagem e guardá-los em
            cofres disponíveis na Hospedagem. A responsabilidade da Hospedagem é
            limitada ao valor declarado dos pertences depositados. Leve em
            consideração que se os bens forem excessivamente valiosos a
            Hospedagem poderá se negar a recebê-los.
        </p>
        <p>
            <strong>0.9 HORÁRIOS DE CHECK-IN E CHECK-OUT</strong>: Sem prejuízo
            dos horários de check-in e check-out particulares informados por
            cada Hospedagem na Plataforma, a quantidade de dias previstos de
            hospedagem é computada a partir do horário do check-in e finalizada
            no horário do check-out do dia seguinte, independentemente da hora
            de chegada e/ou de saída e da utilização completa ou fracionada do
            mesmo. Leve em consideração que os horários que constam no voucher
            estão expressos no horário local do lugar de destino.
        </p>
        <p><strong>CAPÍTULO 3 – CRUZEIROS</strong></p>
        <p>
            Não se esqueça de que cada Companhia de Cruzeiros (a “Companhia de
            Cruzeiros”) tem seus próprios regulamentos de serviços e tarifas
            para a provisão de viagens em cruzeiros (“o(s) “Cruzeiro(s)”):
        </p>
        <p>
            <strong>0.1 RESERVA DE CRUZEIROS:</strong> Se Você contratar um
            Cruzeiro, a {{ company.branch }} lhe enviará um correio confirmando
            seu Pedido de Compra e lhe informará um número de confirmação da
            Companhia de Cruzeiros. Lembre-se de que os documentos enviados pela
            {{ company.branch }} no momento de confirmação do Pedido de Compras
            não são válidos para embarcar e Você deverá obrigatoriamente
            apresentar um voucher que lhe será enviado pela
            {{ company.branch }}, conforme a Companhia de Cruzeiros escolhida,
            com uma antecedência de pelo menos 48 horas antes da data de saída
            do cruzeiro ou, que Você mesmo poderá obter, realizando o check-in
            através do link que a {{ company.company }} lhe enviará ou no site
            da Companhia de Cruzeiros. O check-in deve ser realizado com a
            antecedência que lhe será indicada em cada caso, mas que deverá ser
            de, no mínimo, 4 dias antes da data de embarque. A
            {{ company.branch }} também lhe enviará um e-mail, com as
            “Informações Úteis” da Companhia de Cruzeiros.
        </p>
        <p>
            É importante que Você leia e compreenda esta informação para evitar
            qualquer inconveniente antes ou durante a sua viagem de Cruzeiro.
        </p>
        <p>
            <strong>0.2 INFORMAÇÃO E TARIFAS</strong>: A informação sobre as
            tarifas e as características do serviço é proporcionada pela
            Companhias de Cruzeiros através de seus sistemas de informação. A
            tarifa inclui o alojamento no navio na categoria escolhida, o
            transporte marítimo, todas as comidas, algumas bebidas sem álcool
            (dependendo do navio) e a maioria do entretenimento. Salvo indicação
            em contrário contida na Plataforma, não inclui bebidas, cafeteria,
            qualquer despesa médica, qualquer serviço ou produto fornecido por
            contratados independentes, compras nas boutiques, lavanderia,
            serviços de revelação de fotos e fotografias captadas pelo fotógrafo
            de bordo, cabelereiro, massagens, serviços de spa, serviços de
            telefonia, telegramas, fax e internet, gorjetas, excursões em terra
            e qualquer outra despesa pessoal.
        </p>
        <p>
            <strong
                >0.3 REPROGRAMAÇÕES, ATRASOS E/OU CANCELAMENTOS DOS CRUZEIROS
                (ALTERAÇÕES DE ITINERÁRIOS)</strong
            >
        </p>
        <p>
            Por motivos alheios ao controle da {{ company.branch }}, a Companhia
            de Cruzeiros poderá alterar certas condições dos Cruzeiros, como
            horários, datas, itinerários: poderá atrasá-los e/ou reprogramá-los
            e/ou cancelá-los. A {{ company.branch }} não tem nenhuma ingerência
            nem intervenção com relação às alterações que poderão sofrer os
            Cruzeiros antes ou durante a viagem. O Comandante do Cruzeiro, a seu
            exclusivo juízo, poderá alterar o itinerário, mudar e/ou suprimir
            escalas por razões técnicas, meteorológicas ou de marés adversas
            quando o considerar necessário ou oportuno, para a segurança dos
            passageiros do Cruzeiro ou que possa existir o menor risco para as
            pessoas e/ou outra, sem que os passageiros possam exigir indenização
            alguma.
        </p>
        <p>
            <strong>0.4 APRESENTAÇÃO NO PORTO:</strong> Você deverá estar
            presente nos escritórios de despacho do transportador no porto de
            partida no dia previsto e no horário indicado com o voucher e a
            Documentação de Viagem. É aconselhável que se apresente com pelo
            menos cinco horas de antecedência. Leve em consideração que os
            horários constantes em seu voucher estão expressos na hora local do
            País de escala e/ou de destino. Lembre-se de que se Você não se
            apresentar no embarque ou chegar com atraso ao embarque (No Show),
            Você não terá o direito de exigir a devolução total ou parcial do
            valor pago.
        </p>
        <p>
            <strong>0.5 PEDIDO DE CANCELAMENTO OU ALTERAÇÕES: </strong>A
            possibilidades de efetuar alterações e/ou cancelamentos e, no caso,
            as penalidades aplicáveis a cada circunstância estão sujeitas às
            políticas da Companhia de Cruzeiros que são informadas na
            Plataforma. A {{ company.branch }} não fixa nem cobra penalidades
            por alterações e/ou cancelamentos. Qualquer exceção às referidas
            condições deverá ser autorizada pela Companhia de Cruzeiro.
        </p>
        <p>
            <strong>0.6 MENORES DESACOMPANHADOS:</strong> As Companhias de
            Cruzeiro não permitem o embarque nem a hospedagem em uma cabine a
            menores de 18 anos sem a companhia de seus pais ou responsável legal
            (nos cruzeiros do Caribe, a proibição vale para até os 21 anos),
            assim como bebês menores de 6 meses no momento do embarque. Este
            limite mínimo de idade é elevado a 12 meses no caso de Cruzeiros
            Transatlânticos ou com uma duração de 15 dias ou mais. Sugerimos que
            revise cuidadosamente as políticas da Companhia de Cruzeiros sobre
            menores toda vez que os requisitos puderem variar conforme a empresa
            ou o itinerário. A Companhia de Cruzeiros poderá negar-se a realizar
            reembolsos e/ou qualquer tipo de ressarcimento àqueles que se virem
            afetados pela negação de embarque de um menor que não cumpra com os
            requisitos de idade ou de algum de seus acompanhantes. A idade do
            Passageiro na data de saída determina seu estado para todas as
            férias em Cruzeiro.
        </p>
        <p>
            <strong>0.7 BAGAGEM:</strong> As Companhias de Cruzeiros têm
            diferentes políticas de franquia de bagagem. Informe-se antes de
            viajar. A qualquer momento, a bagagem e os pertences dos passageiros
            são transportados, armazenados e manipulados sob a absoluta
            responsabilidade do proprietário. Qualquer objeto considerado
            valioso, tal como joia, medicamento, equipamento eletrônico, artigo
            delicado, assim como os seus Documentos de Viagem, deverá ser
            transportado pelos passageiros em sua bagagem de mão, tanto no
            embarque como no desembarque. A responsabilidade por roubo e/ou
            furto e/ou perda da bagagem e demais objetos pessoais dos
            passageiros é exclusivamente da Companhia de Cruzeiros. Em caso de
            perda ou dano da bagagem, Você deve dirigir-se aos escritórios de
            atendimento ao cliente do Cruzeiro pelo qual está viajando e
            realizar a correspondente reclamação. Aconselhamos contratar um
            seguro-viagem para cobrir parte desses riscos.
        </p>
        <p>
            <strong>0.8 SAÚDE E APTIDÃO PARA A VIAGEM – GESTANTES: </strong>Você
            garante que está apto para viajar e que sua conduta ou estado não
            prejudicará a segurança ou comodidade do Cruzeiro e/ou dos demais
            Passageiros. Se considerado necessário, as Companhias de Cruzeiro
            poderão exigir de qualquer passageiro a apresentação de um atestado
            médico de aptidão para viajar no Cruzeiro. É aconselhável que as
            mulheres gestantes consultem um médico antes de viajar em qualquer
            etapa da gravidez. Entretanto, é de suma importância o pleno
            conhecimento das condições estabelecidas pela Companhia de Cruzeiros
            para grávidas, já que geralmente não aceitam embarcar passageiras
            que, na data prevista para o embarque, tenham mais do que 6 meses de
            gravidez. Para aqueles passageiros com alguma incapacidade de
            mobilidade, visual ou auditiva e/ou que requeiram dietas especiais,
            estes poderão realizar estas solicitações entrando em “Minha
            Reserva” ou em “Minha Conta”. Leve em consideração que deverá ter a
            documentação médica que ateste a necessidade para poder realizar as
            referidas solicitações. A {{ company.branch }} não é responsável por
            qualquer procedimento inerente à coleta e envio do atestado de
            aptidão médica ou qualquer documento equivalente à Companhia de
            Cruzeiro, cabe a Você a adoção desse procedimento com a Companhia de
            Cruzeiro
        </p>
        <p>
            <strong>0.9 CABINE GARANTIDA:</strong> As cabines garantidas são uma
            boa maneira de viajar economizando. A tarifa garantida se dá em
            cabines de base dupla e não têm localização definida. Ainda que sua
            reserva esteja confirmada, Você somente terá a confirmação do piso e
            do número da cabine no momento do embarque e sem direito a
            alterações. Leia atentamente as condições que são estabelecidas para
            este tipo de serviço.
        </p>
        <p>
            <strong>0.10 GORJETAS:</strong> Cada Companhia de Cruzeiros tem a
            sua política sobre gorjetas, mas, em geral, são obrigatórias e,
            salvo promoções especiais, não estão incluídas na tarifa paga.
            Algumas empresas oferecem gorjetas pré-pagas, outras as incluem no
            cartão de embarque e outras as cobram ao final do Cruzeiro. O valor
            da gorjeta dependerá de vários aspectos, tais como a Companhia de
            Cruzeiros, o tipo de cabine e a idade dos passageiros. É importante
            levar em consideração que a gorjeta é por pessoa (por dia ou noite,
            conforme estabelecido pela Companhia de Cruzeiros) e não por cabine.
            Da mesma maneira, dependendo da Companhia, as crianças menores, de
            determinada idade, também deverão pagá-la. Informe-se sobre a
            política de gorjetas do Cruzeiro.
        </p>
        <p>
            <strong>0.11 REGULAMENTAÇÃO DO TRANSPORTE MARÍTIMO:</strong> A
            responsabilidade do transportador marítimo está regulamentada pela
            Agência Nacional de Transportes Aquaviários (ANTQ) h
            ttp://portal.antaq.gov.br/. Qualquer reclamação, demanda ou denúncia
            pela prestação do Serviço deverá ser dirigida à Companhia de
            Cruzeiros.
        </p>
        <p><strong>CAPÍTULO 4 – AUTOMÓVEIS</strong></p>
        <p>
            Cada locadora de automóveis (a(s) “Locadora(s)”) tem suas próprias
            regras e tarifas aplicáveis sobre o serviço de aluguel de veículos
            (o(s) Automóvel(is)).
        </p>
        <p>
            <strong>0.1 RESERVA DE AUTOMÓVEIS, TARIFA: </strong>A
            {{ company.branch }}
            é mera intermediadora da locação entre Você e a Locadora de
            Veículos, sendo esta última responsável por garantir que o preço
            informado na Plataforma para o aluguel de Automóveis inclui a
            locação do carro por um período determinado (dia, semana, mês),
            quilometragem ilimitada e a totalidade dos requisitos. A Locadora
            poderá requerer de Você a contratação de seguros para a retirada do
            Automóvel e propiciar a circulação no destino escolhido. Salvo
            disposição em contrário contida na Plataforma, a tarifa de locação
            não inclui o pagamento de multas, infrações, serviço de guinchos,
            combustível, entregar o automóvel em cidade distinta daquela da
            retirada, nem qualquer outro serviço adicional.
        </p>
        <p>
            <strong>0.2 INFORMAÇÃO DOS AUTOMÓVEIS: </strong>Quando Você reserva
            um Automóvel na Plataforma, está realizando uma reserva para uma
            “classe” de Automóveis e não para veículos de marcas, anos ou
            modelos específicos, nem de características especiais que não sejam
            comuns a todos os veículos da classe. A Locadora garante o tamanho e
            a capacidade do Automóvel, entretanto, a marca e o modelo dos
            veículos estarão sujeitos à disponibilidade e localização.
        </p>
        <p>
            <strong>0.3 MODALIDADES DE PAGAMENTO:</strong> A
            {{ company.branch }} lhe oferece três modalidades distintas para
            poder efetuar e pagar suas reservas de automóveis. Desta maneira,
            Você tem a possibilidade de: (a) pagar “on-line” e através da
            {{ company.branch }}, 100% do valor de sua reserva; (b) pagar
            somente um percentual do valor total, caso em que deverá pagar o
            saldo remanescente no destino, no momento de retirar o Automóvel; ou
            (c) pagar 100% de sua reserva diretamente no destino, sem a
            necessidade de desembolsar nenhum valor no momento da reserva. Neste
            caso, Você deverá informar à {{ company.branch }} seus Dados
            Pessoais, que serão transmitidos à Locadora para garantir a sua
            reserva. Caso Você decida pagar o total ou parte de sua reserva
            através da {{ company.branch }}, sua reserva poderá ficar sujeita a
            confirmação, circunstância que lhe será informada no primeiro e-mail
            que receber.
        </p>
        <p>
            <strong>0.4 SOLICITAÇÃO DE CANCELAMENTO OU ALTERAÇÕES:</strong>
            De acordo com o estabelecido para cada Locadora, Você poderá
            adquirir reservas reembolsáveis ou não reembolsáveis. Caso a reserva
            adquirida permita reembolso, poderá ser cancelada a qualquer
            momento, sempre que Você notificar a {{ company.branch }} e/ou a
            Locadora, sobre sua vontade nesse sentido com pelo menos 24 horas de
            antecedência em relação ao horário de retirada do veículo. Passado
            esse prazo, não poderão ser efetuados reembolsos. Caso isso
            aconteça, os reembolsos serão gerenciados através de “Minha Reserva”
            ou de “Minha Conta” na Plataforma, e poderão demorar entre 30 e 45
            dias úteis até a sua efetiva devolução. Se o pagamento tiver sido
            efetuado mediante transferência bancária, a
            {{ company.branch }} solicitará novamente seus dados para poder
            gerenciar o reembolso através desse meio. Entretanto, as reservas
            confirmadas e pagas através da {{ company.branch }} não admitem
            alterações. Portanto, você tem a opção de cancelar sem custo sua
            reserva até 24 horas antes do horário previsto para a retirada do
            veículo e preencher novo Pedido de Compra com as condições
            desejadas.
        </p>
        <p>
            <strong>0.5 PERÍODO DA LOCAÇÃO – DEVOLUÇÃO DO AUTOMÓVEL:</strong>
            Os períodos da locação são calculados em blocos de 24 horas. A
            duração de sua locação começa e termina nas datas especificadas no
            seu voucher. Quando o Automóvel for retirado ou devolvido fora dos
            horários do funcionamento dos escritórios da Locadora, a Locadora
            poderá cobrar valores adicionais. Se Você desejar prolongar o
            período de locação ou se devolver o automóvel depois do horário
            determinado no seu voucher, a Locadora poderá aplicar cobranças com
            base na tarifa diária local. Aconselhamos chegar ao local de entrega
            do Automóvel com tempo suficiente. Leve em consideração que no caso
            de Você devolver o Automóvel alugado em um local diferente do
            escolhido, a Locadora poderá aplicar uma cobrança adicional, exceto
            quando este serviço já estiver incluído na reserva adquirida por
            Você.
        </p>
        <p>
            <strong>0.6 DOCUMENTAÇÃO:</strong> Quando Você comparecer à
            Locadora, deverá apresentar: (i) o voucher do serviço enviado pela
            {{ company.branch }}; (ii) uma carteira de habilitação vigente,
            válida no local de destino e expedida com anterioridade mínima de
            dois anos; (iii) um cartão de crédito internacional com limite
            suficiente para fazer frente à garantia. Em alguns casos pode ser
            exigido o passaporte.
        </p>
        <p>
            <strong>0.7 GARANTIA:</strong> Você deverá apresentar um cartão de
            crédito internacional em nome do condutor no momento de retirada do
            Automóvel. Na hora, a Locadora realizará o bloqueio do valor
            correspondente à franquia do seguro a título de garantia, que poderá
            ser de até o dobro da tarifa paga ou a pagar pelo aluguel do
            Automóvel.
        </p>
        <p>
            <strong>0.8 PAGAMENTOS ADICIONAIS:</strong> Esclarecemos que durante
            o período locativo, poderão ser geradas cobranças ou débitos sua
            conta não reclamados pela Locadora de maneira imediata (como multas
            por infrações de trânsito ou danos, quebras ou peças faltantes
            sofridas pelo Automóvel). Você deverá pagar as referidas multas e
            pagamentos adicionais quando forem reclamados pela Locadora. Estes
            pagamentos adicionais dependem das políticas de cada Locadora e são
            cobrados diretamente por elas sem intervenção ou ingerência alguma
            por parte da {{ company.branch }}.
        </p>
        <p>
            <strong
                >0.9 SEGUROS E SERVIÇOS ADICIONAIS CONTRATADOS NO
                DESTINO:</strong
            >
            No destino, a Locadora poderá lhe oferecer a contratação de serviços
            adicionais (tais como seguros com apólices de maior cobertura, quota
            de recuperação, pagamento por gasolina, valor por condutor
            adicional, GPS, assentos para crianças etc.), que serão contratados
            diretamente por Você e cobrados pela Locadora sem intervenção da
            {{ company.branch }}. A contratação desses adicionais é voluntária e
            alheia à {{ company.branch }}, que não é responsável pelos valores
            que Você deve pagar por esses serviços.
        </p>
        <p>
            <strong>0.10 IMPOSTOS E TAXAS:</strong> Em certos destinos, o
            serviço adquirido não inclui impostos locais e/ou encargos
            estaduais, os quais Você deverá pagar no momento da retirada do
            Automóvel, diretamente à Locadora. Esta situação lhe será
            suficientemente informada na Plataforma.
        </p>
        <p>
            <strong>0.11 PEDÁGIOS:</strong> Em certas jurisdições Você poderá
            contratar diretamente com a Locadora um serviço de tele pedágio
            (como por exemplo, o SUN PASS no estado da Flórida, nos Estados
            Unidos), que lhe permitirá trafegar com comodidade e evitar multas.
            Este serviço poderá ser livremente contratado por Você diretamente
            com a Locadora. A {{ company.branch }} não é responsável por tais
            valores e conceitos.
        </p>
        <p>
            <strong>0.12 CONDUTORES MENORES DE 26 ANOS:</strong> Em alguns
            destinos, as Locadoras têm políticas para condutores com idade entre
            21 e 26 anos, nas quais poderão constar restrições e/ou pagamentos
            adicionais que deverão ser pagos no destino. Estes valores não estão
            incluídos na tarifa.
        </p>
        <p>
            <strong>0.13 JURISDIÇÃO ESTRANGEIRA:</strong> Se você planeja cruzar
            uma fronteira entre Estados, assegure-se, antes de completar seu
            Pedido de Compras, que o Automóvel está autorizado a transitar na
            região de destino. Algumas Locadoras podem proibir ou até cobrar um
            valor adicional por transitar em País estrangeiro. Revise as
            condições que são estabelecidas pela Locadora para este item.
        </p>
        <p><strong>CAPÍTULO 5 – ATIVIDADES E TRASLADOS</strong></p>
        <p>
            Leve sempre em consideração que cada operador turístico (o(s)
            “Operador(es)”) tem sempre suas próprias regras de serviços e
            tarifas aplicáveis às atividades, excursões, traslados, tickets e
            atrações que fornecem (a(s) “Atividade(s)”)
        </p>
        <p>
            <strong>5.1 HORÁRIOS:</strong> Sem prejuízo dos horários
            particulares informados para cada Atividade na Plataforma, os
            horários de abertura e fechamento de algumas atrações variam
            conforme o período do ano. Aconselha-se contatar a atração em
            questão antes de visitá-la para confirmar os horários aplicáveis na
            data em que Você tenha planejado fazê-lo. Leve em consideração que
            os horários que constam no voucher estão expressos no horário local
            do destino.
        </p>
        <p>
            <strong>5.2 CONTATO COM O OPERADOR: Em</strong> alguns casos, será
            solicitado que, antes da data para utilizar a Atividade ou do
            Traslado, Você se comunique com o Operador para confirmar alguns
            detalhes e/ou coordenar o local de encontro e/ou solicitar os
            vouchers definitivos sem os quais Você não poderá fazer uso do
            serviço. Se Você não realizar este contato a tempo e de maneira
            correta e, em razão disso, tiver frustrado o uso ou gozo do serviço,
            a {{ company.branch }} não será responsável e Você não terá o
            direito de exigir reembolso algum por parte da
            {{ company.branch }} e/ou do Operador.
        </p>
        <p>
            <strong>0.3 ATIVIDADES DE RISCO: </strong>Nos casos em que a
            Atividade contratada for uma atividade de risco, Você assume o risco
            de participar da Atividade e declara estar ciente, gozar de boa
            saúde física e mental e estar devidamente treinado para participar
            dessa Atividade. Mediante o presente, Você aceita e assume a total
            responsabilidade por todos e quaisquer riscos de doença, ferimentos
            ou morte (não por descuidada, premeditada ou fraudulenta conduta)
            que possam vir a ocorrer em função do desenvolvimento dessa
            Atividade.
        </p>
        <p>
            <strong>0.4 RESPONSABILIDADE:</strong> A {{ company.branch }} não
            será responsável pelas condições das atrações ou locais, nem de atos
            de comissão ou omissão por parte de terceiros em qualquer Atividade,
            local ou serviço.
        </p>
        <p><strong>CAPÍTULO 8 – PACOTES</strong></p>
        <p>
            Na aba “Pacotes” da Plataforma, Você poderá montar uma viagem
            dinâmica. Na viagem dinâmica permite que se somem todos os Serviços
            que Você desejar adquirir, conforme a modalidade do “Cesto de
            Compras” e pagar de uma única vez todos os Serviços. Com a
            modalidade “Cesto de Compras” Você poderá buscar, selecionar
            distintos Serviços (voos + acomodações + atividades + seguro +
            cruzeiros + traslados + ônibus e/ou qualquer combinação entre eles)
            conforme seus gostos e necessidades, realizando uma só busca e
            reservando tantos Serviços quantos Você desejar. A
            {{ company.branch }}
            simplesmente atua como intermediária mostrando as opções disponíveis
            e possibilitando que Você selecione os Serviços, Fornecedores e
            combinações de Serviços conforme seus próprios gostos e
            necessidades, mas sem deixar de atuar como uma intermediária.
        </p>
        <p>
            <strong
                >0.1 {{ company.branch }} NÃO É ORGANIZADORA DE PACOTES:</strong
            >
            A {{ company.branch }} não organiza Pacotes e nem assessora ou tenta
            vender um Serviço e/ou um Fornecedor específico em detrimento de
            outro. Você pode visualizar na Plataforma todas as opções
            disponíveis para construir sua própria viagem combinando voos,
            acomodações, aluguel de autos e/ou traslados, em lugar de comprar um
            pacote pré-definido ou fechado. A {{ company.branch }} pode sugerir
            Pacotes combinando Serviços para facilitar sua busca. Entretanto,
            Você pode alterar qualquer desses Serviços a qualquer momento antes
            da compra para que o Pacote se adapte às suas necessidades.
            Lembre-se de que a {{ company.branch }} não atua como organizadora,
            já que não é quem escolhe e/ou combina os Serviços ou decide qual
            será o prestador e/ou a grade de horários.
        </p>
        <p>
            0.2 MODALIDADE DE RESERVA DE PACOTES: O processo para montar o seu
            Pacote é muito simples e bastam 3 passos: (i) Entre na seção Pacotes
            e defina as datas e o destino de sua viagem. (ii) São selecionados
            os Serviços, como voo, hotel, aluguel de automóveis ou qualquer
            outro produto. (iii) Antes de pagar, é necessário confirmar a compra
            de cada um dos produtos selecionados, então, escolhe-se alguma opção
            de pagamento.
        </p>
        <p>
            0.3 POLITICAS DE CANCELAMENTO E ALTERAÇÃO DE CADA SERVIÇO: Lembre-se
            de que cada serviço selecionado por Você para montar seu Pacote é
            regido por suas próprias condições de alteração e/ou de cancelamento
            estabelecidas por cada Fornecedor e que a alteração ou cancelamento
            de um Serviço não afeta, de maneira alguma, os demais Serviços
            contratados que serão regidos pelas suas próprias condições e que
            deverão ser alterados e/ou cancelados individualmente sempre que as
            condições do Fornecedor assim o permitirem.
        </p>
        <p>
            A {{ company.branch }} se reserva o direito, a seu exclusivo
            critério, de modificar estes termos e condições a qualquer momento,
            com ou sem aviso prévio.
        </p>
        <p>
            <strong
                >A {{ company.branch }} é uma agência de viagens devidamente
                inscrita junto ao Ministério de Turismo, CADASTUR Ministério do
                Turismo sob o nº 09.017.003/0001-02, e sua atividade está
                regulamentada pela Lei de Agências de Viagens n.º 12.974, de 15
                de maio de 2014.</strong
            >
        </p>
        <p>
            Estes Termos e Condições foram atualizados em Janeiro de 2022. A
            {{ company.branch }} compromete-se que, em caso de alterações
            futuras, o texto resultante será novamente publicado no Site.
        </p>
    </div>
</template>

<script>
export default {
    props: {
        contract: Object,
    },
    data() {
        return {
            company: {
                domain:
                    this.contract.meta.payment_methods_payment_link_base_url +
                    ".com.br",
                branch: this.contract.company_branch.name,
                name: this.contract.company_details.company_name,
            }, //
        };
    },
    methods: {},
};
</script>
