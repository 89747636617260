<template>
  <div>
    <aCol :span="$root.windowWidth < 1401 ? 24 : 12">
      <a-form-item>
        <label>Número do seu Cartão de Crédito</label>
        <a-input
          class="travel-input"
          placeholder="Ex: 0000 0000 0000 0000"
          inputmode="numeric"
          v-mask="'#### #### #### ####'"
          v-decorator="[
            `payment_link_credit_card_number`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório.',
                },
              ],
            },
          ]"
          @blur="detectCreditCardFlag"
        >
          <a-tooltip slot="suffix" :title="creditCardFlag.name">
            <img
              :src="creditCardFlag.image"
              :alt="creditCardFlag.name"
              height="18"
            />
          </a-tooltip>
        </a-input> </a-form-item
    ></aCol>
    <aCol :span="$root.windowWidth < 1401 ? 24 : 12">
      <a-form-item>
        <label>Títular do Cartão de Crédito</label>
        <a-input
          class="travel-input"
          placeholder="Insira o nome como aparece no cartão"
          v-decorator="[
            `payment_link_credit_card_name`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório.',
                },
              ],
            },
          ]"
        >
        </a-input>
      </a-form-item>
    </aCol>
    <aCol :span="$root.windowWidth < 1401 ? 24 : 6">
      <a-form-item>
        <label>Vencimento</label>
        <a-input
          class="travel-input"
          placeholder="MM/AAAA"
          v-mask="'##/####'"
          inputmode="numeric"
          v-decorator="[
            `payment_link_credit_card_expiration`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório.',
                },
              ],
            },
          ]"
        >
        </a-input> </a-form-item
    ></aCol>
    <aCol :span="$root.windowWidth < 1401 ? 24 : 6">
      <a-form-item>
        <label>Código de Segurança</label>
        <a-input
          class="travel-input"
          inputmode="numeric"
          readonly
          placeholder="***"
          v-mask="'####'"
          v-decorator="[
            `payment_link_credit_card_cvv`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório.',
                },
              ],
            },
          ]"
        >
        </a-input> </a-form-item
    ></aCol>

    <aCol :span="$root.windowWidth < 1401 ? 24 : 12">
      <a-form-item>
        <label>CPF</label>
        <a-input
          class="travel-input"
          inputmode="numeric"
          v-mask="'###.###.###-##'"
          readonly
          placeholder="___.___.___-__"
          v-decorator="[
            `payment_link_client_cpf`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório.',
                },
              ],
            },
          ]"
        >
        </a-input> </a-form-item
    ></aCol>

    <aCol class="mb-30 mt-10" :span="24">
      <aCollapse
        v-model="activeKey"
        expandIconPosition="right"
        style="overflow:hidden"
      >
        <aCollapsePanel class="credit-card-installments" key="1">
          <template slot="header">
            <span v-if="installments[0].selected == undefined">
              Selecione em quantas parcelas você quer pagar
            </span>

            <span v-if="installments[0].selected != undefined">
              <span v-if="installments[0].selected == 1"
                >Pagamento à vista</span
              >
              <span v-if="installments[0].selected > 1"
                >Pagamento em {{ installments[0].selected }}x sem juros</span
              >
            </span>
          </template>
          <div
            class="group"
            v-for="(installment, index) in installments"
            :key="index"
          >
            <div class="options">
              <a-radio-group
                v-model="installment.selected"
                @change="emitSelectedInstallment(installment.selected)"
              >
                <a-radio
                  v-for="(option, index) in installment.data"
                  :key="index"
                  :value="option.number"
                >
                  <div v-if="option.number == 1">
                    <div class="number">À vista {{ option.value }}</div>
                  </div>
                  <div v-if="option.number > 1">
                    <div class="number">
                      <span class="cprimary"
                        >{{ option.number }}x sem juros
                      </span>
                      de {{ option.value }}
                    </div>
                    <div class="total">
                      Total a pagar:
                      {{ option.totalValue }}
                    </div>
                  </div>
                </a-radio>
              </a-radio-group>
            </div>
          </div>
        </aCollapsePanel>
      </aCollapse>
    </aCol>
  </div>
</template>

<script>
import { parse } from "vue-currency-input";
import formatThings from "../../general/mixins/formatThings.js";

export default {
  props: {
    contract: Object,
    form: Object,
  },
  mixins: [formatThings],
  data() {
    return {
      activeKey: 1,
      creditCardFlag: {
        name: "",
        image: "",
      },
      installments: [
        {
          selected: undefined,
          data: [],
        },
      ],
    };
  },
  mounted() {
    let installments = parseInt(
        this.contract.meta[
          `payment_methods_payment_link_installments_${this.contract.payment_link_id}`
        ]
      ),
      value = this.contract.meta[
        `payment_methods_payment_link_total_value_${this.contract.payment_link_id}`
      ].includes(".")
        ? this.contract.meta[
            `payment_methods_payment_link_total_value_${this.contract.payment_link_id}`
          ]
        : parse(
            this.contract.meta[
              `payment_methods_payment_link_total_value_${this.contract.payment_link_id}`
            ]
          );
    for (let i = 1; i <= installments; i++) {
      this.installments[0].data.push({
        number: i,
        value: this.formatPricePtBr(value / i),
        totalValue: this.formatPricePtBr(value),
        withTax: false,
      });
    }
  },
  methods: {
    emitSelectedInstallment(selected) {
      this.$emit("updateSelectedInstallment", selected);
    },
    detectCreditCardFlag(e) {
      const cardNumber = e.target.value
        .replace(" ", "")
        .replace(" ", "")
        .replace(" ", "")
        .replace(" ", "");
      // Regular expressions to match card numbers
      const patterns = {
        visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
        mastercard: /^5[1-5][0-9]{14}$/,
        amex: /^3[47][0-9]{13}$/,
        discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
        diners_club: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
        jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
        elo: /^(40117[8-9]|43(1274|8935)|45(1416|7393|763(1|2))|50(4175|6699|67(09|18|26|34|42|59|67|75))|627780|63(6297|6368|6369)|65(0031|0037|0039|0146|0147|0175|0213|0214|0240|0259|0274|0290|055|06(3|4)|09(0|1)|11(1|2)|17(6|7)))/,
        hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/,
      };

      if (cardNumber) {
        // Iterate through patterns to find a match
        for (let flag in patterns) {
          if (patterns[flag].test(cardNumber)) {
            this.creditCardFlag = {
              name: flag,
              image: require(`@/assets/${flag}.png`),
            };
          }
        }
      } else {
        this.creditCardFlag = {
          name: "",
          image: "",
        };
      }
    },
  },
};
</script>

<style lang="sass">
.ant-form-item-with-help
    margin-bottom: 24px !important
.credit-card-installments
  .ant-form-item-with-help
    margin-bottom: 24px !important
  .ant-form-explain
    display: none
  .ant-collapse-header
    background: #FFF !important
    font-size: 11px
    font-weight: 500
  .ant-collapse-content-box
    padding: 0 !important
  .group
    .ant-radio-group
      label:first-child
        .ant-radio
           top: 6px
      label
        .ant-radio
          position: relative
          top: -4px
          margin: 0 7px 0 6px
          .ant-radio-inner
            width: 21px
            height: 21px
            border-width: 1px !important
          .ant-radio-inner::after
            top: 3px
            left: 3px
            width: 13px
            height: 13px
            border-radius: 20px
        span
          display: inline-block
</style>

<style lang="sass" scoped>

.credit-card-installments
  .group
    .ant-radio-group
      width: 100%
      label
        display: block
        height: 48px
        padding: 7px 10px
        margin: 0
        border-bottom: 1px solid #e1e1e1
        font-size: 12px
        color: #333
        font-weight: 500
        .number
          color: #656d78
        .total
          color: #656d78
          font-size: 9px
        span
          display: inline-block
      & :last-child
       // border-bottom: 0
      & .ant-radio-wrapper-checked
        background: #fafafa
        color: #333
</style>
