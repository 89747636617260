<template>
  <section id="app">
    <RouterView />
  </section>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="sass">
@import '/assets/sass/style.sass'
</style>
