<template>
  <div>
    <aForm class="payment-form" :form="form" @submit="onSubmitForm">
      <aCol :span="24" class="white-box" style="padding: 16px !important">
        <div class="success-box" v-if="$route.params.status === 'success'">
          <img src="@/assets/success.png" alt="Link expirado" width="100" />
          <div class="txt">
            Pagamento realizado com sucesso!
          </div>
        </div>
        <template v-if="$route.params.status !== 'success'">
          <aAlert
            v-if="
              [undefined, ''].includes(
                contract.meta[
                  `payment_link_3ds_tid_${contract.payment_link_id}`
                ]
              )
            "
            class="welcome"
            description="Parabéns! Você esta a um passo de garantir o melhor valor para sua viagem! Efetue o pagamento agora e garanta esta tarifa!"
            type="success"
            banner
          >
            <template slot="icon">
              <img src="@/assets/welcome.png" alt="welcome" />
            </template>
          </aAlert>

          <aAlert
            v-if="
              ![undefined, ''].includes(
                contract.meta[
                  `payment_link_3ds_tid_${contract.payment_link_id}`
                ]
              )
            "
            class="welcome-2"
            description="Parabéns! Você já efetuou o pagamento!"
            type="success"
            banner
          >
          </aAlert>

          <aRow class="mb-30" :gutter="20">
            <aCol class="mb-10" :span="24">
              <h2>
                <img src="@/assets/customer.png" width="20" alt="customer" />
                Contratante
              </h2>
            </aCol>
            <aCol :span="24">
              <CustomerSection :contract="contract" :form="form" />
            </aCol>
            <aCol class="section-border" :span="24" />
          </aRow>

          <aRow class="mb-30" :gutter="20">
            <aCol :span="24">
              <h2>
                <img src="@/assets/passenger.png" width="20" alt="passenger" />
                Quem vai viajar
              </h2>
            </aCol>

            <aCol :span="24" style="padding: 0 20px">
              <TravellersSection :contract="contract" />
            </aCol>
          </aRow>

          <aRow
            v-if="
              [undefined, ''].includes(
                contract.meta[
                  `payment_link_3ds_tid_${contract.payment_link_id}`
                ]
              )
            "
            :gutter="20"
          >
            <aCol :span="24">
              <h2>
                <img
                  src="@/assets/credit-card.png"
                  width="20"
                  alt="credit-card"
                />
                Pagamento
              </h2>
            </aCol>

            <aCol :span="24" style="padding: 0 ">
              <CreditCardSection
                @updateSelectedInstallment="updateSelectedInstallment"
                :contract="contract"
                :form="form"
              />
            </aCol>
          </aRow>

          <aRow
            v-if="
              [undefined, ''].includes(
                contract.meta[
                  `payment_link_3ds_tid_${contract.payment_link_id}`
                ]
              )
            "
            :gutter="20"
          >
            <aCol :span="24">
              <h2>
                <img src="@/assets/maps.png" width="20" alt="maps" />
                Endereço da fatura do cartão de crédito
              </h2>
            </aCol>

            <aCol :span="$root.windowWidth < 1401 ? 24 : 6">
              <a-form-item>
                <label>CEP</label>
                <a-input
                  class="travel-input"
                  placeholder="Ex: 30130-139"
                  inputmode="numeric"
                  v-mask="'#####-###'"
                  @keyup="getLocation()"
                  v-decorator="[
                    `payment_link_client_cep_${contract.payment_link_id}`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório.',
                        },
                      ],
                    },
                  ]"
                >
                  <a-icon slot="suffix" v-if="loadingLocation" type="loading" />
                </a-input> </a-form-item
            ></aCol>

            <aCol :span="$root.windowWidth < 1401 ? 24 : 6">
              <a-form-item>
                <label>Estado</label>
                <a-select
                  class="travel-input"
                  show-search
                  placeholder="Selecione"
                  v-decorator="[
                    `payment_link_client_location_state_${contract.payment_link_id}`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                >
                  <a-select-option
                    v-for="(item, index) of states"
                    :key="index"
                    :value="item.initials"
                  >
                    {{ item.initials.toUpperCase() }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </aCol>
            <aCol :span="$root.windowWidth < 1401 ? 24 : 6">
              <a-form-item>
                <label>Cidade</label>
                <a-input
                  class="travel-input"
                  placeholder=""
                  v-decorator="[
                    `payment_link_client_location_city_${contract.payment_link_id}`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório.',
                        },
                      ],
                    },
                  ]"
                >
                </a-input> </a-form-item
            ></aCol>

            <aCol :span="$root.windowWidth < 1401 ? 24 : 6">
              <a-form-item>
                <label>Bairro</label>
                <a-input
                  class="travel-input"
                  placeholder=""
                  v-decorator="[
                    `payment_link_client_location_neighborhood_${contract.payment_link_id}`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório.',
                        },
                      ],
                    },
                  ]"
                >
                </a-input> </a-form-item
            ></aCol>

            <aCol :span="$root.windowWidth < 1401 ? 24 : 12">
              <a-form-item>
                <label>Endereço</label>
                <a-input
                  class="travel-input"
                  placeholder=""
                  v-decorator="[
                    `payment_link_client_location_street_${contract.payment_link_id}`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório.',
                        },
                      ],
                    },
                  ]"
                >
                </a-input> </a-form-item
            ></aCol>

            <aCol :span="$root.windowWidth < 1401 ? 24 : 4">
              <a-form-item>
                <label>Número</label>
                <a-input
                  class="travel-input"
                  placeholder=""
                  v-decorator="[
                    `payment_link_client_location_number_${contract.payment_link_id}`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório.',
                        },
                      ],
                    },
                  ]"
                >
                </a-input> </a-form-item
            ></aCol>

            <aCol :span="$root.windowWidth < 1401 ? 24 : 8">
              <a-form-item>
                <label>Complemento</label>
                <a-input
                  class="travel-input"
                  placeholder=""
                  v-decorator="[
                    `payment_link_client_location_complement_${contract.payment_link_id}`,
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Obrigatório.',
                        },
                      ],
                    },
                  ]"
                >
                </a-input> </a-form-item
            ></aCol>
            <aCol class="section-border mb-20" :span="24" />
          </aRow>

          <aRow
            v-if="
              [undefined, ''].includes(
                contract.meta[
                  `payment_link_3ds_tid_${contract.payment_link_id}`
                ]
              )
            "
          >
            <aCol :span="24">
              <a-checkbox
                class="mt-10"
                v-model="agreedPaymentLinkTerms"
                style="font-size: 12px; font-weight: 500; position: relative"
              >
              </a-checkbox>

              Li e aceito as
              <a
                @click="purchaseConditions.modal = true"
                style="font-weight: 500; color: #333"
                >condições de compra</a
              >
              e
              <a
                @click="privacyPolicy.modal = true"
                style="font-weight: 500; color: #333"
              >
                política de privacidade.</a
              >.
            </aCol>

            <aCol class="mt-20" :span="24">
              <a-alert
                class="important"
                description="Importante: Somente o pagamento não garante sua reserva. O contratato digital deve ser assinado para garantir."
                type="success"
                banner
              >
                <template slot="icon">
                  <img src="@/assets/alert.png" alt="alert" />
                </template>
              </a-alert>
            </aCol>
            <aCol class="mt-20" :span="24">
              <a-button
                type="primary"
                size="large"
                html-type="submit"
                :disabled="agreedPaymentLinkTerms == false"
                style="width: 100%"
              >
                Finalizar pagamento
              </a-button>
            </aCol>
          </aRow>
        </template>
      </aCol>
    </aForm>

    <aModal
      title="Termos e Condições de Compra"
      :visible="purchaseConditions.modal"
      :footer="null"
      class="travel-modal terms"
      @cancel="purchaseConditions.modal = false"
      :width="$root.windowWidth < 501 ? 360 : 700"
    >
      <PurchaseConditions :contract="contract" />
    </aModal>

    <aModal
      title="Políticas de alteração e cancelamento"
      :visible="changeAndCancelationPolicies.modal"
      :footer="null"
      class="travel-modal terms"
      @cancel="changeAndCancelationPolicies.modal = false"
      :width="$root.windowWidth < 501 ? 360 : 700"
    >
    </aModal>

    <aModal
      title="Política de Privacidade"
      :visible="privacyPolicy.modal"
      :footer="null"
      class="travel-modal terms"
      @cancel="privacyPolicy.modal = false"
      :width="$root.windowWidth < 501 ? 360 : 700"
    >
      <PrivacyPolicy :contract="contract" />
    </aModal>

    <aSpin
      v-if="updateContractLoading == true"
      size="large"
      tip="Processando seu pagamento, por favor aguarde..."
    >
    </aSpin>
  </div>
</template>

<script>
import axios from "axios";
import { format, parse } from "date-fns";

import CustomerSection from "./CustomerSection.vue";
import TravellersSection from "./TravellersSection.vue";
import CreditCardSection from "./CreditCardSection.vue";
import PrivacyPolicy from "../../terms/PrivacyPolicy.vue";
import PurchaseConditions from "../../terms/PurchaseConditions.vue";
import formatThings from "../../general/mixins/formatThings.js";

export default {
  components: {
    CustomerSection,
    TravellersSection,
    CreditCardSection,
    PrivacyPolicy,
    PurchaseConditions,
  },
  props: {
    contract: Object,
    userData: Object,
    emailFlightData: String,
  },
  mixins: [formatThings],
  data() {
    return {
      form: this.$form.createForm(this),
      updateContractLoading: false,
      purchaseConditions: { modal: false },
      selectedInstallment: undefined,
      changeAndCancelationPolicies: { modal: false },
      privacyPolicy: { modal: false },
      openConfirmationModal: false,
      loadingLocation: false,
      openTermsModal: false,
      agreedPaymentLinkTerms: false,
      installments: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      states: [
        { name: "Acre", initials: "AC" },
        { name: "Alagoas", initials: "AL" },
        { name: "Amapá", initials: "AP" },
        { name: "Amazonas", initials: "AM" },
        { name: "Bahia", initials: "BA" },
        { name: "Ceará", initials: "CE" },
        { name: "Distrito Federal", initials: "DF" },
        { name: "Espírito Santo", initials: "ES" },
        { name: "Goiás", initials: "GO" },
        { name: "Maranhão", initials: "MA" },
        { name: "Mato Grosso", initials: "MT" },
        { name: "Mato Grosso do Sul", initials: "MS" },
        { name: "Minas Gerais", initials: "MG" },
        { name: "Pará", initials: "PA" },
        { name: "Paraíba", initials: "PB" },
        { name: "Paraná", initials: "PR" },
        { name: "Pernambuco", initials: "PE" },
        { name: "Piauí", initials: "PI" },
        { name: "Rio de Janeiro", initials: "RJ" },
        { name: "Rio Grande do Norte", initials: "RN" },
        { name: "Rio Grande do Sul", initials: "RS" },
        { name: "Rondônia", initials: "RO" },
        { name: "Roraima", initials: "RR" },
        { name: "Santa Catarina", initials: "SC" },
        { name: "São Paulo", initials: "SP" },
        { name: "Sergipe", initials: "SE" },
        { name: "Tocantins", initials: "TO" },
      ],
    };
  },
  methods: {
    formatValueToRede(value) {
      let theValue;
      let theValuePieces = [];

      if (value.includes(",")) {
        theValuePieces = value.split(",");
        let theValueDecimals = "00";
        if (theValuePieces[1].length === 1) {
          theValueDecimals = `${theValuePieces[1]}0`;
        } else {
          theValueDecimals = `${theValuePieces[1]}`;
        }
        theValue = `${theValuePieces[0]}${theValueDecimals}`;
      } else {
        theValue = `${value}00`;
      }

      return theValue;
    },
    updateSelectedInstallment(selected) {
      this.selectedInstallment = selected;
    },
    formatDate(date) {
      if (date.length == 8) {
        let theDate = parse(date, "yyyyMMdd", new Date());
        return format(theDate, "dd/MM/yyyy");
      }
      if (date.length == 10) return date;

      if (date.length > 22) {
        let theDate = parse(date.split("T")[0], "yyyy-MM-dd", new Date());
        return format(theDate, "dd/MM/yyyy");
      }
    },
    travellers() {
      let allTravellers = [];

      JSON.parse(this.contract.meta.travellers_rows).forEach((traveller) => {
        allTravellers.push({
          id: traveller.id,
          nome: `${this.contract.meta[
            `traveller_${traveller.id}_first_name`
          ].toUpperCase()} ${this.contract.meta[
            `traveller_${traveller.id}_last_name`
          ].toUpperCase()}`,
          nascimento:
            this.contract.meta[`traveller_${traveller.id}_birthday`] !=
            undefined
              ? this.formatDate(
                  this.contract.meta[`traveller_${traveller.id}_birthday`]
                )
              : "",
          cpf: `${
            this.contract.meta[`traveller_${traveller.id}_CPF`] != undefined
              ? this.contract.meta[`traveller_${traveller.id}_CPF`]
              : ""
          }`,
          idade: this.contract.meta[`traveller_${traveller.id}_age`],
        });
      });
      return allTravellers;
    },
    onSubmitForm(e) {
      e.preventDefault();

      if (this.agreedPaymentLinkTerms) {
        this.form.validateFields((err, values) => {
          values.id = this.contract.id;
          values[
            `payment_link_credit_card_selected_installment_${this.contract.payment_link_id}`
          ] = this.selectedInstallment;

          const dateReference = this.contract.created.split("-");
          const reference = `LP${this.paymentLinkId}${dateReference[0]}${dateReference[1]}${this.contract.id}`;
          const contractReference = this.contract.meta[
            `payment_link_reference_${this.contract.payment_link_id}`
          ];

          values.contract_unique_id = contractReference ?? reference;
          values.agreed_payment_link_terms = this.agreedPaymentLinkTerms;
          values[
            `payment_methods_payment_link_total_value_sent_${this.contract.payment_link_id}`
          ] = this.formatValueToRede(
            this.contract.meta[
              `payment_methods_payment_link_total_value_${this.contract.payment_link_id}`
            ]
          );

          values.payment_link_credit_card_number = values.payment_link_credit_card_number
            ? values.payment_link_credit_card_number
                .replace(" ", "")
                .replace(" ", "")
                .replace(" ", "")
            : undefined;

          values[
            `payment_methods_payment_link_base_url_${this.contract.payment_link_id}`
          ] = this.contract.meta[
            `payment_methods_payment_link_base_url_${this.contract.payment_link_id}`
          ];

          if (!err && this.selectedInstallment != undefined) {
            this.onSubmitUpdateContract(values);
          } else {
            this.$message.warning("Selecione a quantidade de parcelas.");
          }
        });
      } else {
        this.$message.warning(
          "Você precisa ler e concordar com as condições de compra, políticas de alteração e cancelamento e política de privacidade."
        );
      }
    },
    onSubmitUpdateContract(values) {
      const threeDSecureTransaction = {
        embedded: true,
        onFailure: "continue",
        userAgent: navigator.userAgent,
        device: {
          colorDepth: screen.colorDepth,
          deviceType3ds: "BROWSER",
          javaEnabled: false,
          language: "BR",
          screenHeight: screen.height,
          screenWidth: screen.width,
          timeZoneOffset: 3,
        },
      };

      let contractDataToUpdate = JSON.stringify(values);
      contractDataToUpdate = JSON.parse(contractDataToUpdate);

      contractDataToUpdate[
        `payment_link_three_d_secure_data_${this.contract.payment_link_id}`
      ] = JSON.stringify(threeDSecureTransaction);

      contractDataToUpdate.flights_email_data = this.emailFlightData;
      contractDataToUpdate.payment_link_credit_card_number = "";
      contractDataToUpdate.payment_link_credit_card_name = "";
      contractDataToUpdate.payment_link_credit_card_expiration = "";
      contractDataToUpdate.payment_link_credit_card_cvv = "";

      this.updateContractLoading = true;

      this.$http
        .post("/contract/update-multi-meta-payment-link", contractDataToUpdate)
        .then(() => {
          this.updateContractLoading = false;

          this.$http.post("/log/create-payment-link", {
            user_id: this.contract.user.id,
            module_id: this.contract.id,
            module: "contract",
            action: `payment-link-${this.contract.payment_link_id}-update-customer-data`,
            description: `<b>${this.contract.customer.first_name} ${this.contract.customer.last_name}</b> aceitou os termos e enviou os dados do <b>Cartão de Crédito (${this.selectedInstallment}x)</b> e <b>Endereço</b> para a <b>Rede</b>.`,
          });

          this.submitToRede(
            values,
            contractDataToUpdate,
            threeDSecureTransaction
          );
        })
        .catch(() => {
          this.$message.error(
            "Não foi possível continuar com a transação, informe ao seu consultor."
          );

          this.updateContractLoading = false;
        });
    },
    submitToRede(values, contractDataToUpdate, threeDSecureTransaction) {
      const baseUrl = "https://api.viajar.tur.br/v1/integrations/rede/";
      let w = window.location.host.split(".")[1];
      if (w === "localhost:3333") w = "voesimples";

      const redeTransaction = {
        capture: false,
        reference: values.contract_unique_id,
        amount:
          values[
            `payment_methods_payment_link_total_value_sent_${this.contract.payment_link_id}`
          ],
        cardholderName: values.payment_link_credit_card_name,
        cardNumber: values.payment_link_credit_card_number,
        expirationMonth: values.payment_link_credit_card_expiration.split(
          "/"
        )[0],
        expirationYear: values.payment_link_credit_card_expiration.split(
          "/"
        )[1],
        securityCode: values.payment_link_credit_card_cvv,
        kind: "credit",
        threeDSecure: threeDSecureTransaction,
        urls: [
          {
            kind: "threeDSecureSuccess",
            url: `${baseUrl}success?w=${w}&plid=${this.contract.payment_link_id}`,
          },
          {
            kind: "threeDSecureFailure",
            url: `${baseUrl}failure?w=${w}&plid=${this.contract.payment_link_id}`,
          },
        ],
      };

      if (this.selectedInstallment > 1)
        redeTransaction.installments =
          values[
            `payment_link_credit_card_selected_installment_${this.contract.payment_link_id}`
          ];

      // for DEV tests .post("/integrations/rede/transaction-dev"
      this.$http
        .post("/integrations/rede/transaction", {
          transaction: JSON.stringify(redeTransaction),
        })
        .then(({ data }) => {
          this.$message.loading(
            "Você será redirecionado para finalizar a transação, aguarde.",
            2
          );

          this.$http.post("/log/create-payment-link", {
            user_id: this.contract.user.id,
            module_id: this.contract.id,
            module: "contract",
            action: `payment-link-${this.contract.payment_link_id}-update-customer-data`,
            description: `<b>${this.contract.customer.first_name} ${this.contract.customer.last_name}</b> foi redirecionado para verificação do <b>Cartão de Crédito</b>.`,
          });

          contractDataToUpdate[
            `payment_link_3ds_transaction_generation_date_${this.contract.payment_link_id}`
          ] = data.dateTime;

          this.$http.post(
            "/contract/update-multi-meta-payment-link",
            contractDataToUpdate
          );

          setTimeout(() => {
            window.open(data.threeDSecure.url, "_self");
          }, 2000);
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);

          setTimeout(() => {
            this.$http.post("/log/create-payment-link", {
              user_id: this.contract.user.id,
              module_id: this.contract.id,
              module: "contract",
              action: `payment-link-${this.contract.payment_link_id}-update-customer-data`,
              description: `Houve algum erro ao enviar os dados da transação para a Rede. Segue mensagem retornada:
            <br><br>
            Erro ${response.data?.returnCode}: <b>${response.data.message}</b>
            <br><br>
            `,
            });
          }, 500);
        });
    },
    getLocation() {
      setTimeout(() => {
        let cepNumber = this.form.getFieldValue(
          `payment_link_client_cep_${this.contract.payment_link_id}`
        );
        if (cepNumber) {
          if (cepNumber.replace("-", "").length == 8) {
            this.loadingLocation = true;
            axios
              .get(`https://viacep.com.br/ws/${cepNumber}/json/`)
              .then(({ data }) => {
                this.loadingLocation = false;
                this.form.setFieldsValue({
                  [`payment_link_client_location_street_${this.contract.payment_link_id}`]: data.logradouro.toUpperCase(),
                  [`payment_link_client_location_state_${this.contract.payment_link_id}`]: data.uf,
                  [`payment_link_client_location_city_${this.contract.payment_link_id}`]: data.localidade.toUpperCase(),
                  [`payment_link_client_location_neighborhood_${this.contract.payment_link_id}`]: data.bairro.toUpperCase(),
                });
              })
              .catch(() => {
                this.$message.error(
                  "Houve um problema ao recuperar a localização."
                );
                this.loadingLocation = false;
              });
          }
        }
      }, 110);
    },
  },
};
</script>

<style lang="sass">
.ant-spin-text
    font-size: 18px
    margin-top: 30px
    font-weight: 300
.ant-spin-dot-item
  background-color: #fff !important
.ant-spin-spinning
  position: fixed !important
  z-index: 999
  background: #000000a3
  left: 0
  right: 0
  top: 0
  bottom: 0
  padding-top: 200px !important
  color: #FFF !important

.terms
  .ant-modal-header
    padding: 16px 15px
    color: #000
    font-weight: 700 !important
    background: #fff
    border-bottom: 1px solid #e8e8e8
  .ant-modal-body
    padding: 15px
    font-size: 12px
    text-align: justify
    color: #000
    font-weight: 400
.payment-form
  .important
    .ant-alert-description
      line-height: 1.2
      font-size: 11px
      font-weight: 500
    .ant-alert-icon
      top: -7px !important
      left: 8px !important
  .welcome-2
    .anticon
      top: 10px
      left: 15px
      color: #fff
  .welcome
    .ant-alert-description
      line-height: 1.2
    .ant-alert-icon
      top: 8px !important
      left: 15px !important
</style>

<style lang="sass" scoped>
.success-box
  text-align: center
  background: #eafff1
  padding: 40px 20px
  color: #60b17b
  font-size: 20px
  font-weight: 600
  img
    margin-bottom: 20px

.payment-form
  .section-border
    padding-top: 10px
    &:after
      border-bottom: 1px solid #e1e1e1
      content: ""
      width: 100%
      display: block
  h2
    font-size: 15px !important
    color: #aab2bd !important
    img
      position: relative
      top: -2px
      margin-right: 5px
  .important
    background: #e05c6e
    border-radius: 6px
    color: #FFF
    padding: 4px 10px 4px 35px
    border: 1px solid rgba(0, 0, 0, 10%)
  .welcome-2
    background: #48cfad
    border-radius: 6px
    margin-bottom: 20px
    color: #FFF
    font-weight: 500
    padding: 8px 30px 8px 50px
    border: 1px solid rgba(0, 0, 0, 10%)
    .anticon
      top: 10px
      left: 11px
      color: #fff
  .welcome
    background: #48cfad
    border-radius: 6px
    margin-bottom: 20px
    color: #FFF
    font-weight: 500
    padding: 8px 30px 8px 65px
    border: 1px solid rgba(0, 0, 0, 10%)
    .ant-alert-icon
      top: 10px
      left: 11px
</style>
